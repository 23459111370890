import { useState, useEffect } from "react";
import { Icon, Grid, Card, Form } from "components/lynx-components";
import "./add-widget-modal.css";
import { getFilters } from "../../../services/filter-service";
import { CreateCountList } from "./create-count-list";
import { CreateGroupChart } from "./create-group-chart";
import useAlert from "hooks/useAlert";
import { UserDashboardWidgetDto } from "types";
import { WidgetTypes } from "types/enums-ts";

interface Props {
  widget: UserDashboardWidgetDto;
  handleModalClose: (value: boolean) => void;
}

const EditWidgetModal = ({widget, handleModalClose}: Props) => {
  const [filters, setFilters] = useState([]);

  const { showAlert } = useAlert();

  useEffect(() => {
    getFilters("")
      .then((res) => {
        setFilters(res.data);
      })
      .catch((err) => {
        showAlert("error", err.response.data.message);
      });
  }, []);

  return (
    <Form className="card dsmodal-main lynx-modal">
      <Card.Body>
        <Card.Title>
          Edit Widget
          <Icon
            name="x"
            onClick={() => handleModalClose(false)}
            className="float-right pointer"
          ></Icon>
        </Card.Title>

        <Grid.Row>
          <>
            {widget.widgetType == WidgetTypes.CountList && (
              <CreateCountList
                filters={filters}
                handleModalClose={handleModalClose}
                existingWidget={widget}
              />
            )}
            {(widget.widgetType == WidgetTypes.DonutChart || widget.widgetType == WidgetTypes.BarChart) && (
              <CreateGroupChart
                filters={filters}
                handleModalClose={handleModalClose}
                widgetType={widget.widgetType}
                existingWidget={widget}
              />
            )}
          </>
        </Grid.Row>
      </Card.Body>
    </Form>
  );
};

export default EditWidgetModal;
