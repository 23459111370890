import _ from "lodash";
import { useGetAuditLogsQuery } from "services/rtkApi/endpoints/auditLogs";
import { EntityTypes } from "types/enums-ts";
import { LynxDataGrid } from "../../data-grid/lynx-data-grid";
import { columns } from "./columns";

export function AuditLogList() {
  return (
    <>
      {!_.isEmpty(columns) && (
        <LynxDataGrid
          columns={columns}
          useQuery={useGetAuditLogsQuery}
          entityType={EntityTypes.AuditLog}
          localStorageName="auditLog"
          title={"Audit Log"}
        />
      )}
    </>
  );
}
