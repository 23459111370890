import React, { useState } from "react";
import { Page, Form, Card, Grid } from "components/lynx-components";
import Button from "@mui/material/Button";
import { forgotPassword } from "./../../services/login";
import { LynxDialog } from "./../lynx-dialog";

import { validationService } from "./../../services/validation";
import useAlert from "hooks/useAlert";
import queryString from "query-string";
import { Redirect, useHistory, useLocation } from "react-router-dom";

export function ForgotPassword() {
  const [formState, setFormState] = useState({ email: "", emailError: "" });
  const [showDialog, setShowDialog] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const { showAlert } = useAlert();
  const validateDataForSave = () => {
    let newState = { ...formState };
    let isFormValid = false;

    validationService.validateEmailValue(
      formState.email,
      newState,
      "emailError",
      true
    );
    setFormState(newState);
    isFormValid = !validationService.hasError(newState, "emailError");

    return isFormValid;
  };

  const requestResetLink = () => {
    if (validateDataForSave()) {
      forgotPassword(formState.email)
        .then((res) => {
          setShowDialog(true);
        })
        .catch((err) => {
          showAlert("error", err.response.data.message);
        });
    }
  };

  const handleConfirm = () => {
    const qs = queryString.parse(location.search);
    if (qs.redirect) {
      history.push(qs.redirect);
    } else {
      history.push("/");
    }
  };

  return (
    <div className="content-wrapper">
      <Page>
        <Page.Main>
          <Page.Content>
            <Form className="card">
              <Card.Body>
                <Card.Title>Reset Password</Card.Title>
                <Grid.Row>
                  <Grid.Col md={6} width={12}>
                    <Form.Group>
                      <Form.Label>Email address</Form.Label>
                      <Form.Input
                        type="email"
                        placeholder="Email"
                        value={formState.email}
                        error={formState.emailError}
                        name="email"
                        onChange={(event) => {
                          setFormState({ email: event.target.value });
                        }}
                      />
                    </Form.Group>
                  </Grid.Col>
                </Grid.Row>
              </Card.Body>
              <Card.Footer className="text-right">
                <Button variant="contained" onClick={requestResetLink}>
                  Request Reset Link
                </Button>
              </Card.Footer>
            </Form>
          </Page.Content>
        </Page.Main>
      </Page>
      <LynxDialog
        open={showDialog}
        handleConfirm={handleConfirm}
        title={`Forgot Password Reset Link Sent`}
        description={`An email has been sent to ${formState.email} with a password reset link. This link will expire after 30 minutes.`}
      />
    </div>
  );
}
