export const UserRoles = {
  Admin: "Admin",
  SuperAdmin: "SuperAdmin",
  IncidentsUser: "IncidentsUser",
  InternalUser: "InternalUser",
  InspectionsUser: "InspectionsUser",
  EnergyUser: "EnergyUser",
  TimeSeriesUser: "TimeSeriesUser",
  PortalUser: "PortalUser",
  PortalAdmin: "PortalAdmin",
  //Event roles
  EventsAdministrator: "EventsAdministrator",
  EventsEditor: "EventsEditor",
  EventsContributor: "EventsContributor",
  EventsViewer: "EventsViewer",
  //Monitoring roles
  MonitoringAdministrator: "MonitoringAdministrator",
  MonitoringViewer: "MonitoringViewer",
  MonitoringEditor: "MonitoringEditor",
};

export const PortalUserStatuses = {
  PendingApproval: "Pending Approval",
  PendingRegistration: "Pending Registration",
  Inactive: "Inactive",
  Active: "Active",
  Rejected: "Rejected",
};

export const PortalUserHistoryActions = {
  PageVisit: "PageVisit",
  Logout: "Logout",
  MonitoringLocationMapClick: "MonitoringLocationMapClick",
  LineChartParameterClick: "LineChartParameterClick",
  LineChartView: "LineChartView",
};

export const RtkTagTypes = {
  GetUnitConversions: "GetUnitConversions",
  GetEvents: "GetEvents",
  GetEventsForMap: "GetEventsForMap",
  SearchEvents: "SearchEvents",
  GetDocuments: "GetDocuments",
  GetAuditLogs: "GetAuditLogs",
  GetPhotoImportHistories: "GetPhotoImportHistories",
  GetPhotoImportHistory: "GetPhotoImportHistory",
  GetPhotoImportHistoryResults: "GetPhotoImportHistoryResults",
  GetIncidentWorkflowSettings: "GetIncidentWorkflowSettings",
  GetCustomers: "GetCustomers",
  GetPermits: "GetPermits",
  GetEquipment: "GetEquipment",
  GetParameters: "GetParameters",
  GetMonitoringLocations: "GetMonitoringLocations",
  GetMonitoringEvents: "GetMonitoringEvents",
  GetMonitoringResults: "GetMonitoringResults",
  GetKpis: "GetKpis",
  GetKpi: "GetKpi",
  GetKpiHistory: "GetKpiHistory",
  GetKpiDashboard: "GetKpiDashboard",
  GetInspections: "GetInspections",
  GetInspection: "GetInspection",
  GetInspectionCount: "GetInspectionCount",
  GetInspectionForms: "GetInspectionForms",
  GetInspectionForm: "GetInspectionForm",
  GetCustomer: "GetCustomer",
  GetCorrespondenceLogs: "GetCorrespondenceLogs",
  GetUsersForCustomer: "GetUsersForCustomer",
  GetOrganizations: "GetOrganizations",
  GetEnergyLogs: "GetEnergyLogs",
  GetContacts: "GetContacts",
  GetUsers: "GetUsers",
  GetContactGroups: "GetContactGroups",
  GetMonitoringVarianceLimits: "GetMonitoringVarianceLimits",
  GetMonitoringLimits: "GetMonitoringLimits",
  GetImportHistory: "GetImportHistory",
  GetPortalSubmissions: "GetPortalSubmissions",
  GetPortalUsers: "GetPortalUsers",
  GetPortalUserHistory: "GetPortalUserHistory",
  GetImportSpecifications: "GetImportSpecifications",
  GetUserDashboardWidget: "GetUserDashboardWidget",

  GetOrgComplianceAndRegulations: "GetOrgComplianceAndRegulations",
};
export const EventStatuses = {
  PendingEmailReview: "Pending Email Review",
  Complete: "Complete",
  Incomplete: "Incomplete",
  PendingReview: "Pending Review",
  PendingActions: "Pending Actions",
  IncompleteOverdue: "Incomplete (Overdue)",
};

export const IncidentNotificationStages = {
  IncidentInitialReportSubmitted: "IncidentInitialReportSubmitted",
  IncidentInitialReviewSubmitted: "IncidentInitialReviewSubmitted",
  IncidentInvestigationSubmitted: "IncidentInvestigationSubmitted",
  IncidentFinalReviewSubmitted: "IncidentFinalReviewSubmitted",
  IncidentActionCreated: "IncidentActionCreated",
  IncidentActionApproved: "IncidentActionApproved",
  IncidentActionCompleted: "IncidentActionCompleted",
  IncidentActionVerified: "IncidentActionVerified",
  IncidentActionDue: "IncidentActionDue",
  IncidentActionOverdue: "IncidentActionOverdue",
};

export const IncidentAssignmentStages = {
  IncidentInvestigationAssigned: "IncidentInvestigationAssigned",
  IncidentInitialReviewAssigned: "IncidentInitialReviewAssigned",
  IncidentFinalReviewAssigned: "IncidentFinalReviewAssigned",
  IncidentActionApprovalAssigned: "IncidentActionApprovalAssigned",
  IncidentActionVerificationAssigned: "IncidentActionVerificationAssigned",
  IncidentActionAssigned: "IncidentActionAssigned",
};
export const IncidentUserTypes = {
  InvestigationTeamMember: "InvestigationTeamMember",
  InitialReviewer: "InitialReviewer",
  FinalReviewer: "FinalReviewer",
};
export const ActionUserTypes = {
  Approver: "Approver",
  Verifier: "Verifier",
};
export const MapLayerNames = {
  MonitoringEvents: "Monitoring Events",
  MonitoringLocations: "Monitoring Locations",
  Assets: "Assets",
  Events: "Events",
};

export const MapLayerKeys = {
  MonitoringEvents: "internalMonitoring",
  Assets: "internalAssets",
  Events: "internalEvents",
  MonitoringLocations: "internalMonitoringLocations",
};

export const EntityTypes = {
  Event: "Event",
  Customer: "Customer",
  Incident: "Incident",
  IncidentInvestigation: "IncidentInvestigation",
  MonitoringEvent: "MonitoringEvent",
  MonitoringResult: "MonitoringResult",
  Action: "Action",
  MonitoringVarianceLimit: "MonitoringVarianceLimit",
  MonitoringLocation: "MonitoringLocation",
  InspectionEvent: "InspectionEvent",
  EnergyLog: "EnergyLog",
  Organization: "Organization",
  Equipment: "Equipment",
  Permit: "Permit",
  TimeSeries: "TimeSeries",
  CustomerPortalUserHistory: "CustomerPortalUserHistory",
  Document: "Document",
};

export const TourTypes = {
  Initial: "Initial",
  EventsGrid: "EventsGrid",
  MonitoringGrid: "MonitoringGrid",
  IncidentsGrid: "IncidentsGrid",
};

export const MapSourceTypes = {
  Mapbox: "Mapbox",
  ArcGis: "ArcGis",
};

export const LookupTypes = {
  AssetType: "AssetType",
  EventSubCategory: "EventSubCategory",
  EventCategory: "EventCategory",
  EventLocationType: "EventLocationType",
  ContactType: "ContactType",
  ContactGroupType: "ContactGroupType",
  TagGroup: "TagGroup",
  Tag: "Tag",
  TagCategory: "TagCategory",
  Unit: "Unit",
  MonitoringEventType: "MonitoringEventType",
  ActionCategory: "ActionCategory",
  ActionEvidenceRequired: "ActionEvidenceRequired",
  IncidentParticipantType: "IncidentParticipantType",
  IncidentSeverity: "IncidentSeverity",
  IncidentType: "IncidentType",
  IncidentSubType: "IncidentSubType",
  MonitoringResultType: "MonitoringResultType",
  MonitoringLimitType: "MonitoringLimitType",
  MonitoringLocationType: "MonitoringLocationType",
  //non standard
  Users: "Users",
  Assets: "Assets",
  MonitoringLocations: "MonitoringLocations",
  ImportSpecifications: "ImportSpecifications",
  EnergyParameterUnits: "EnergyParameterUnits",
  EnergyLogType: "EnergyLogType",
  PermitType: "PermitType",
  CorrespondenceLogType: "CorrespondenceLogType",
};

export const VarianceStatuses = {
  Exceedance: "Exceedance",
  NonExceedance: "Non-Exceedance",
};

export const CustomFieldTypes = {
  Dropdown: "Dropdown",
  Text: "Text",
  TextArea: "TextArea",
  Number: "Number",
  Date: "Date",
  DateTime: "DateTime",
  Checkbox: "Checkbox",
  MultiSelect: "MultiSelect",
  Paragraph: "Paragraph",
  Divider: "Divider",
  Signature: "Signature",
};
export const EventContactTypes = {
  Contact: "Contact",
  ContactGroup: "ContactGroup",
  User: "User",
};

export const InspectionFormTypes = {
  IncidentInvestigation: "IncidentInvestigation",
  IncidentParticipant: "IncidentParticipant",
  Inspection: "Inspection",
};
export const LocalStorageKeys = {
  DashboardMapViewport: "dashboard-map-viewport",
  DashboardMapEnabledLayers: "layerLibraryDashboard",
  OfflineTimeout: "offlineTimeout",
  WidgetFilters: "widgetFilters",
  DisableOfflineMode: "disableOfflineMode",
  DisableOfflineDateTime: "disableOfflineDateTime",
  MapFilterSelectedAssets: "mapFilterSelectedAssets",
  MapFilterSelectedMonitoringLocationTypes:
    "mapFilterSelectedMonitoringLocationTypes",
  AccessToken: "access_token",
  Ip: "ip",
};
export const ActionStatuses = {
  Closed: "Closed",
  Complete: "Complete",
  PendingApproval: "Pending Approval",
  Approved: "Approved",
  Overdue: "Overdue",
  PendingVerification: "Pending Verification",
};
export const BulkUpdateActions = {
  Update: "Update",
  UpdateIfEmpty: "UpdateIfEmpty",
  Clear: "Clear",
  Append: "Append",
  Prepend: "Prepend",
};
export const Statuses = {
  Complete: "Complete",
  Incomplete: "Incomplete",
  PendingReview: "Pending Review",
};

export const MonitoringMenuKeys = {
  Events: "Events",
  Locations: "Locations",
  Results: "Results",
  ResultsWide: "Wideresults",
  Timeseries: "Timeseries",
};

export const PhotoImportHistoryStatuses = {
  UploadingPhotos: "UploadingPhotos",
  Staged: "Staged",
  StartingModel: "StartingModel",
  AnalyzingPhotos: "AnalyzingPhotos",
  AwaitingReview: "AwaitingReview",
  Complete: "Complete",
  AwaitingModelTraining: "AwaitingModelTraining",
};

export const MonitoringLocationPortalTypes = {
  SurfaceWater: "SurfaceWater",
  GroundWater: "GroundWater",
  Wildlife: "Wildlife",
  Air: "Air",
  Vegetation: "Vegetation",
};
export const IncidentReportTypes = {
  InitialIncidentReport: "InitialIncidentReport",
  IncidentInvestigationReport: "IncidentInvestigationReport",
  FullIncidentReport: "FullIncidentReport",
};

export const IncidentStatus = {
  PendingReview: "pending review",
  Investigation: "investigation",
  PendingActions: "pending actions",
  FinalReview: "final review",
  Closed: "closed",
};

export const ComplianceApplicability = {
  NotApplicable: { id: 1, name: "Not Applicable" },
  SlightlyApplicable: { id: 2, name: "Slightly Applicable " },
  SomewhatApplicable: { id: 3, name: "Somewhat Applicable" },
  MostlyApplicable: { id: 4, name: "Mostly Applicable" },
  FullyApplicable: { id: 5, name: "Fully Applicable" },
};
