import queryString from "query-string";
import { apiService } from "../apiService";
import { RtkTagTypes } from "types/enums";
import { BaseParams } from "types";

const BASE_ROUTE = "/audit";

const auditEndpoint = apiService.injectEndpoints({
  endpoints: (builder: any) => ({
    getAuditLogs: builder.query({
      query: (baseParams: BaseParams) =>
        `${BASE_ROUTE}?${queryString.stringify(baseParams)}`,
      providesTags: [RtkTagTypes.GetAuditLogs],
      transformResponse: (response: any, meta: any) => {
        const pagination = meta.response.headers.get("Pagination");
        return { data: response, pagination };
      },
    }),
  }),
});

export const { useGetAuditLogsQuery, useLazyGetAuditLogsQuery } = auditEndpoint;
