import _ from "lodash";
import moment from "moment";
import { apiUrl, client } from "../modules/store";
import { GridFilterItem, GridFilterModel } from "@mui/x-data-grid-pro";
const isEqualTo = ["is", "equals", "="];
const isNotEqualTo = ["!=", "not"];
const startsWith = ["startsWith"];
const endsWith = ["endsWith"];
const contains = ["contains"];
const doesNotContain = ["doesNotContain"];
const isEmpty = ["isEmpty"];
const isNotEmpty = ["isNotEmpty"];
const isGreaterThan = [">", "after"];
const isGreatThanOrEqualTo = [">=", "onOrAfter"];
const isLessThan = ["<", "before"];
const isLessThanOrEqualTo = ["<=", "onOrBefore"];
const isAnyOf = ["isAnyOf"];
const isRange = ["isRange"];
const isBetween = ["isBetween"];

export function performGroupByTranslations(field: any, value: any) {
  if (value == "Empty") {
    return "null";
  }
  if (
    field == "eventDateTime" ||
    field == "endDateTimeUtc" ||
    field == "startDateTimeUtc" ||
    field == "lastReviewedDateTimeUtc"
  ) {
    return moment(value).utc().format();
  }

  if (field == "isNonCompliant") {
    return value == "Noncompliant" ? "true" : "false";
  }
  if (value == "True") {
    return "true";
  }
  if (value == "False") {
    return "false";
  }

  return value;
}

export function performValueTranslations(item: GridFilterItem) {
  if (!item.value) return null;
  if (item.value == "Empty") {
    return "null";
  }
  if (
    (item.field == "eventDateTime" ||
      item.field == "endDateTimeUtc" ||
      item.field == "startDateTimeUtc" ||
      item.field == "lastReviewedDateTimeUtc" ||
      item.field == "incidentDateTime" ||
      (item.field.includes("customFieldData.") &&
        item.field.includes(".dateTimeValue"))) &&
    item.operator != "isRange" &&
    item.operator != "isBetween"
  ) {
    return moment(item.value).utc().format();
  }

  if (item.operator == "isBetween" && item.value) {
    if (item.value[0] != "today") {
      item.value[0] = moment(item.value[0]).utc().format();
    }
    if (item.value[1] != "today") {
      item.value[1] = moment(item.value[1]).utc().format();
    }
  }

  if (item.value == "True") {
    return "true";
  }
  if (item.value == "False") {
    return "false";
  }

  return item.value;
}
export function convertFilterDefToQueryString(filterDef: GridFilterModel) {
  var queryString = "";
  var filterOperator = filterDef.logicOperator === "or" ? "//" : "++";
  filterDef.items.forEach((item) => {
    item.value = performValueTranslations(item);
    if (_.includes(isEqualTo, item.operator) && item.value) {
      queryString += `${item.field}==${item.value}${filterOperator}`;
    }
    if (_.includes(isNotEqualTo, item.operator) && item.value) {
      queryString += `${item.field}!=${item.value}${filterOperator}`;
    }
    if (_.includes(startsWith, item.operator) && item.value) {
      queryString += `${item.field}=^${item.value}${filterOperator}`;
    }
    if (_.includes(endsWith, item.operator) && item.value) {
      queryString += `${item.field}=$${item.value}${filterOperator}`;
    }
    if (_.includes(contains, item.operator) && item.value) {
      queryString += `${item.field}=@${item.value}${filterOperator}`;
    }
    if (_.includes(doesNotContain, item.operator) && item.value) {
      queryString += `${item.field}!@${item.value}${filterOperator}`;
    }
    if (_.includes(isEmpty, item.operator)) {
      queryString += `${item.field}!!${filterOperator}`;
    }
    if (_.includes(isNotEmpty, item.operator)) {
      queryString += `${item.field}@@${filterOperator}`;
    }
    if (_.includes(isGreaterThan, item.operator) && item.value) {
      queryString += `${item.field}>>${item.value}${filterOperator}`;
    }
    if (_.includes(isGreatThanOrEqualTo, item.operator) && item.value) {
      queryString += `${item.field}>=${item.value}${filterOperator}`;
    }
    if (_.includes(isLessThan, item.operator) && item.value) {
      queryString += `${item.field}<<${item.value}${filterOperator}`;
    }
    if (_.includes(isLessThanOrEqualTo, item.operator) && item.value) {
      queryString += `${item.field}<=${item.value}${filterOperator}`;
    }
    if (_.includes(isAnyOf, item.operator) && !_.isEmpty(item.value)) {
      var valueString = item.value.join("|");
      queryString += `${item.field}<>${valueString}${filterOperator}`;
    }
    if (_.includes(isRange, item.operator) && !_.isEmpty(item.value)) {
      var valueStringRange = item.value.join("|");
      queryString += `${item.field}^^${valueStringRange}${filterOperator}`;
    }
    if (_.includes(isBetween, item.operator) && !_.isEmpty(item.value)) {
      var valueStringBetween = item.value.join("|");
      queryString += `${item.field}**${valueStringBetween}${filterOperator}`;
    }
  });
  return queryString;
}

export function saveFilter(dto: any) {
  return client.post(apiUrl + "/filters", {
    ...dto,
  });
}

export function getFilters(entityType: any) {
  return client.get(apiUrl + "/filters?entityType=" + entityType);
}

export function deleteFilter(filterId: any) {
  return client.delete(apiUrl + "/filters/" + filterId);
}
