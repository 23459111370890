import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import GroupIcon from "@mui/icons-material/Group";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import MuiGrid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Dimmer, Form, Grid } from "components/lynx-components";
import { roleMatch } from "../../../actions/auth";
import { validationService } from "../../../services";

import { dateUtil } from "../../../services/date-util";
import { getLookups } from "../../../services/lookup";
import {
  EventContactTypes,
  LookupTypes,
  UserRoles,
} from "../../../types/enums";
import { LynxTextArea } from "../../form-controls/lynx-form-controls";
import { LynxDialog } from "../../lynx-dialog";
import { CorrespondenceContactChip } from "./correspondence-contact-chip";
import useAlert from "hooks/useAlert";
import {
  useCreateCorrespondenceLogMutation,
  useDeleteCorrespondenceLogMutation,
  useUpdateCorrespondenceLogMutation,
} from "services/rtkApi/endpoints/correspondenceLogs";
import { useLazySearchContactsQuery } from "services/rtkApi/endpoints/contacts";
export function CorrespondenceLogModal(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [correspondenceLog, setCorrespondenceLog] = useState(null);
  const [formState, setFormState] = useState({
    correspondenceDateTimeUtc: "",
    correspondenceDateTimeUtcError: "",
    subject: "",
    correspondenceTypeId: "",
    correspondenceTypeIdError: "",
    subjectError: "",
    details: "",
    fromDetails: "",
    toDetails: "",
  });
  const [showDelete, setShowDelete] = useState(false);
  const [toOptions, setToOptions] = useState([]);
  const [correspondenceTypes, setCorrespondenceTypes] = useState([]);
  const [fromOptions, setFromOptions] = useState([]);
  const [toOptionsLoading, setToOptionsLoading] = useState(false);
  const [fromOptionsLoading, setFromOptionsLoading] = useState(false);
  const [correspondenceLogContacts, setCorrespondenceLogContacts] = useState(
    []
  );
  const [autoCompleteToContactValue, setAutoCompleteToContactValue] =
    useState(null);
  const [autoCompleteToContactInputValue, setAutoCompleteToContactInputValue] =
    useState("");

  const [autoCompleteFromContactValue, setAutoCompleteFromContactValue] =
    useState(null);
  const [
    autoCompleteFromContactInputValue,
    setAutoCompleteFromContactInputValue,
  ] = useState("");
  const [searchTrigger] = useLazySearchContactsQuery();
  const [addTrigger] = useCreateCorrespondenceLogMutation();
  const [updateTrigger] = useUpdateCorrespondenceLogMutation();
  const [deleteTrigger] = useDeleteCorrespondenceLogMutation();
  useEffect(() => {
    if (props.correspondenceLog) {
      setCorrespondenceLog(props.correspondenceLog);
      setFormStateFromProps(props.correspondenceLog);
    }
    getLookups(LookupTypes.CorrespondenceLogType).then((res) => {
      setCorrespondenceTypes(res.data);
      setIsLoading(false);
    });
  }, []);

  const { showAlert } = useAlert();

  const setFormStateFromProps = (correspondenceLog) => {
    setFormState({
      correspondenceDateTimeUtc: dateUtil.convertDateTimeToLocal(
        correspondenceLog.correspondenceDateTimeUtc
      ),
      correspondenceTypeId: correspondenceLog.correspondenceTypeId,
      subject: correspondenceLog.subject,
      details: correspondenceLog.details,
      fromDetails: correspondenceLog.fromDetails,
      toDetails: correspondenceLog.toDetails,
    });
    setCorrespondenceLogContacts(correspondenceLog.correspondenceLogContacts);
  };
  useEffect(() => {
    if (autoCompleteToContactInputValue === "") {
      setToOptions(
        autoCompleteToContactValue ? [autoCompleteToContactValue] : []
      );
      return;
    }
    setToOptionsLoading(true);
    searchTrigger({
      query: autoCompleteToContactInputValue,
      includeUsers: true,
    }).then((res) => {
      if (res.data) {
        setToOptions(res.data);
        setToOptionsLoading(false);
      }
    });
  }, [autoCompleteToContactValue, autoCompleteToContactInputValue]);

  useEffect(() => {
    if (autoCompleteFromContactInputValue === "") {
      setFromOptions(
        autoCompleteFromContactValue ? [autoCompleteFromContactValue] : []
      );
      return;
    }
    setFromOptionsLoading(true);
    searchTrigger({
      query: autoCompleteFromContactInputValue,
      includeUsers: true,
    }).then((res) => {
      if (res.data) {
        setFromOptions(res.data);
        setFromOptionsLoading(false);
      }
    });
  }, [autoCompleteFromContactValue, autoCompleteFromContactInputValue]);
  useEffect(() => {}, [correspondenceTypes]);
  const handleInputChange = (e) => {
    let newState = { ...formState };
    let name = e.target.name;
    let value = e.target.value;
    _.set(newState, name, value);
    setFormState(newState);
  };
  const getContactOptions = (isFrom) => {
    let options = isFrom ? fromOptions : toOptions;
    var contactOptions = options.filter(
      (x) =>
        x.type == EventContactTypes.Contact &&
        !correspondenceLogContacts
          .filter((x) => x.contactId != null && x.isFrom == isFrom)
          .map((y) => y.contactId)
          .includes(x.id)
    );
    var contactGroupOptions = options.filter(
      (x) =>
        x.type == EventContactTypes.ContactGroup &&
        !correspondenceLogContacts
          .filter((x) => x.contactGroupId != null && x.isFrom == isFrom)
          .map((y) => y.contactGroupId)
          .includes(x.id)
    );

    var userOptions = options.filter(
      (x) =>
        x.type == EventContactTypes.User &&
        !correspondenceLogContacts
          .filter((x) => x.userId != null && x.isFrom == isFrom)
          .map((y) => y.userId)
          .includes(x.id)
    );

    var allOptions = [
      ...contactOptions,
      ...contactGroupOptions,
      ...userOptions,
    ];
    return _.sortBy(allOptions, [(contact) => contact.name?.toLowerCase()]);
  };
  const handleContactSelected = (e, value, isFrom) => {
    if (value) {
      isFrom
        ? setAutoCompleteFromContactValue(null)
        : setAutoCompleteToContactValue(null);
      let newContacts = _.cloneDeep(correspondenceLogContacts);
      let existingContact = newContacts.find(
        (x) => x.id == value.id && x.type == value.type
      );
      if (!existingContact) {
        const contactsToSet = [
          ...newContacts,
          { ...transformContactLookupToCoresspondenceContact(value, isFrom) },
        ];
        setCorrespondenceLogContacts(contactsToSet);
      }
    }
  };

  const transformContactLookupToCoresspondenceContact = (contact, isFrom) => {
    return {
      userId: contact.type == EventContactTypes.User ? contact.id : null,
      userFullName:
        contact.type == EventContactTypes.User ? contact.name : null,
      contactId: contact.type == EventContactTypes.Contact ? contact.id : null,
      contactFullName:
        contact.type == EventContactTypes.Contact ? contact.name : null,
      contactGroupId:
        contact.type == EventContactTypes.ContactGroup ? contact.id : null,
      contactGroupName:
        contact.type == EventContactTypes.ContactGroup ? contact.name : null,
      isFrom: isFrom,
      isTo: !isFrom,
    };
  };

  const autoCompleteContactInputChange = (e, newValue, reason, isFrom) => {
    if (reason === "reset") {
      isFrom
        ? setAutoCompleteFromContactValue(null)
        : setAutoCompleteToContactValue(null);
      return;
    } else {
      isFrom
        ? setAutoCompleteFromContactInputValue(newValue)
        : setAutoCompleteToContactInputValue(newValue);
    }
  };
  const handleContactRemove = (contact) => {
    var allContacts = [...correspondenceLogContacts];
    var contactIndex = null;
    if (contact.userId) {
      contactIndex = allContacts.findIndex(
        (x) =>
          x.userId == contact.userId &&
          x.isTo == contact.isTo &&
          x.ifFrom == contact.ifFrom
      );
    }
    if (contact.contactId) {
      contactIndex = allContacts.findIndex(
        (x) =>
          x.contactId == contact.contactId &&
          x.isTo == contact.isTo &&
          x.ifFrom == contact.ifFrom
      );
    }
    if (contact.contactGroupId) {
      contactIndex = allContacts.findIndex(
        (x) =>
          x.contactGroupId == contact.contactGroupId &&
          x.isTo == contact.isTo &&
          x.ifFrom == contact.ifFrom
      );
    }
    if (contactIndex != null) {
      allContacts.splice(contactIndex, 1);
    }

    setCorrespondenceLogContacts(allContacts);
  };
  const logForm = (
    <Dimmer active={isLoading} loader>
      <Grid>
        <Grid.Row>
          <Grid.Col md={6} width={12}>
            <Form.Group isRequired label="Correspondence Date Time">
              <Form.Input
                type="datetime-local"
                value={formState.correspondenceDateTimeUtc}
                name="correspondenceDateTimeUtc"
                onChange={handleInputChange}
                error={formState.correspondenceDateTimeUtcError}
              />
            </Form.Group>
          </Grid.Col>
          <Grid.Col md={6} width={12}>
            <Form.Group isRequired label="Correspondence Type">
              <Form.Select
                onChange={handleInputChange}
                name={`correspondenceTypeId`}
                value={formState.correspondenceTypeId}
                error={formState.correspondenceTypeIdError}
              >
                <option value={""}></option>
                {correspondenceTypes.map((type) => (
                  <option value={type.id} key={type.id}>
                    {type.code}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Grid.Col>
          <Grid.Col md={12} width={12}>
            <Form.Group isRequired={true} label="Subject">
              <Form.Input
                type="text"
                onChange={handleInputChange}
                name={`subject`}
                error={formState.subjectError}
                value={formState.subject}
              ></Form.Input>
            </Form.Group>
          </Grid.Col>
          <Grid.Col md={12} width={12}>
            <Form.Group label="Details">
              <LynxTextArea
                onChange={handleInputChange}
                name={`details`}
                value={formState.details}
                error={formState.detailsError}
              ></LynxTextArea>
            </Form.Group>
          </Grid.Col>

          <Grid.Col md={6} width={12}>
            <Form.Group isRequired label="Correspondence From:">
              <LynxTextArea
                onChange={handleInputChange}
                name={`fromDetails`}
                className="mb-2"
                value={formState.fromDetails}
                error={formState.fromDetailsError}
              ></LynxTextArea>
              <Autocomplete
                loading={fromOptionsLoading}
                filterOptions={(x) => x}
                disabled={props.eventLocked}
                id="contact-grouped-search"
                options={getContactOptions(true)}
                noOptionsText={
                  !autoCompleteFromContactInputValue
                    ? "Enter text to search"
                    : "No options"
                }
                getOptionLabel={(option) =>
                  option.name + " " + option.contactGroupName
                }
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option.type == EventContactTypes.ContactGroup && (
                      <GroupIcon color="disabled" className="mr-2" />
                    )}
                    {option.type == EventContactTypes.User && (
                      <AccountCircleIcon color="disabled" className="mr-2" />
                    )}
                    {option.name}
                    <Typography variant="body2" className="ml-2">
                      {option.contactGroupName}
                    </Typography>
                  </Box>
                )}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Search users, contacts or contact groups"
                  />
                )}
                onChange={(e, value) => {
                  handleContactSelected(e, value, true);
                }}
                value={autoCompleteFromContactValue}
                inputValue={autoCompleteFromContactInputValue}
                onInputChange={(e, newValue, reason) => {
                  autoCompleteContactInputChange(e, newValue, reason, true);
                }}
              />{" "}
              <MuiGrid container spacing={1} className="mt-2">
                {_.sortBy(
                  correspondenceLogContacts.filter((x) => x.isFrom),
                  [
                    (contact) =>
                      contact.userId
                        ? contact.userFullName?.toLowerCase()
                        : contact.contactId
                        ? contact.contactFullName?.toLowerCase()
                        : contact.contactGroupId
                        ? contact.contactGroupName?.toLowerCase()
                        : "",
                  ]
                ).map((contact, i) => (
                  <MuiGrid item key={`corLogContact_${i}`}>
                    <CorrespondenceContactChip
                      contact={contact}
                      onDelete={handleContactRemove}
                    />
                  </MuiGrid>
                ))}
              </MuiGrid>
            </Form.Group>
          </Grid.Col>
          <Grid.Col md={6} width={12}>
            <Form.Group isRequired label="Correspondence To:">
              <LynxTextArea
                onChange={handleInputChange}
                name={`toDetails`}
                className="mb-2"
                value={formState.toDetails}
                error={formState.toDetailsError}
              ></LynxTextArea>
              <Autocomplete
                loading={toOptionsLoading}
                filterOptions={(x) => x}
                disabled={props.eventLocked}
                id="contact-grouped-search"
                options={getContactOptions(false)}
                noOptionsText={
                  !autoCompleteToContactInputValue
                    ? "Enter text to search"
                    : "No options"
                }
                getOptionLabel={(option) =>
                  option.name + " " + option.contactGroupName
                }
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option.type == EventContactTypes.ContactGroup && (
                      <GroupIcon color="disabled" className="mr-2" />
                    )}
                    {option.type == EventContactTypes.User && (
                      <AccountCircleIcon color="disabled" className="mr-2" />
                    )}
                    {option.name}
                    <Typography variant="body2" className="ml-2">
                      {option.contactGroupName}
                    </Typography>
                  </Box>
                )}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Search users, contacts or contact groups"
                  />
                )}
                onChange={(e, value) => {
                  handleContactSelected(e, value, false);
                }}
                value={autoCompleteToContactValue}
                inputValue={autoCompleteToContactInputValue}
                onInputChange={(e, newValue, reason) => {
                  autoCompleteContactInputChange(e, newValue, reason, false);
                }}
              />{" "}
              <MuiGrid container spacing={1} className="mt-2">
                {_.sortBy(
                  correspondenceLogContacts.filter((x) => x.isTo),
                  [
                    (contact) =>
                      contact.userId
                        ? contact.userFullName?.toLowerCase()
                        : contact.contactId
                        ? contact.contactFullName?.toLowerCase()
                        : contact.contactGroupId
                        ? contact.contactGroupName?.toLowerCase()
                        : "",
                  ]
                ).map((contact, i) => (
                  <MuiGrid item key={`corLogContact_${i}`}>
                    <CorrespondenceContactChip
                      contact={contact}
                      onDelete={handleContactRemove}
                    />
                  </MuiGrid>
                ))}
              </MuiGrid>
            </Form.Group>
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </Dimmer>
  );
  const handleDelete = () => {
    setShowDelete(true);
  };
  const handleSaveLog = () => {
    if (!validateDataForSave()) {
      return;
    }
    setIsLoading(true);
    let dto = validationService.unsetErrors(
      formState,
      "correspondenceDateTimeUtcError",
      "correspondenceTypeIdError",
      "subjectError",
      "toDetailsError",
      "fromDetailsError"
    );
    dto.correspondenceLogContacts = correspondenceLogContacts;
    dto.eventId = props.event.id;
    dto.correspondenceDateTimeUtc = moment(dto.correspondenceDateTimeUtc)
      .utc()
      .format();
    if (correspondenceLog != null) {
      updateTrigger({ id: correspondenceLog.id, dto: dto }).then((res) => {
        if (res.data) {
          showAlert("success", "Correspondence log saved.");
        }
        props.handleClose();
      });
    } else {
      addTrigger(dto).then((res) => {
        if (res.data) {
          showAlert("success", "Correspondence log created.");
        }

        props.handleClose();
      });
    }
  };
  const deleteCorLog = () => {
    deleteTrigger(correspondenceLog.id).then((res) => {
      if (res) {
        showAlert("success", "Correspondence log deleted.");
      }
      props.handleClose();
    });
  };
  const validateDataForSave = () => {
    let newState = { ...formState };
    let isFormValid = false;
    validationService.validateRequiredField(
      newState,
      "correspondenceDateTimeUtc",
      "correspondenceDateTimeUtcError",
      "Correspondence Date Time"
    );
    validationService.validateRequiredField(
      newState,
      "correspondenceTypeId",
      "correspondenceTypeIdError",
      "Correspondence Type"
    );
    validationService.validateRequiredField(
      newState,
      "subject",
      "subjectError",
      "Subject"
    );

    if (
      _.isEmpty(correspondenceLogContacts.filter((x) => x.isTo)) &&
      !formState.toDetails
    ) {
      newState.toDetailsError =
        "Correspondence To is required. Enter text or search from the list below.";
    } else {
      _.unset(newState, "toDetailsError");
    }
    if (
      _.isEmpty(correspondenceLogContacts.filter((x) => x.isFrom)) &&
      !formState.fromDetails
    ) {
      newState.fromDetailsError =
        "Correspondence From is required. Enter text or search from the list below.";
    } else {
      _.unset(newState, "fromDetailsError");
    }
    isFormValid = !validationService.hasError(
      newState,
      "correspondenceDateTimeUtcError",
      "correspondenceTypeIdError",
      "subjectError",
      "toDetailsError",
      "fromDetailsError"
    );
    if (!isFormValid) {
      setFormState(newState);
      showAlert("error", "Form is not valid for saving.");
    }
    return isFormValid;
  };
  return (
    <>
      <LynxDialog
        dividers
        maxWidth={"md"}
        open={props.open}
        title={`Add Correspondence Log`}
        fullWidth
        dialogContent={logForm}
        handleDelete={
          correspondenceLog != null && roleMatch([UserRoles.Admin])
            ? handleDelete
            : null
        }
        handleClose={() => {
          props.handleClose();
        }}
        handleSave={handleSaveLog}
      />
      <LynxDialog
        dividers
        open={showDelete}
        title={`Delete Correspondence Log?`}
        description={
          "Are you sure you want to delete this correspondence log? This action cannot be undone."
        }
        handleClose={() => {
          setShowDelete(false);
        }}
        handleConfirm={deleteCorLog}
      />
    </>
  );
}
