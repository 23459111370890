import { useGetUserPortalHistoryQuery } from "services/rtkApi/endpoints/customerPortal";
import { EntityTypes } from "types/enums-ts";
import { LynxDataGrid } from "../../../data-grid/lynx-data-grid";
import { userHistoryColumns } from "./user-history-columns";
export function CustomerPortalHistory() {
  return (
    <LynxDataGrid
      title="Customer Portal User History"
      columns={userHistoryColumns}
      useQuery={useGetUserPortalHistoryQuery}
      localStorageName="customer_portal_user_history"
      entityType={EntityTypes.CustomerPortalUserHistory}
    />
  );
}
