import queryString from "query-string";
import { apiService } from "../apiService";
import { RtkTagTypes } from "types/enums";
import { BaseParams, UserDto } from "types";

const BASE_ROUTE = "/users";

const usersEndpoint = apiService.injectEndpoints({
  endpoints: (builder) => ({
    // Get user lookups
    getUserLookups: builder.query({
      query: () => `${BASE_ROUTE}/lookup`,
      providesTags: [RtkTagTypes.GetUsers],
    }),

    // Get users with pagination
    getUsers: builder.query({
      query: (userParams: BaseParams) =>
        `${BASE_ROUTE}?${queryString.stringify(userParams)}`,
      providesTags: [RtkTagTypes.GetUsers],
      transformResponse: (response, meta) => {
        const pagination = meta?.response?.headers.get("Pagination");
        return { data: response, pagination };
      },
    }),

    // Get portal users with pagination
    getPortalUsers: builder.query({
      query: (userParams: BaseParams) =>
        `${BASE_ROUTE}/portal?${queryString.stringify(userParams)}`,
      providesTags: [RtkTagTypes.GetPortalUsers],
      transformResponse: (response, meta) => {
        const pagination = meta?.response?.headers.get("Pagination");
        return { data: response, pagination };
      },
    }),

    // Get portal user by ID
    getPortalUserById: builder.query({
      query: (id: number) => `${BASE_ROUTE}/portal/${id}`,
      providesTags: (result, error, id) => [
        { type: RtkTagTypes.GetPortalUsers, id },
      ],
    }),

    // Get user by ID
    getUserById: builder.query({
      query: (userId: number) => `${BASE_ROUTE}/lookup/${userId}`,
      providesTags: (result, error, userId) => [
        { type: RtkTagTypes.GetUsers, userId },
      ],
    }),

    // Update user (patch)
    patchUser: builder.mutation({
      query: ({ id, dto }: { id: number; dto: UserDto }) => ({
        url: `${BASE_ROUTE}/${id}`,
        method: "PATCH",
        body: dto,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: RtkTagTypes.GetUsers, id },
        RtkTagTypes.GetUsers,
      ],
    }),

    // Reset user password
    resetPassword: builder.mutation({
      query: ({ id, dto }: { id: number; dto: any }) => ({
        url: `${BASE_ROUTE}/${id}/password`,
        method: "PUT",
        body: dto,
      }),
    }),

    // Add portal user
    addPortalUser: builder.mutation({
      query: (dto: UserDto) => ({
        url: `${BASE_ROUTE}/portal`,
        method: "POST",
        body: dto,
      }),
      invalidatesTags: [RtkTagTypes.GetPortalUsers],
    }),

    // Delete portal user
    deletePortalUser: builder.mutation({
      query: (id: number) => ({
        url: `${BASE_ROUTE}/portal/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [RtkTagTypes.GetPortalUsers],
    }),

    // Update portal user (admin)
    updatePortalUserAdmin: builder.mutation({
      query: ({ id, dto }: { id: number; dto: UserDto }) => ({
        url: `${BASE_ROUTE}/portal/${id}`,
        method: "PUT",
        body: dto,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: RtkTagTypes.GetPortalUsers, id },
        RtkTagTypes.GetPortalUsers,
      ],
    }),

    // Approve or reject portal user
    approveRejectPortalUser: builder.mutation({
      query: ({
        id,
        dto,
        isApproved,
      }: {
        id: number;
        dto: UserDto;
        isApproved: boolean;
      }) => ({
        url: `${BASE_ROUTE}/portalApproval/${id}?isApproved=${isApproved}`,
        method: "PUT",
        body: dto,
      }),
      invalidatesTags: [RtkTagTypes.GetPortalUsers],
    }),

    // Update portal user (self)
    updatePortalUser: builder.mutation({
      query: (dto: UserDto) => ({
        url: `${BASE_ROUTE}/portalSelf`,
        method: "PUT",
        body: dto,
      }),
      invalidatesTags: [RtkTagTypes.GetPortalUsers],
    }),

    // Get users for a specific customer
    getUsersForCustomer: builder.query({
      query: ({ customerId, ...rest }: { rest: any; customerId: number }) =>
        `${BASE_ROUTE}/customers/${customerId}?${queryString.stringify(rest)}`,
      providesTags: [RtkTagTypes.GetUsers],
      transformResponse: (response, meta) => {
        const pagination = meta?.response?.headers.get("Pagination");
        return { data: response, pagination };
      },
    }),

    // Get permissions for a lookup
    getPermissionsForLookup: builder.query({
      query: ({
        lookupType,
        lookupId,
      }: {
        lookupType: string;
        lookupId: number;
      }) =>
        `${BASE_ROUTE}/permissions?lookupType=${lookupType}&lookupId=${lookupId}`,
    }),

    // Get permissions for a user
    getPermissionsForUser: builder.query({
      query: (orgUserId: number) => `${BASE_ROUTE}/${orgUserId}/permissions`,
    }),
  }),
});

export const {
  useGetUserLookupsQuery,
  useGetUsersQuery,
  useGetPortalUsersQuery,
  useLazyGetPortalUserByIdQuery,
  useGetUserByIdQuery,
  usePatchUserMutation,
  useResetPasswordMutation,
  useAddPortalUserMutation,
  useDeletePortalUserMutation,
  useUpdatePortalUserAdminMutation,
  useApproveRejectPortalUserMutation,
  useUpdatePortalUserMutation,
  useGetUsersForCustomerQuery,
  useGetPermissionsForLookupQuery,
  useGetPermissionsForUserQuery,
} = usersEndpoint;
