import queryString from "query-string";
import { apiService } from "../apiService";
import { RtkTagTypes } from "types/enums";
import { BaseParams } from "types";

const BASE_ROUTE = "/energyLogs";

const energyLogsEndpoint = apiService.injectEndpoints({
  endpoints: (builder: any) => ({
    // Fetch all energy logs with pagination
    getEnergyLogs: builder.query({
      query: (baseParams: BaseParams) =>
        `${BASE_ROUTE}?${queryString.stringify(baseParams)}`,
      providesTags: [RtkTagTypes.GetEnergyLogs],
      transformResponse: (response: any, meta: any) => {
        const pagination = meta.response.headers.get("Pagination");
        return { data: response, pagination };
      },
    }),

    // Fetch an energy log by ID
    getEnergyLogById: builder.query({
      query: (id: number) => `${BASE_ROUTE}/${id}`,
      providesTags: (result: any, error: any, id: any) => [
        { type: RtkTagTypes.GetEnergyLogs, id },
      ],
    }),

    // Add a new energy log
    addEnergyLog: builder.mutation({
      query: ({
        formData,
        saveAction,
      }: {
        formData: FormData;
        saveAction: string;
      }) => ({
        url: `${BASE_ROUTE}?saveAction=${saveAction}`,
        method: "POST",
        body: formData,
        headers: { "Content-Type": "multipart/form-data" },
      }),
      invalidatesTags: [RtkTagTypes.GetEnergyLogs],
    }),

    // Update an energy log (patch)
    patchEnergyLog: builder.mutation({
      query: ({
        id,
        energyLogPatch,
        saveAction,
      }: {
        id: number;
        energyLogPatch: any;
        saveAction: string;
      }) => ({
        url: `${BASE_ROUTE}/${id}?saveAction=${saveAction}`,
        method: "PATCH",
        body: energyLogPatch,
      }),
      invalidatesTags: (result: any, error: any, { id }: any) => [
        { type: RtkTagTypes.GetEnergyLogs, id },
        RtkTagTypes.GetEnergyLogs,
      ],
    }),

    // Delete an energy log
    deleteEnergyLog: builder.mutation({
      query: (id: number) => ({
        url: `${BASE_ROUTE}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [RtkTagTypes.GetEnergyLogs],
    }),

    // Export energy logs as a file
    exportEnergyLogs: builder.mutation({
      query: ({
        baseParams,
        gridColumnDefinitions,
      }: {
        baseParams: BaseParams;
        gridColumnDefinitions: any[];
      }) => ({
        url: `${BASE_ROUTE}/export?${queryString.stringify(baseParams)}`,
        method: "POST",
        body: gridColumnDefinitions,
        responseType: "blob", // Ensure the response is treated as a file
      }),
    }),
  }),
});

export const {
  useGetEnergyLogsQuery,
  useLazyGetEnergyLogsQuery,
  useGetEnergyLogByIdQuery,
  useAddEnergyLogMutation,
  usePatchEnergyLogMutation,
  useDeleteEnergyLogMutation,
  useExportEnergyLogsMutation,
} = energyLogsEndpoint;
