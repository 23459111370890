import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton, Popover } from "@mui/material";
import { useState, useEffect } from "react";
import { LynxDataGrid } from "../../data-grid/lynx-data-grid";
import { contactColumns as cc } from "./contact-columns";
import { contactGroupColumns as cgc } from "./contact-group-columns";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import queryString from "query-string";
import _ from "lodash";
import { localStorageService } from "../../../services/local-storage-service";
import { ContactGroupModal } from "./contact-group-modal";
import { roleMatch } from "actions/auth";
import { EventContactTypes, UserRoles } from "types/enums";
import { useHistory, useLocation } from "react-router-dom";
import { ContactQuickCard } from "./contact-quick-card";
import { LynxGridColDef } from "types";
import {
  useGetContactGroupsQuery,
  useGetContactsQuery,
} from "services/rtkApi/endpoints/contacts";
import { EntityTypes } from "types/enums-ts";

export function ContactList() {
  const [gridView, setGridView] = useState("");
  const [contactColumns, setContactColumns] = useState<LynxGridColDef[]>([]);
  const [contactGroupColumns, setContactGroupColumns] = useState<
    LynxGridColDef[]
  >([]);
  const [showAddGroupModal, setShowAddGroupModal] = useState(false);
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const dimensions = useWindowDimensions();
  const lsKey = "contact_grid_view";
  const [selectedContactIdToView, setSelectedContactIdToView] = useState<{
    id: number;
    type: string;
  } | null>(null);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    prepColumns();
  }, []);
  useEffect(() => {
    const values = queryString.parse(location.search);
    if (!values.view) {
      var selectedGridView = localStorageService.getLocalStorage(lsKey);
      if (selectedGridView) {
        setGridView(selectedGridView);
        history.push(`/contacts?view=${_.toLower(selectedGridView)}`);
      } else {
        setGridView("contacts");
        history.push("/contacts?view=contacts");
      }
    } else {
      setGridView(values.view ? (values.view as string) : "contacts");
    }
  }, []);
  const handleEditClick = (id: number) => {
    handleNavigateTo(null, "/contacts/" + id);
  };

  const prepColumns = () => {
    const actionCol = {
      field: "actions",
      headerName: ``,
      width: 95,
      type: "string",
      sortable: false,
      filterable: false,
      renderCell: (params: any) => (
        <>
          <IconButton
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
              setSelectedContactIdToView({
                id: params.row.id,
                type: EventContactTypes.Contact,
              });
            }}
          >
            <VisibilityIcon />
          </IconButton>
          {canAddEdit && (
            <IconButton onClick={() => handleEditClick(params.row.id)}>
              <EditIcon />
            </IconButton>
          )}
        </>
      ),
    } as LynxGridColDef;
    let contactCols = [actionCol, ...cc];
    let colIndex = contactCols.findIndex(
      (x) => x.field == "numberOfAssociatedEvents"
    );
    contactCols[colIndex].renderCell = (params: any) => {
      if (params.value > 0)
        return (
          <Link
            underline="hover"
            href="#"
            onClick={(e) => {
              handleNavigateTo(e, `events?contactId=${params.row.id}`);
            }}
          >
            {params.value}
          </Link>
        );
      return params.value;
    };
    setContactColumns(contactCols);

    const actionColGroup = {
      field: "actions",
      headerName: ``,
      width: 95,
      type: "string",
      sortable: false,
      filterable: false,
      renderCell: (params: any) => (
        <>
          <IconButton
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
              setSelectedContactIdToView({
                id: params.row.id,
                type: EventContactTypes.ContactGroup,
              });
            }}
          >
            <VisibilityIcon />
          </IconButton>
          {canAddEdit && (
            <IconButton
              onClick={() => {
                setSelectedId(params.row.id);
                setShowAddGroupModal(true);
              }}
            >
              <EditIcon />
            </IconButton>
          )}
        </>
      ),
    } as LynxGridColDef;

    let contactGroupCols = [actionColGroup, ...cgc];
    let colIndex2 = contactGroupCols.findIndex(
      (x) => x.field == "numberOfAssociatedEvents"
    );
    contactGroupCols[colIndex2].renderCell = (params: any) => {
      if (params.value > 0)
        return (
          <Link
            underline="hover"
            href="#"
            onClick={(e) => {
              handleNavigateTo(e, `events?contactGroupId=${params.row.id}`);
            }}
          >
            {params.value}
          </Link>
        );
      return params.value;
    };
    setContactGroupColumns(contactGroupCols);
  };
  const handleNavigateTo = (e: any, url: string) => {
    if (e && e != null) {
      e.preventDefault();
    }
    history.push(url);
  };
  const handleGridViewChange = (e: any) => {
    setGridView(e.target.value);
    localStorageService.setLocalStorage(lsKey, e.target.value);
    history.push(`/contacts?view=${_.toLower(e.target.value)}`);
  };
  const getContactListHeader = () => {
    return (
      <>
        <Typography
          variant="h3"
          component="div"
          className="align-self-center mr-5"
        >
          Contacts
        </Typography>

        <span className=" align-self-end grid-view-select">
          <FormControl
            variant="standard"
            sx={
              dimensions.isMobile
                ? {
                    minWidth: 250,
                  }
                : {
                    marginBottom: 1,
                    marginTop: 1,
                    minWidth: 250,
                  }
            }
          >
            <InputLabel id="demo-simple-select-label">
              Select Grid View
            </InputLabel>
            <Select
              value={gridView}
              onChange={handleGridViewChange}
              className="font-weight-bold"
            >
              <MenuItem value={"contacts"} key={"contacts"}>
                {"All Contacts"}
              </MenuItem>

              <MenuItem value={"contact-groups"} key={"contact-groups"}>
                {"Contact Groups"}
              </MenuItem>
            </Select>
          </FormControl>
        </span>
      </>
    );
  };

  const canAddEdit = roleMatch([
    UserRoles.Admin,
    UserRoles.SuperAdmin,
    UserRoles.EventsAdministrator,
    UserRoles.EventsEditor,
  ]);

  return (
    <>
      {gridView == "contacts" && !_.isEmpty(contactColumns) && (
        <LynxDataGrid
          disableRefetchOnMountOrArgChange
          columns={contactColumns}
          useQuery={useGetContactsQuery}
          localStorageName="contacts"
          handleEditClick={canAddEdit ? handleEditClick : undefined}
          entityType={EntityTypes.Contact}
          header={getContactListHeader}
          addButtonAction={
            canAddEdit
              ? () => {
                  history.push(`/add-contact`);
                }
              : undefined
          }
        />
      )}
      {gridView == "contact-groups" && !_.isEmpty(contactGroupColumns) && (
        <>
          <LynxDataGrid
            disableRefetchOnMountOrArgChange
            columns={contactGroupColumns}
            addButtonText="Add Contact Group"
            useQuery={useGetContactGroupsQuery}
            localStorageName="contactgroups"
            handleEditClick={(id: number) => {
              if (canAddEdit) {
                setSelectedId(id);
                setShowAddGroupModal(true);
              }
            }}
            addButtonAction={
              canAddEdit
                ? () => {
                    setShowAddGroupModal(true);
                  }
                : undefined
            }
            entityType={EntityTypes.ContactGroup}
            header={getContactListHeader}
          />
        </>
      )}
      {showAddGroupModal && (
        <ContactGroupModal
          open={showAddGroupModal}
          contactGroupId={selectedId}
          handleClose={() => {
            setShowAddGroupModal(false);
            setSelectedId(null);
          }}
          handleGroupFormSave={() => {
            setShowAddGroupModal(false);
            setSelectedId(null);
          }}
        />
      )}
      {selectedContactIdToView && (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          disableScrollLock
          onClose={() => {
            setAnchorEl(null);
            setSelectedContactIdToView(null);
          }}
        >
          <ContactQuickCard
            id={selectedContactIdToView.id}
            type={selectedContactIdToView.type}
          />
        </Popover>
      )}
    </>
  );
}
