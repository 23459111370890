
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { resetTours } from "../../../services/accounts";
export function About() {
  const handleCheckUpdate = () => {
    navigator.serviceWorker.getRegistration().then(function (reg) {
      if (reg) {
        reg.unregister().then(function () {
          window.location.reload(true);
        });
      } else {
        window.location.reload(true);
      }
    });
  };

  const handleResetTour = () => {
    resetTours().then(() => {
      window.location.reload(true);
    });
  };

  return (
    <div className="w-100 h-100 d-flex justify-content-center align-items-center">
      <Card sx={{ width: "50%" }}>
        <CardContent>
          <Typography variant="h5" color="text.secondary" gutterBottom>
            About
          </Typography>
          <Typography variant="h5" component="div">
            Version <b>{process.env.REACT_APP_VERSION}</b>
            <Button onClick={handleCheckUpdate} className="ml-2 ">
              Check for updates
            </Button>
          </Typography>
          <Typography variant="h5" component="div" className="mt-2">
            <Link to="/privacy">Privacy Policy</Link>
          </Typography>
          <Button onClick={handleResetTour} className="">
            Reset Tours
          </Button>
          <Typography
            className="align-self-end mt-2"
            variant="h5"
            component="div"
          >
            Copyright © 2024 Lynx Global Intelligence, Inc.
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
}
