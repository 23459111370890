import { apiUrl, client } from "../modules/store";

export function getLastSyncCacheInvalidated() {
  return client.get(`${apiUrl}/organizations/syncInvalidated`);
}

export function uploadLogo(form) {
  return client.post(apiUrl + "/organizations/logo/", form);
}

export function getLogo() {
  return client
    .get(apiUrl + `/organizations/logo`, {
      responseType: "blob",
    })
    .then((res) => res);
}

export function getOrgLogo(customerId) {
  return client
    .get(apiUrl + `/organizations/logo/${customerId}`, {
      responseType: "blob",
    })
    .then((res) => res);
}
