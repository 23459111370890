import { Close } from "@mui/icons-material";
import { AlertColor } from "@mui/material";
import { OptionsObject, SnackbarMessage, useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { clearAlerts } from "reducers/alerts";

const useAlert = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const showAlert = (
    variant: AlertColor,
    message: SnackbarMessage,
    option?: OptionsObject
  ) => {
    enqueueSnackbar(message, {
      ...option,
      anchorOrigin: { horizontal: "center", vertical: "top" },
      autoHideDuration: option?.autoHideDuration || 5000,
      variant: variant,
      preventDuplicate: true,
      action: (snackbarId: any) => (
        <Close
          sx={{ cursor: "pointer" }}
          onClick={() => closeSnackbar(snackbarId)}
        />
      ),
      onClose: () => {
        dispatch(clearAlerts());
      },
    });
  };

  return { showAlert };
};

export default useAlert;
