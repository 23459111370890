import queryString from "query-string";
import { BaseParams } from "types";
import { RtkTagTypes } from "types/enums";

const { apiService } = require("../apiService");

const BASE_ROUTE = "/kpis";

const kpisEndpoint = apiService.injectEndpoints({
  endpoints: (builder: any) => ({
    getKpis: builder.query({
      query: (params: BaseParams) =>
        `${BASE_ROUTE}?${queryString.stringify(params)}`,
      providesTags: [RtkTagTypes.GetKpis],
      transformResponse: (response: any, meta: any) => {
        const pagination = meta.response.headers.get("Pagination");
        return { data: response, pagination };
      },
    }),
    getKpiById: builder.query({
      query: (id: number) => `${BASE_ROUTE}/${id}`,
      providesTags: (result: any, error: any, id: any) => [
        { type: RtkTagTypes.GetKpi, id },
      ],
    }),
    getKpiDashboardDataById: builder.query({
      query: ({
        id,
        dateFilter,
        refreshData,
      }: {
        id: number;
        dateFilter: string;
        refreshData: boolean;
      }) =>
        `${BASE_ROUTE}/${id}/dashboard?${queryString.stringify({
          dateFilter,
          refreshData,
        })}`,
      providesTags: (result: any, error: any, { id }: any) => [
        { type: RtkTagTypes.GetKpiDashboard, id },
      ],
    }),
    getKpiHistoryById: builder.query({
      query: ({ id, ...params }: { id: number; params: any }) =>
        `${BASE_ROUTE}/${id}/history?${queryString.stringify(params)}`,
      providesTags: (result: any, error: any, { id }: any) => [
        { type: RtkTagTypes.GetKpiHistory, id },
      ],
      transformResponse: (response: any, meta: any) => {
        const pagination = meta.response.headers.get("Pagination");
        return { data: response, pagination };
      },
    }),
  }),
});

export const {
  useGetKpisQuery,
  useLazyGetKpisQuery,
  useGetKpiByIdQuery,
  useLazyGetKpiByIdQuery,
  useGetKpiDashboardDataByIdQuery,
  useLazyGetKpiDashboardDataByIdQuery,
  useGetKpiHistoryByIdQuery,
  useLazyGetKpiHistoryByIdQuery,
} = kpisEndpoint;
