import { useGetLookupsQuery } from "services/rtkApi/endpoints/lookups";
import { useGetParametersQuery } from "services/rtkApi/endpoints/parameters";
import { LookupTypes } from "./../../../../types/enums";

export const monitoringLimitColumns = [
  {
    field: "edit",
    headerName: "Edit / Delete",
    width: 100,
    type: "actions",
    sortable: false,
  },
  {
    field: "parameterName",
    headerName: "Parameter",
    width: 150,
    type: "singleSelect",
    query: useGetParametersQuery,
    getOptionValue: (value) => value.name,
    getOptionLabel: (value) => value.name,
    importable: true,
  },
  {
    field: "value",
    headerName: "Value",
    width: 50,
    type: "string",
    importable: true,
  },
  {
    field: "unitName",
    headerName: "Unit",
    width: 150,
    type: "singleSelect",
    lookupType: "unit",
    importable: true,
  },

  {
    field: "monitoringLimitType",
    headerName: "Type",
    width: 100,
    type: "singleSelect",
    query: useGetLookupsQuery,
    queryParams: { lookupType: LookupTypes.MonitoringLimitType },
    getOptionValue: (value) => value.code,
    getOptionLabel: (value) => value.code,
    importable: true,
  },
];
