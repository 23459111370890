import { apiUrl, client } from "../modules/store";

export function getReports(entityType) {
  return client.get(apiUrl + "/reports?entityType=" + entityType);
}

export function runIncidentReport(reportId, incidentId) {
  var url = `${apiUrl}/reports/${reportId}/incidents/${incidentId}`;

  return client.get(url, {
    responseType: "blob",
  });
}
export function runEventReport(reportId, eventId) {
  var url = `${apiUrl}/reports/${reportId}/events/${eventId}`;

  return client.get(url, {
    responseType: "blob",
  });
}

export function runIncidentsReport(reportId, params) {
  return client.get(apiUrl + `/reports/${reportId}/incidents/`, {
    params: params,
    responseType: "blob",
  });
}
export function runEventsReport(reportId, params) {
  return client.get(apiUrl + `/reports/${reportId}/events/`, {
    params: params,
    responseType: "blob",
  });
}
