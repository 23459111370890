import {
  GridToolbarExportContainer,
  GridPrintExportMenuItem,
  GridCsvExportMenuItem,
} from "@mui/x-data-grid-pro";
import MenuItem from "@mui/material/MenuItem";
import { EntityTypes } from "../../types/enums";
import _ from "lodash";

export function CustomExportButton(props) {
  return (
    <GridToolbarExportContainer>
      {props.handleExportAttachments && (
        <GridExportAttachmentsMenuItem
          handleExportAttachments={props.handleExportAttachments}
        />
      )}
      {props.handleExportAsXLSX ? (
        <GridExportAsXLSXMenuItem
          handleExportAsXLSX={props.handleExportAsXLSX}
        />
      ) : (
        <GridCsvExportMenuItem />
      )}

      <GridPrintExportMenuItem />
    </GridToolbarExportContainer>
  );
}

function GridExportAttachmentsMenuItem(props) {
  const { hideMenu } = props;

  return (
    <MenuItem
      onClick={() => {
        props.handleExportAttachments();
        hideMenu?.();
      }}
    >
      Export Attachments
    </MenuItem>
  );
}

function GridExportAsXLSXMenuItem(props) {
  const { hideMenu } = props;

  return (
    <MenuItem
      onClick={() => {
        props.handleExportAsXLSX();
        hideMenu?.();
      }}
    >
      Download as XLSX
    </MenuItem>
  );
}
