import queryString from "query-string";
import { apiService } from "../apiService";
import { RtkTagTypes } from "types/enums";
import {
  BaseParams,
  EntityContactDto,
  ContactDto,
  ContactGroupDto,
} from "types";

const BASE_ROUTE = "/contacts";

const contactsEndpoint = apiService.injectEndpoints({
  endpoints: (builder: any) => ({
    // Fetch contacts for a specific entity
    getContactsForEntity: builder.query({
      query: ({
        entityType,
        entityId,
      }: {
        entityType: string;
        entityId: number;
      }) =>
        `${BASE_ROUTE}/entity?entityType=${entityType}&entityId=${entityId}`,
      providesTags: [RtkTagTypes.GetContacts],
    }),

    // Delete an entity contact
    deleteEntityContact: builder.mutation({
      query: (entityContactId: number) => ({
        url: `${BASE_ROUTE}/entity/${entityContactId}`,
        method: "DELETE",
      }),
      invalidatesTags: [RtkTagTypes.GetContacts],
    }),

    // Fetch contacts with pagination
    getContacts: builder.query({
      query: (contactParams: BaseParams) =>
        `${BASE_ROUTE}?${queryString.stringify(contactParams)}`,
      providesTags: [RtkTagTypes.GetContacts],
      transformResponse: (response: any, meta: any) => {
        const pagination = meta.response.headers.get("Pagination");
        return { data: response, pagination };
      },
    }),

    // Create an entity contact
    createEntityContact: builder.mutation({
      query: (dto: EntityContactDto) => ({
        url: `${BASE_ROUTE}/entity`,
        method: "POST",
        body: dto,
      }),
      invalidatesTags: [RtkTagTypes.GetContacts],
    }),

    // Search contacts
    searchContacts: builder.query({
      query: ({
        query,
        includeUsers,
      }: {
        query: string;
        includeUsers: boolean;
      }) => `${BASE_ROUTE}/search?query=${query}&includeUsers=${includeUsers}`,
      providesTags: [RtkTagTypes.GetContacts],
    }),

    // Fetch a contact by ID
    getContactById: builder.query({
      query: (id: number) => `${BASE_ROUTE}/${id}`,
      providesTags: (result: any, error: any, id: any) => [
        { type: RtkTagTypes.GetContacts, id },
      ],
    }),

    // Fetch a contact group by ID
    getContactGroupById: builder.query({
      query: (id: number) => `${BASE_ROUTE}/groups/${id}`,
      providesTags: (result: any, error: any, id: any) => [
        { type: RtkTagTypes.GetContactGroups, id },
      ],
    }),

    // Create a contact
    createContact: builder.mutation({
      query: (dto: ContactDto) => ({
        url: BASE_ROUTE,
        method: "POST",
        body: dto,
      }),
      invalidatesTags: [RtkTagTypes.GetContacts],
    }),

    // Update a contact (patch)
    patchContact: builder.mutation({
      query: ({ id, dto }: { id: number; dto: ContactDto }) => ({
        url: `${BASE_ROUTE}/${id}`,
        method: "PATCH",
        body: dto,
      }),
      invalidatesTags: (result: any, error: any, { id }: any) => [
        { type: RtkTagTypes.GetContacts, id },
        RtkTagTypes.GetContacts,
      ],
    }),

    // Delete a contact
    deleteContact: builder.mutation({
      query: (id: number) => ({
        url: `${BASE_ROUTE}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [RtkTagTypes.GetContacts],
    }),

    // Fetch contact groups with pagination
    getContactGroups: builder.query({
      query: (contactParams: BaseParams) =>
        `${BASE_ROUTE}/groups?${queryString.stringify(contactParams)}`,
      providesTags: [RtkTagTypes.GetContactGroups],
      transformResponse: (response: any, meta: any) => {
        const pagination = meta.response.headers.get("Pagination");
        return { data: response, pagination };
      },
    }),

    // Delete a contact group
    deleteContactGroup: builder.mutation({
      query: (id: number) => ({
        url: `${BASE_ROUTE}/groups/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [RtkTagTypes.GetContactGroups],
    }),

    // Update a contact group (patch)
    patchContactGroup: builder.mutation({
      query: ({ id, dto }: { id: number; dto: ContactGroupDto }) => ({
        url: `${BASE_ROUTE}/groups/${id}`,
        method: "PATCH",
        body: dto,
      }),
      invalidatesTags: (result: any, error: any, { id }: any) => [
        { type: RtkTagTypes.GetContactGroups, id },
        RtkTagTypes.GetContactGroups,
      ],
    }),

    // Create a contact group
    createContactGroup: builder.mutation({
      query: (dto: ContactGroupDto) => ({
        url: `${BASE_ROUTE}/groups`,
        method: "POST",
        body: dto,
      }),
      invalidatesTags: [RtkTagTypes.GetContactGroups],
    }),

    // Merge contacts
    mergeContact: builder.mutation({
      query: (dto: any) => ({
        url: `${BASE_ROUTE}/merge`,
        method: "POST",
        body: dto,
      }),
      invalidatesTags: [RtkTagTypes.GetContacts],
    }),
  }),
});

export const {
  useDeleteEntityContactMutation,
  useLazySearchContactsQuery,
  useLazyGetContactsForEntityQuery,
  useLazyGetContactsQuery,
  useLazyGetContactByIdQuery,
  useGetContactGroupsQuery,
  usePatchContactGroupMutation,
  useDeleteContactGroupMutation,
  useMergeContactMutation,
  useGetContactsQuery,
  usePatchContactMutation,
  useDeleteContactMutation,
  useCreateContactMutation,
  useCreateContactGroupMutation,
  useCreateEntityContactMutation,
  useLazyGetContactGroupByIdQuery,
} = contactsEndpoint;
