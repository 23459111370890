import _, { create } from "lodash";
import { useEffect, useState } from "react";
import { Form, Grid } from "components/lynx-components";

import { validationService } from "./../../../services/validation";
import { LynxDialog } from "./../../lynx-dialog";
import { getLookups } from "./../../../services/lookup";
import { LookupTypes } from "./../../../types/enums";
import { LynxTextArea } from "components/form-controls/lynx-form-controls";
import useAlert from "hooks/useAlert";
import {
  useCreateContactGroupMutation,
  useDeleteContactGroupMutation,
  useLazyGetContactGroupByIdQuery,
  usePatchContactGroupMutation,
} from "services/rtkApi/endpoints/contacts";

const initialGroupForm = {
  name: "",
  nameError: "",
  description: "",
  contactGroupTypeId: "",
  notes: "",
};

export function ContactGroupModal(props) {
  const [groupFormState, setGroupFormState] = useState(initialGroupForm);
  const [contactGroupTypes, setContactGroupTypes] = useState([]);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const { showAlert } = useAlert();
  const [updateTrigger] = usePatchContactGroupMutation();
  const [deleteTrigger] = useDeleteContactGroupMutation();
  const [createTrigger] = useCreateContactGroupMutation();
  const [triggerGetContactGroup, { data: contactGroup }] =
    useLazyGetContactGroupByIdQuery();
  useEffect(() => {
    if (props.contactGroupId) {
      triggerGetContactGroup(props.contactGroupId).then((res) => {
        if (res.data) {
          setGroupFormState({ ...res.data });
        }
      });
    }
    getLookups(LookupTypes.ContactGroupType).then((res) => {
      setContactGroupTypes(res.data);
    });
  }, []);
  const handleGroupInputChange = (e) => {
    let newState = { ...groupFormState };
    const { name, value } = e.target;

    _.set(newState, name, value);

    setGroupFormState(newState);
  };
  const handleGroupFormSave = () => {
    if (!validateGroupDateForSave()) {
      return;
    }
    if (props.contactGroupId) {
      updateTrigger({ id: props.contactGroupId, dto: groupFormState }).then(
        (res) => {
          if (res.data) {
            showAlert("success", "Contact group updated.");
            props.handleGroupFormSave(res.data);
          }
        }
      );
    } else {
      createTrigger(groupFormState).then((res) => {
        if (res.data) {
          showAlert("success", "Contact group added.");
          setGroupFormState(initialGroupForm);
          props.handleGroupFormSave(res.data);
        }
      });
    }
  };
  const validateGroupDateForSave = () => {
    let newState = { ...groupFormState };
    let isFormValid = false;
    validationService.validateRequiredField(
      newState,
      "name",
      "nameError",
      "Name"
    );
    isFormValid = !validationService.hasError(newState, "nameError");
    if (!isFormValid) {
      setGroupFormState(newState);
      showAlert("error", "Form is not valid for saving.");
    }
    return isFormValid;
  };

  const handleContactGroupDelete = () => {
    setShowDeleteDialog(true);
  };
  const deleteGroup = () => {
    deleteTrigger(props.contactGroupId).then((res) => {
      if (res) {
        props.handleGroupFormSave();
      }
    });
  };
  return (
    <>
      <LynxDialog
        open={showDeleteDialog}
        handleClose={() => setShowDeleteDialog(false)}
        handleDelete={deleteGroup}
        title="Delete Contact Group?"
        description={
          contactGroup && contactGroup.numberOfAssociatedContacts > 0
            ? `This will also delete the ${contactGroup.numberOfAssociatedContacts} associated contact(s).`
            : "This action cannot be undone."
        }
      />{" "}
      <LynxDialog
        fullWidth
        dividers
        maxWidth={"md"}
        handleClose={() => {
          props.handleClose();
        }}
        isCloseInHeader
        handleSave={handleGroupFormSave}
        handleDelete={props.contactGroupId ? handleContactGroupDelete : null}
        open={props.open}
        title={
          props.contactGroupId ? "Edit Contact Group" : "Add Contact Group"
        }
        dialogContent={
          <Grid>
            <Grid.Row>
              <Grid.Col md={6} width={12}>
                <Form.Group label="Group Name" isRequired>
                  <Form.Input
                    type="text"
                    onChange={handleGroupInputChange}
                    name={`name`}
                    error={groupFormState.nameError}
                    value={groupFormState.name}
                  ></Form.Input>
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={6} width={12}>
                <Form.Group label="Group Type">
                  <Form.Select
                    onChange={handleGroupInputChange}
                    name={`contactGroupTypeId`}
                    value={groupFormState.contactGroupTypeId}
                  >
                    <option value={""}></option>
                    {contactGroupTypes.map((unit) => (
                      <option value={unit.id} key={unit.id}>
                        {unit.code}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={12} width={12}>
                <Form.Group label="Description">
                  <LynxTextArea
                    onChange={handleGroupInputChange}
                    name={`description`}
                    value={groupFormState.description}
                  ></LynxTextArea>
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={12} width={12}>
                <Form.Group label="Notes">
                  <LynxTextArea
                    onChange={handleGroupInputChange}
                    name={`notes`}
                    value={groupFormState.notes}
                  ></LynxTextArea>
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
          </Grid>
        }
      />{" "}
    </>
  );
}
