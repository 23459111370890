import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Container } from "@mui/material";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { DataGridPro } from "@mui/x-data-grid-pro";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { dateUtil } from "../../../services/date-util";
import { BadgeWidget } from "../../dashboards/dashboards/widgets/badge-widget";
import { WylooLineChart } from "./wyloo-line-chart";
import { wylooTheme } from "./wyloo-theme";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";
import { navigateTo } from "services/navigation-service";
import {
  getPortalStats,
  getMonitoringLocationForMicrositeMap,
} from "services/customer-portal-service";
export function WylooLocationDetails(props) {
  const [showResults, setShowResults] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [statsLoading, setStatsLoading] = useState(true);
  const [portalStats, setPortalStats] = useState({});
  const [selectedMonitoringLocation, setSelectedMonitoringLocation] = useState({
    monitoringLocation: {},
    monitoringEvents: [],
  });
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    getMonitoringLocationForMicrositeMap(
      props.match.params.id,
      location.pathname
    ).then((res) => {
      setSelectedMonitoringLocation(res.data);
    });
    getPortalStats([], location.pathname, props.match.params.id)
      .then((response) => {
        setPortalStats(response.data);
      })
      .finally(() => {
        setStatsLoading(false);
      });
  }, []);
  var monitoringLocation = selectedMonitoringLocation.monitoringLocation;

  const handleViewDetails = (row) => {
    setSelectedEvent(row);
    setShowResults(true);
  };

  const wylooResultColumns = [
    {
      field: "parameterName",
      headerName: "Parameter",
      width: 200,
      type: "string",
    },
    {
      field: "enteredValue",
      headerName: "Value",
      width: 100,
      type: "string",
    },

    {
      field: "unitName",
      headerName: "Unit",
      width: 150,
      type: "string",
    },
    {
      field: "enteredMethodDetectionLimit",
      headerName: "MDL",
      width: 50,
      type: "string",
    },
  ];
  const wylooLocationColumns = [
    {
      field: "a",
      headerName: "Details",
      width: 200,
      type: "string",
      renderCell: (params) => {
        return (
          <Button
            variant="outlined"
            onClick={() => handleViewDetails(params.row)}
          >
            View Results
          </Button>
        );
      },
    },
    {
      field: "name",
      headerName: "Sample ID",
      width: 150,
      type: "string",
    },
    {
      field: "endDateTimeUtc",
      headerName: "Sample Date",
      width: 150,
      type: "dateTime",

      valueGetter: (value) => {
        return dateUtil.convertColumnDateTimeToLocal(value);
      },
    },
    {
      field: "eventTypeName",
      headerName: "Type",
      width: 150,
      type: "string",
    },
    {
      field: "description",
      headerName: "Description",
      width: 200,
      type: "string",
    },
  ];
  return (
    <div className="wyloo-microsite-container wyloo-colors ">
      <ThemeProvider theme={wylooTheme}>
        <Container maxWidth="xl">
          <div className="wyloo-text-section mt-2 mb-2">
            <div style={{ width: 50, height: "100%" }} className="float-left">
              <IconButton
                onClick={(e) => navigateTo(history, "/wyloo/", e)}
                variant="outlined"
                className="mr-5"
                size="large"
              >
                <ArrowBackIcon />
              </IconButton>
            </div>
            <div>
              <Typography variant="h4" sx={{ width: "100%" }}>
                Monitoring Location: {portalStats.monitoringLocationType} -{" "}
                {portalStats.monitoringLocationDescription}
              </Typography>
              <Typography
                variant="h5"
                sx={{ width: "100%", marginLeft: "50px" }}
              >
                {portalStats.monitoringLocationName}
              </Typography>
            </div>
          </div>
          <div className="d-flex mb-2 ">
            <BadgeWidget
              title={"Total Sample Events"}
              value={portalStats.totalSampleEvents}
              subValue={`Between ${moment(portalStats.firstSampleDate).format(
                "MMM YYYY"
              )} and ${moment(portalStats.lastSampleDate).format("MMM YYYY")}`}
              className="mr-2 portal-badge"
              isLoading={statsLoading}
            />
            <BadgeWidget
              title={"Total Sample Results"}
              value={portalStats.totalResults}
              subValue={`Across ${portalStats.totalSampleEvents} Sample Events`}
              className=" portal-badge mr-2"
              isLoading={statsLoading}
            />
            <BadgeWidget
              title={"Unique Parameters Sampled"}
              value={portalStats.distinctParametersSampled}
              className="portal-badge"
              subValue={` `}
              isLoading={statsLoading}
            />
          </div>
          <div
            style={{
              height: "100%",
              width: "100%",
            }}
            className="wyloo-colors  mt-2"
          >
            <WylooLineChart
              monitoringLocation={monitoringLocation}
              events={selectedMonitoringLocation.monitoringEvents}
              isForDetails
            />
          </div>
          <div
            style={{
              height: "100%",
              width: "100%",
            }}
            className="wyloo-text-container "
          >
            {showResults ? (
              !_.isEmpty(selectedEvent) && (
                <div className="wyloo-text-section mt-0">
                  <div style={{ width: "50" }}>
                    {" "}
                    <IconButton
                      className="float-left"
                      onClick={() => {
                        setSelectedEvent({});
                        setShowResults(false);
                      }}
                    >
                      <ArrowBackIcon />
                    </IconButton>
                  </div>

                  <div style={{ marginLeft: "50px" }}>
                    <Typography variant="h4">
                      Sample ID: {selectedEvent.name}
                    </Typography>{" "}
                    <Typography className="">
                      {`${
                        selectedEvent.startDateTimeUtc
                          ? "Start Date: " +
                            dateUtil.convertDateTimeToLocal(
                              selectedEvent.startDateTimeUtc
                            )
                          : ""
                      } ${
                        selectedEvent.endDateTimeUtc
                          ? "Sample Date: " +
                            dateUtil.convertDateTimeToLocal(
                              selectedEvent.endDateTimeUtc
                            )
                          : ""
                      }`}
                    </Typography>
                  </div>
                </div>
              )
            ) : (
              <div className="wyloo-text-section mt-0">
                <Typography variant="h4">{"Monitoring Events"}</Typography>
              </div>
            )}
          </div>
          <div
            style={{
              height: "100%",
              width: "100%",
              marginBottom: "0.5rem",
            }}
            className="wyloo-text-section p-0"
          >
            <DataGridPro
              sx={{ borderRadius: "25px" }}
              autoHeight
              pagination
              rows={
                showResults && !_.isEmpty(selectedEvent)
                  ? selectedEvent.monitoringResults
                  : selectedMonitoringLocation.monitoringEvents
              }
              columns={showResults ? wylooResultColumns : wylooLocationColumns}
              disableMultipleRowSelection={true}
              disableRowSelectionOnClick
              disableChildrenSorting
              pageSizeOptions={[20]}
            />
          </div>
        </Container>
      </ThemeProvider>
    </div>
  );
}
