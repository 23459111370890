import { useState, useEffect } from "react";
import { Icon, Grid, Card, Form } from "components/lynx-components";
import Button from "@mui/material/Button";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import FormatListNumberedRtlIcon from "@mui/icons-material/FormatListNumberedRtl";
import DonutLargeIcon from "@mui/icons-material/DonutLarge";
import BarChartIcon from "@mui/icons-material/BarChart";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import "./add-widget-modal.css";
import lynxColors from "../../../modules/lynxColors";
import { getFilters } from "../../../services/filter-service";
import { CreateCountList } from "./create-count-list";
import AddchartIcon from "@mui/icons-material/Addchart";
import { CreateGroupChart } from "./create-group-chart";
import {
  getSystemWidgets,
  addSystemWidget,
} from "../../../services/dashboard-widget-service";
import _ from "lodash";
import { LineChartWidget } from "./widgets/line-chart-widget";
import { BarChartWidget } from "./widgets/bar-chart-widget";
import ViewTimelineIcon from "@mui/icons-material/ViewTimeline";
import MuiGrid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { getMonitoringLocations } from "../../../services/monitoring-location-service";
import { getParameters } from "../../../services/parameters";
import {
  hasAnyEventRole,
  hasAnyMonitoringRole
} from "../../../actions/auth";
import useAlert from "hooks/useAlert";
import { WidgetTypes } from "types/enums-ts";
export function AddWidgetModal(props) {
  const [widgetType, setWidgetType] = useState("");
  const [workflow, setWorkflow] = useState("");
  const [filters, setFilters] = useState([]);
  const [systemWidgets, setSystemWidget] = useState([]);
  const [selectedSystemWidget, setSelectedSystemWidget] = useState({});
  const [monitoringLocations, setMonitoringLocations] = useState([]);
  const [parameters, setParameters] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedParameters, setSelectedParameters] = useState([]);
  const [systemWidgetTitle, setSystemWidgetTitle] = useState("");
  const [selectedValues, setSelectedValues] = useState([]);
  const [autoCompleteLocationValue, setAutoCompleteLocationValue] =
    useState(null);
  const [autoCompleteLocationInputValue, setAutoCompleteLocationInputValue] =
    useState("");
  const [autoCompleteParameterValue, setAutoCompleteParameterValue] =
    useState(null);
  const [autoCompleteParameterInputValue, setAutoCompleteParameterInputValue] =
    useState("");
  const [autoCompleteValueValue, setAutoCompleteValueValue] = useState(null);
  const [autoCompleteValueInputValue, setAutoCompleteValueInputValue] =
    useState("");

  const { showAlert } = useAlert();

  useEffect(() => {
    getFilters("")
      .then((res) => {
        setFilters(res.data);
      })
      .catch((err) => {
        showAlert("error", err.response.data.message);
      });
    getSystemWidgets()
      .then((res) => {
        setSystemWidget(res.data);
      })
      .catch((err) => {
        showAlert("error", err.response.data.message);
      });
    if (hasAnyMonitoringRole()) {
      getMonitoringLocations()
        .then((res) => {
          setMonitoringLocations(res.data);
        })
        .catch((err) => {
          showAlert("error", err.response.data.message);
        });
      getParameters()
        .then((res) => {
          setParameters(res.data);
        })
        .catch((err) => {
          showAlert("error", err.response.data.message);
        });
    }
  }, []);

  const saveAddSystemWidget = () => {
    let properties = {};
    if (
      !_.isEmpty(selectedLocations) &&
      !_.isEmpty(selectedParameters) &&
      !_.isEmpty(selectedValues)
    ) {
      properties = {
        parameters: selectedParameters.map((x) => x.id).join("|"),
        monitoringLocations: selectedLocations.map((x) => x.id).join("|"),
        resultValues: selectedValues.join("|"),
      };
    }

    const widgetToCreate = {
      dashboardSystemWidgetId: selectedSystemWidget.id,
      title: systemWidgetTitle,
      propertiesJson: JSON.stringify(properties),
    };
    addSystemWidget(widgetToCreate)
      .then((res) => {
        showAlert("success", "Widget added.");
        props.handleModalClose(true);
      })
      .catch((err) => {
        showAlert("error", err.response.data.message);
      });
  };

  const getIcon = (widget) => {
    switch (_.toLower(widget.type)) {
      case WidgetTypes.TimeLine.toLowerCase():
        return <ViewTimelineIcon fontSize="large" />;
      case WidgetTypes.LineChart.toLowerCase():
        return <ShowChartIcon fontSize="large" />;
      default:
        return <BarChartIcon fontSize="large" />;
    }
  };

  const handleLocationSelected = (e, value) => {
    if (value) {
      setAutoCompleteLocationValue(null);
      let existingLocations = _.cloneDeep(selectedLocations);
      let existingLocation = existingLocations.find((x) => x.id == value.id);
      if (!existingLocation) {
        setSelectedLocations([
          ...existingLocations,
          { ...value, type: "manual" },
        ]);
      }
    }
  };

  const autoCompleteLocationInputChange = (e, newValue, reason) => {
    if (reason === "reset") {
      setAutoCompleteLocationValue(null);
      setAutoCompleteLocationInputValue("");
      return;
    } else {
      setAutoCompleteLocationInputValue(newValue);
    }
  };

  const handleParameterSelected = (e, value) => {
    if (value) {
      setAutoCompleteParameterValue(null);
      let existingParameters = _.cloneDeep(selectedParameters);
      let existingParameter = existingParameters.find((x) => x.id == value.id);
      if (!existingParameter) {
        setSelectedParameters([
          ...existingParameters,
          { ...value, type: "manual" },
        ]);
      }
    }
  };

  const autoCompleteParameterInputChange = (e, newValue, reason) => {
    if (reason === "reset") {
      setAutoCompleteParameterValue(null);
      setAutoCompleteParameterInputValue("");
      return;
    } else {
      setAutoCompleteParameterInputValue(newValue);
    }
  };

  const handleValueSelected = (e, value) => {
    if (value) {
      setAutoCompleteValueValue(null);
      let existingValues = _.cloneDeep(selectedValues);
      let existingValue = existingValues.find((x) => x == value);

      if (!existingValue) {
        setSelectedValues([...existingValues, value]);
      }
    }
  };

  const autoCompleteValueInputChange = (e, newValue, reason) => {
    if (reason === "reset") {
      setAutoCompleteValueValue(null);
      setAutoCompleteValueInputValue("");
      return;
    } else {
      setAutoCompleteValueInputValue(newValue);
    }
  };

  const handleLocationDeleted = (location) => {
    let newLocations = _.cloneDeep(selectedLocations);
    let index = newLocations.findIndex((x) => x.id == location.id);
    newLocations.splice(index, 1);
    setSelectedLocations(newLocations);
  };

  const handleParameterDeleted = (param) => {
    let newParams = _.cloneDeep(selectedParameters);
    let index = newParams.findIndex((x) => x.id == param.id);
    newParams.splice(index, 1);
    setSelectedParameters(newParams);
  };

  const handleValueDeleted = (value) => {
    let newValues = _.cloneDeep(selectedValues);
    let index = newValues.findIndex((x) => x.id == value);
    newValues.splice(index, 1);
    setSelectedValues(newValues);
  };

  const valuesToSelect = ["Maximum", "Minimum", "Average", "Limit"];
  return (
    <Form className="card dsmodal-main lynx-modal">
      <Card.Body>
        <Card.Title>
          Add Widget
          <Icon
            name="x"
            onClick={() => props.handleModalClose(false)}
            className="float-right pointer"
          ></Icon>
        </Card.Title>

        <Grid.Row>
          <Grid.Col md={12} width={12} className="mb-2">
            <div className="form-groupd">
              <label className="form-label">
                What type of widget would you like to add?
              </label>
            </div>
            <Button
              size="large"
              variant="outlined"
              className="mr-2"
              sx={{
                borderColor: workflow == "new" ? lynxColors.harvestOrange : "",
              }}
              onClick={() => setWorkflow("new")}
              startIcon={<AddchartIcon fontSize="large" />}
            >
              Create New Widget
            </Button>

            <Button
              size="large"
              variant="outlined"
              sx={{
                borderColor:
                  workflow == "system" ? lynxColors.harvestOrange : "",
              }}
              onClick={() => setWorkflow("system")}
              startIcon={<QueryStatsIcon fontSize="large" />}
            >
              Choose System Widget
            </Button>
          </Grid.Col>
          {workflow == "system" && (
            <>
              <Grid.Col md={12} width={12} className="mb-2">
                <div className="form-groupd">
                  <label className="form-label">Choose widget</label>
                </div>

                {_.orderBy(
                  systemWidgets.filter((x) => x.entityType == null),
                  "name"
                ).map((widget) => (
                  <Button
                    size="large"
                    variant="outlined"
                    className="mr-2 mb-2"
                    startIcon={getIcon(widget)}
                    key={widget.id}
                    onClick={() => {
                      setSelectedSystemWidget(widget);
                      setSystemWidgetTitle(widget.name);
                    }}
                    sx={{
                      borderColor:
                        selectedSystemWidget &&
                        selectedSystemWidget.id == widget.id
                          ? lynxColors.harvestOrange
                          : "",
                    }}
                  >
                    {widget.name}
                  </Button>
                ))}
              </Grid.Col>
              {hasAnyEventRole() && (
                <Grid.Col md={12} width={12} className="mb-2">
                  <div className="mb-2">Events</div>
                  {_.orderBy(
                    systemWidgets.filter(
                      (x) => _.toLower(x.entityType) == "event"
                    ),
                    "name"
                  ).map((widget) => (
                    <Button
                      size="large"
                      variant="outlined"
                      className="mr-2 mb-2"
                      startIcon={getIcon(widget)}
                      key={widget.id}
                      onClick={() => {
                        setSelectedSystemWidget(widget);
                        setSystemWidgetTitle(widget.name);
                      }}
                      sx={{
                        borderColor:
                          selectedSystemWidget &&
                          selectedSystemWidget.id == widget.id
                            ? lynxColors.harvestOrange
                            : "",
                      }}
                    >
                      {widget.name}
                    </Button>
                  ))}
                </Grid.Col>
              )}

              <>
                {hasAnyMonitoringRole() && (
                  <Grid.Col md={12} width={12} className="mb-2">
                    <div className="mb-2">Monitoring</div>
                    {_.orderBy(
                      systemWidgets.filter(
                        (x) => _.toLower(x.entityType) == "monitoringresult"
                      ),
                      "name"
                    ).map((widget) => (
                      <Button
                        size="large"
                        variant="outlined"
                        className="mr-2 mb-2"
                        startIcon={getIcon(widget)}
                        key={widget.id}
                        onClick={() => {
                          setSelectedSystemWidget(widget);
                          setSystemWidgetTitle(widget.name);
                        }}
                        sx={{
                          borderColor:
                            selectedSystemWidget &&
                            selectedSystemWidget.id == widget.id
                              ? lynxColors.harvestOrange
                              : "",
                        }}
                      >
                        {widget.name}
                      </Button>
                    ))}
                  </Grid.Col>
                )}

                {!_.isEmpty(selectedSystemWidget) && (
                  <>
                    <Grid.Col md={12} width={12}>
                      <div className="mb-2">Options</div>
                    </Grid.Col>
                    <Grid.Col md={12} width={12}>
                      <Form.Group isRequired label="Title" id="title">
                        <Form.Input
                          name="title"
                          onChange={(e) => setSystemWidgetTitle(e.target.value)}
                          value={systemWidgetTitle}
                        ></Form.Input>
                      </Form.Group>
                    </Grid.Col>
                  </>
                )}
                {_.toLower(selectedSystemWidget.entityType) ==
                  "monitoringresult" && (
                  <>
                    <Grid.Col md={4} width={4} className="m-0">
                      <>
                        <Grid.Col md={12} width={12} className="mb-2 mt-1">
                          {/* Add "search" to ID to disabled autofill / lastpass */}
                          <Autocomplete
                            id="location-grouped-search"
                            options={_.sortBy(
                              monitoringLocations.filter(
                                (x) =>
                                  !selectedLocations
                                    .map((y) => y.id)
                                    .includes(x.id)
                              ),
                              [(location) => location.name.toLowerCase()]
                            )}
                            getOptionLabel={(option) => option.name}
                            sx={{ width: 250 }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                label="Select locations"
                              />
                            )}
                            onChange={handleLocationSelected}
                            value={autoCompleteLocationValue}
                            inputValue={autoCompleteLocationInputValue}
                            onInputChange={autoCompleteLocationInputChange}
                          />
                        </Grid.Col>
                        <Grid.Col md={12} width={12} className="mb-2">
                          <MuiGrid container spacing={1}>
                            {_.sortBy(selectedLocations, [
                              (location) => location.name.toLowerCase(),
                            ]).map((location) => (
                              <MuiGrid item key={location.id}>
                                <Chip
                                  label={`${location.name}`}
                                  variant="outlined"
                                  onDelete={() =>
                                    handleLocationDeleted(location)
                                  }
                                />
                              </MuiGrid>
                            ))}
                          </MuiGrid>
                        </Grid.Col>
                      </>
                    </Grid.Col>
                    <Grid.Col md={4} width={4}>
                      <>
                        <Grid.Col md={12} width={12} className="mb-2 mt-1">
                          {/* Add "search" to ID to disabled autofill / lastpass */}
                          <Autocomplete
                            id="parameter-grouped-search"
                            options={_.sortBy(
                              parameters.filter(
                                (x) =>
                                  !selectedParameters
                                    .map((y) => y.id)
                                    .includes(x.id)
                              ),
                              [(parameter) => parameter.name.toLowerCase()]
                            )}
                            getOptionLabel={(option) => option.name}
                            sx={{ width: 250 }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                label="Select parameters"
                              />
                            )}
                            onChange={handleParameterSelected}
                            value={autoCompleteParameterValue}
                            inputValue={autoCompleteParameterInputValue}
                            onInputChange={autoCompleteParameterInputChange}
                          />
                        </Grid.Col>
                        <Grid.Col md={12} width={12} className="mb-2">
                          <MuiGrid container spacing={1}>
                            {_.sortBy(selectedParameters, [
                              (parameter) => parameter.name.toLowerCase(),
                            ]).map((parameter) => (
                              <MuiGrid item key={parameter.id}>
                                <Chip
                                  label={`${parameter.name}`}
                                  variant="outlined"
                                  onDelete={() =>
                                    handleParameterDeleted(parameter)
                                  }
                                />
                              </MuiGrid>
                            ))}
                          </MuiGrid>
                        </Grid.Col>
                      </>
                    </Grid.Col>
                    <Grid.Col md={4} width={4}>
                      <>
                        <Grid.Col md={12} width={12} className="mb-2 mt-1">
                          {/* Add "search" to ID to disabled autofill / lastpass */}
                          <Autocomplete
                            id="values-grouped-search"
                            options={valuesToSelect.filter(
                              (x) => !selectedValues.includes(x)
                            )}
                            getOptionLabel={(option) => option}
                            sx={{ width: 250 }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                label="Select values"
                              />
                            )}
                            onChange={handleValueSelected}
                            value={autoCompleteValueValue}
                            inputValue={autoCompleteValueInputValue}
                            onInputChange={autoCompleteValueInputChange}
                          />
                        </Grid.Col>
                        <Grid.Col md={12} width={12} className="mb-2">
                          <MuiGrid container spacing={1}>
                            {_.sortBy(selectedValues).map((value) => (
                              <MuiGrid item key={value}>
                                <Chip
                                  label={value}
                                  variant="outlined"
                                  onDelete={() => handleValueDeleted(value)}
                                />
                              </MuiGrid>
                            ))}
                          </MuiGrid>
                        </Grid.Col>
                      </>
                    </Grid.Col>
                  </>
                )}
              </>

              {!_.isEmpty(selectedSystemWidget) && (
                <>
                  <Grid.Col md={12} width={12} className="mb-2">
                    {_.toLower(selectedSystemWidget.type) == WidgetTypes.LineChart.toLowerCase() && (
                      <LineChartWidget
                        systemWidget={selectedSystemWidget}
                        parameters={selectedParameters}
                        monitoringLocations={selectedLocations}
                        resultValues={selectedValues}
                        systemWidgetTitle={systemWidgetTitle}
                      />
                    )}
                    {_.toLower(selectedSystemWidget.type) == WidgetTypes.BarChart.toLowerCase() && (
                      <BarChartWidget systemWidget={selectedSystemWidget} />
                    )}
                  </Grid.Col>
                  <Grid.Col md={12} width={12}>
                    <Button
                      variant="contained"
                      className="float-right"
                      onClick={saveAddSystemWidget}
                    >
                      Add Widget
                    </Button>
                  </Grid.Col>
                </>
              )}
            </>
          )}
          {workflow == "new" && (
            <>
              <Grid.Col md={12} width={12} className="mb-2">
                <div className="form-groupd">
                  <label className="form-label">Choose widget type</label>
                </div>

                <Button
                  size="large"
                  variant="outlined"
                  onClick={() => setWidgetType(WidgetTypes.CountList)}
                  sx={{
                    borderColor:
                      widgetType == WidgetTypes.CountList ? lynxColors.harvestOrange : "",
                  }}
                  className="mr-2"
                  startIcon={<FormatListNumberedRtlIcon fontSize="large" />}
                >
                  Count List
                </Button>
                <Button
                  size="large"
                  variant="outlined"
                  onClick={() => setWidgetType(WidgetTypes.DonutChart)}
                  sx={{
                    borderColor:
                      widgetType == WidgetTypes.DonutChart
                        ? lynxColors.harvestOrange
                        : "",
                  }}
                  className="mr-2"
                  startIcon={<DonutLargeIcon fontSize="large" />}
                >
                  Donut Chart
                </Button>
                <Button
                  size="large"
                  variant="outlined"
                  onClick={() => setWidgetType(WidgetTypes.BarChart)}
                  sx={{
                    borderColor:
                      widgetType == WidgetTypes.BarChart ? lynxColors.harvestOrange : "",
                  }}
                  startIcon={<BarChartIcon fontSize="large" />}
                >
                  Bar Chart
                </Button>
              </Grid.Col>{" "}
              {widgetType == WidgetTypes.CountList && (
                <CreateCountList
                  filters={filters}
                  handleModalClose={props.handleModalClose}
                />
              )}
              {(widgetType == WidgetTypes.DonutChart || widgetType == WidgetTypes.BarChart) && (
                <CreateGroupChart
                  filters={filters}
                  handleModalClose={props.handleModalClose}
                  widgetType={widgetType}
                />
              )}
            </>
          )}
        </Grid.Row>
      </Card.Body>
    </Form>
  );
}
