import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { LocalStorageKeys, RtkTagTypes } from "types/enums";

export const mainUrl =
  process.env.REACT_APP_API_URL ?? "https://demo-api.lynx-esg.com/";

const baseUrl = `${mainUrl}api`;

export const apiService = createApi({
  reducerPath: "API",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: async (headers) => {
      const token = localStorage.getItem(LocalStorageKeys.AccessToken);
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
    },
  }),
  endpoints: () => ({}),
  keepUnusedDataFor: 120,
  tagTypes: [...Object.values(RtkTagTypes)],
});
