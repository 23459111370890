import React, { useState } from "react";
import { Page } from "components/lynx-components";
import ResetPassword from "./reset-password";
import { validatePasswordReset } from "../../services/login";
import { LynxDialog } from "./../lynx-dialog";
import { logoutUser } from "../../actions/auth";
import { useHistory, useLocation, useParams } from "react-router-dom";
import queryString from "query-string";

export function ResetForgotPassword() {
  const [email, setEmail] = useState("");
  const [showDialog, setShowDialog] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const params = useParams();
  React.useEffect(() => {
    //Clear login token
    logoutUser();

    validatePasswordReset(params.guid)
      .then((res) => {
        setEmail(res.data.email);
      })
      .catch((err) => {
        setShowDialog(true);
      });
  }, []);

  const handleBack = () => {
    const qs = queryString.parse(location.search);
    if (qs.redirect) {
      history.push(qs.redirect);
    } else {
      history.push("/");
    }
  };

  return (
    <div className="content-wrapper">
      <Page>
        <Page.Main>
          <Page.Content>
            <ResetPassword
              handleBack={handleBack}
              passwordResetGuid={params.guid}
              email={email}
              apiUrl="/auth/password"
            ></ResetPassword>
          </Page.Content>
        </Page.Main>
      </Page>
      <LynxDialog
        open={showDialog}
        handleConfirm={handleBack}
        title={`Password Reset Error`}
        description={`This password reset link has expired. Please try resetting your password again.`}
      />
    </div>
  );
}
