import LockIcon from "@mui/icons-material/Lock";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { FC } from "react";
import { dateUtil } from "../../../services/date-util";
import { useSelector } from "react-redux";
import { roleMatch } from "actions/auth";
import { UserRoles } from "types/enums";
import { EventDto, RootState } from "types";
import { tagParseToChip } from "services/helpers/tagParser";

const na = "-";

interface EventDetailsProps {
  eventLocked: boolean;
  handleEdit: (section: string) => void;
  event: EventDto;
}

const EventDetails: FC<EventDetailsProps> = ({
  eventLocked,
  handleEdit,
  event,
}) => {
  const organization = useSelector((state: RootState) => state.organization);
  const account = useSelector((state: RootState) => state.account);

  const userRoleCanSave = roleMatch([
    UserRoles.EventsAdministrator,
    UserRoles.EventsEditor,
    UserRoles.EventsContributor,
  ]);

  return (
    <div className="w-100 d-flex justify-content-center">
      <Card variant="outlined" sx={{ width: "100%" }} className="lynx-card">
        <CardHeader
          className="lynx-card-header"
          title="Event Details"
          action={
            userRoleCanSave &&
            (eventLocked ? (
              <LockIcon
                className="mt-3 mr-3"
                color="disabled"
                fontSize="small"
              />
            ) : (
              <Button
                onClick={() => handleEdit("details")}
                disabled={eventLocked}
                id="editEventDetailsBtn"
              >
                Edit
              </Button>
            ))
          }
        />
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={organization.hasPermitFeature ? 6 : 12}>
              <>
                <div className="details-label-text">Associated Assets</div>
                <div className="details-value-text">{event.assetNames}</div>
              </>
            </Grid>
            {organization.hasPermitFeature && (
              <Grid item xs={12} sm={6}>
                <div className="details-label-text">Associated Permits</div>
                <div className="details-value-text">
                  {event.permitNames || na}
                </div>
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <div className="details-label-text">Event Category</div>
              <div className="details-value-text">{event.eventCategory}</div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="details-label-text">Event Subcategory</div>
              <div className="details-value-text">
                {event.eventSubCategory || na}
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="details-label-text">Due Date</div>
              <div className="details-value-text">
                {dateUtil.convertDateOnlyToLocal(event.eventDueDateTime) || na}
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="details-label-text">Completed Date</div>
              <div className="details-value-text">
                {(organization.hasDateOnlyEventDate
                  ? dateUtil.convertDateTimeToLocalDateOnly(event.eventDateTime)
                  : dateUtil.convertDateTimeToLocal(event.eventDateTime)) || na}
              </div>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ overflowWrap: "break-word" }}>
              <div className="details-label-text">Assigned To</div>
              <div className="details-value-text">
                {event.assignedToUserFullNames || na}
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="details-label-text">Urgency</div>
              <div className="details-value-text">{event.urgency || na}</div>
            </Grid>
            <Grid item xs={12} sm={12}>
              <div className="details-label-text">Description</div>
              <div className="details-value-text">
                {event.description ? tagParseToChip(event.description) : na}
              </div>
            </Grid>
            {account.hasEventConfidentialAccess && (
              <Grid item xs={12} sm={12}>
                <div className="details-label-text">Confidential?</div>
                <div className="details-value-text">
                  {event.isConfidential ? "Yes" : "No"}
                </div>
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <div className="details-label-text">Follow up required?</div>
              <div className="details-value-text">
                {event.followUpRequired ? "Yes" : "No"}
              </div>
            </Grid>
            {event.followUpRequired && (
              <>
                <Grid item xs={12} sm={6}>
                  <div className="details-label-text">Follow Up Date</div>
                  <div className="details-value-text">
                    {dateUtil.convertDateOnlyToLocal(event.followUpDate) || na}
                  </div>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <div className="details-label-text">
                    Follow Up Description
                  </div>
                  <div className="details-value-text">
                    {event.followUpDescription || na}
                  </div>
                </Grid>
              </>
            )}
          </Grid>
          <Divider className="mt-2 mb-2" />
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <div className="details-label-text">Created Date</div>
              <div className="details-value-text">
                {dateUtil.convertDateTimeToLocal(event.createdDateTimeUtc) ||
                  na}
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="details-label-text">Created By</div>
              <div className="details-value-text">
                {event.createdByFullName || na}
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="details-label-text">Last Modified Date</div>
              <div className="details-value-text">
                {dateUtil.convertDateTimeToLocal(
                  event.lastModifiedDateTimeUtc
                ) || na}
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="details-label-text">Last Modified By</div>
              <div className="details-value-text">
                {event.lastModifiedByFullName || na}
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default EventDetails;
