import * as React from "react";
import { Icon } from "components/lynx-components";
import { roleMatch } from "../../../../actions/auth";
import { UserRoles } from "./../../../../types/enums";

export const EventSubCategoryColumns = [
  {
    field: "parentLookupCode",
    headerName: "Category",
    width: 150,
    type: "string",
  },
  {
    field: "code",

    headerName: "Subcategory",
    width: 150,
    type: "string",
  },

  {
    field: "description",
    headerName: "Description",
    width: 250,
    type: "string",
  },

  {
    field: "delete",
    headerName: "",
    width: 45,
    type: "",

    cellClassName: "icon-settings",
    renderCell: (params) => (
      <div>
        {((roleMatch([UserRoles.Admin, UserRoles.EventsAdministrator]) &&
          !params.row.isInUse) ||
          roleMatch([UserRoles.InternalUser])) && (
          <Icon className="display-5 mb-3" name="trash" />
        )}
      </div>
    ),
  },

  {
    field: "edit",
    headerName: "",
    width: 45,
    type: "",
    cellClassName: "icon-settings",
    renderCell: (params) => (
      <div>
        <Icon className="display-5 mb-3" name="edit" />
      </div>
    ),
  },
];
