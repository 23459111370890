import queryString from "query-string";
import { RtkTagTypes } from "types/enums";
const { apiService } = require("../apiService");

const BASE_ROUTE = "/events";

const eventsEndpoint = apiService.injectEndpoints({
  endpoints: (builder) => ({
    getNumberOfEvents: builder.mutation({
      query: (args) => ({
        url: `${BASE_ROUTE}/schedule/count`,
        method: "POST",
        body: args,
      }),
    }),
    addEventSchedule: builder.mutation({
      query: (args) => ({
        url: `${BASE_ROUTE}/schedule`,
        method: "POST",
        body: args,
      }),
      invalidatesTags: [RtkTagTypes.GetEvents],
    }),
    getEvents: builder.query({
      query: (params) => `${BASE_ROUTE}?${queryString.stringify(params)}`,
      transformResponse: (response, meta) => {
        const pagination = meta.response.headers.get("Pagination"); // Access headers
        return { data: response, pagination }; // Include headers in the return object
      },
      providesTags: [RtkTagTypes.GetEvents],
    }),
    getEventsForMap: builder.query({
      query: (params) => `${BASE_ROUTE}/map?${queryString.stringify(params)}`,
      providesTags: [RtkTagTypes.GetEventsForMap],
    }),
    getEvent: builder.query({
      query: (id) => `${BASE_ROUTE}/${id}`,
      providesTags: (result, error, id) => [
        { type: RtkTagTypes.GetEvents, id: id },
      ],
    }),
    searchEvents: builder.query({
      query: (search) => `${BASE_ROUTE}/search?search=${search}`,
      providesTags: [RtkTagTypes.SearchEvents],
    }),
    getEventCountByFilterId: builder.query({
      query: (filterId) => `${BASE_ROUTE}/count/${filterId}`,
    }),
    getEventsAttachments: builder.query({
      query: (params) => ({
        url: `${BASE_ROUTE}/attachments`,
        params,
        responseType: "blob",
      }),
    }),
    getEventsExportAsXLSX: builder.mutation({
      query: ({ params, columnDefinitionDto }) => ({
        url: `${BASE_ROUTE}/export`,
        method: "POST",
        body: columnDefinitionDto,
        params,
        responseType: "blob",
      }),
    }),
    createEvent: builder.mutation({
      query: (dto) => ({
        url: BASE_ROUTE,
        method: "POST",
        body: dto,
      }),
      invalidatesTags: [RtkTagTypes.GetEvents],
    }),
    mergeEventCorrespondence: builder.mutation({
      query: ({ fromId, toId }) => ({
        url: `${BASE_ROUTE}/mergeCorrespondence?fromId=${fromId}&toId=${toId}`,
        method: "POST",
      }),
      invalidatesTags: (result, error, { fromId, toId }) => [
        RtkTagTypes.GetEvents,
        { type: RtkTagTypes.GetEvents, id: fromId },
        { type: RtkTagTypes.GetEvents, id: toId },
      ],
    }),
    createEventWithAttachments: builder.mutation({
      query: (form) => ({
        url: `${BASE_ROUTE}/attachments/`,
        method: "POST",
        body: form,
      }),
      invalidatesTags: [RtkTagTypes.GetEvents],
    }),
    deleteEvent: builder.mutation({
      query: (id) => ({
        url: `${BASE_ROUTE}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [RtkTagTypes.GetEvents],
    }),
    patchEvent: builder.mutation({
      query: ({ id, jPatch }) => ({
        url: `${BASE_ROUTE}/${id}`,
        method: "PATCH",
        body: jPatch,
      }),
      invalidatesTags: (result, error, { id }) => [
        RtkTagTypes.GetEvents,
        { type: RtkTagTypes.GetEvents, id },
      ],
    }),
    bulkUpdateEvents: builder.mutation({
      query: ({ params, data }) => ({
        url: `${BASE_ROUTE}/bulkUpdate?${queryString.stringify(params)}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [RtkTagTypes.GetEvents],
    }),
    bulkUpdateEventAttachments: builder.mutation({
      query: ({ params, attachments }) => {
        const formData = new FormData();
        attachments.forEach((file) => formData.append("file", file));
        return {
          url: `${BASE_ROUTE}/bulkUpdateAttachments`,
          method: "POST",
          body: formData,
          params,
        };
      },
      invalidatesTags: [RtkTagTypes.GetEvents],
    }),
  }),
});

export const {
  useBulkUpdateEventsMutation,
  useGetNumberOfEventsMutation,
  useAddEventScheduleMutation,
  useGetEventsQuery,
} = eventsEndpoint;
