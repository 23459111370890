import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";

import { LynxDataGrid } from "../data-grid/lynx-data-grid";
import { submissionColumns } from "./submission-columns";
import { getSubmissions } from "../../services/portal-submission-service";
import { LynxGridColDef } from "types";
import { useHistory } from "react-router-dom";
import { useGetSubmissionsQuery } from "services/rtkApi/endpoints/portalSubmissions";
import { EntityTypes } from "types/enums-ts";

export function SubmissionsList() {
  const history = useHistory();
  const [columns, setColumns] = useState<LynxGridColDef[]>([]);
  const handleSubmissionNumberClick = (row: any) => {
    history.push(`/portal-submissions/${row.id}`);
  };

  useEffect(() => {
    let newColumns = [...submissionColumns];
    let colIndex = newColumns.findIndex((x) => x.field == "submissionNumber");
    newColumns[colIndex].renderCell = (params: any) => {
      return (
        <Button
          variant="text"
          onClick={() => handleSubmissionNumberClick(params.row)}
        >
          {params.value}
        </Button>
      );
    };
    setColumns(newColumns);
  }, [submissionColumns]);

  return (
    <>
      <LynxDataGrid
        columns={columns}
        useQuery={useGetSubmissionsQuery}
        localStorageName="portal-submission"
        entityType={EntityTypes.PortalSubmission}
        title={"Portal Submissions"}
      />
    </>
  );
}
