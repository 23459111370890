import { apiUrl, client } from "../modules/store";
import _ from "lodash";

export function getActions(params) {
  return client.get(apiUrl + "/actions", {
    params: params,
  });
}

export function getAction(id) {
  return client.get(apiUrl + "/actions/" + id);
}
export function getActionCountByFilterId(filterId) {
  let url = apiUrl + "/actions/count/" + filterId;

  return client.get(url);
}
export function createAction(dto, files) {
  let form = new FormData();
  var attachmentsToUpload = [];
  if (!_.isEmpty(files)) {
    for (var index = 0; index < files.length; index++) {
      let file = files[index];
      attachmentsToUpload.push({
        name: file.name,
        blob: file.slice(0, file.size, file.type),
      });
      var element = files[index];
      form.append("file", element);
    }
  }
  let dataString = JSON.stringify(dto);

  form.append("data", dataString);

  return client.post(apiUrl + "/actions", form);
}

export function deleteAction(id) {
  return client.delete(apiUrl + "/actions/" + id);
}

export function patchAction(id, jPatch, saveAction) {
  var url = `${apiUrl}/actions/${id}`;
  if (saveAction) {
    url = `${url}?saveAction=${saveAction}`;
  }
  return client.patch(url, jPatch);
}

export function getActionsExportAsXLSX(params, columnDefinitionDto) {
  var url = `${apiUrl}/actions/export`;

  return client
    .post(url, columnDefinitionDto, {
      responseType: "blob",
      params: params,
    })
    .then((res) => res);
}
