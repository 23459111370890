import queryString from "query-string";
const { apiService } = require("../apiService");

const BASE_ROUTE = "/incidents";

const incidentsEndpoint = apiService.injectEndpoints({
  endpoints: (builder) => ({
    getIncidents: builder.query({
      query: (params) => `${BASE_ROUTE}?${queryString.stringify(params)}`,
      transformResponse: (response, meta) => {
        const pagination = meta.response.headers.get("Pagination"); // Access headers
        return { data: response, pagination }; // Include headers in the return object
      },
    }),
  }),
});

export const { useGetIncidentsQuery } = incidentsEndpoint;
