import { useEffect, useState } from "react";
import { Grid, Form } from "components/lynx-components";

import Button from "@mui/material/Button";
import _ from "lodash";
import { validationService } from "../../../services";

import "./add-widget-modal.css";

import { MultiSelect } from "./../../form-controls/multi-select";
import { CountListWidget } from "./widgets/count-list-widget";
import { getEventCountByFilterId } from "./../../../services/events";
import {
  createCountListWidget,
  getWidgetData,
} from "./../../../services/dashboard-widget-service";
import { getMonitoringEventCountByFilterId } from "../../../services/monitoring-events";
import { getMonitoringResultCountByFilterId } from "../../../services/monitoring-result-service";
import { getActionCountByFilterId } from "../../../services/action-service";
import { getIncidentCountByFilterId } from "../../../services/incident-service";
import { getInspectionCountByFilterId } from "../../../services/inspection-event-service";
import { getEnergyLogsCountByFilterId } from "../../../services/energy-service";
import { EntityTypes } from "../../../types/enums";
import useAlert from "hooks/useAlert";
import {
  useDeleteUserDashboardWidgetByIdMutation,
  useUpdateWidgetCountListMutation,
} from "services/rtkApi/endpoints/dashboardWidget";
import { Stack } from "@mui/material";
import { LynxDialog } from "../../lynx-dialog";
import { handleDeleteWidgetFilterLocalStorage } from "../../../services/dashboard-widget-service";

const initialForm = {
  title: "",
  titleError: "",
};
export function CreateCountList(props) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState(initialForm);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  const [countListRows, setCountListRows] = useState([]);

  const { showAlert } = useAlert();
  const [updateWidgetTrigger] = useUpdateWidgetCountListMutation();
  const isForUpdate = Boolean(props.existingWidget);
  const [deleteWidgetTrigger] = useDeleteUserDashboardWidgetByIdMutation();

  const handleInputChange = (e) => {
    let newState = { ...formState };
    const { name, value } = e.target;
    _.set(newState, name, value);
    setFormState(newState);
  };

  const saveForm = () => {
    //validate here
    if (!validateDataForSave()) {
      return;
    }
    //unset errors
    let formToSave = validationService.unsetErrors(formState, "titleError");
    let dto = {
      title: formToSave.title,
      filters: getFiltersToSave(),
    };

    if (isForUpdate) {
      dto.id = props.existingWidget.id;
      updateWidgetTrigger(dto).then(() => {
        props.handleModalClose(true);
        showAlert("success", "Widget updated.");
        customEventRefreshWidgetChart();
      });
    } else {
      createCountListWidget(dto)
        .then(() => {
          props.handleModalClose(true);
          showAlert("success", "Widget created.");
        })
        .catch((err) => {
          showAlert("error", err.response.data.message);
        });
    }
  };

  const handleDeleteWidget = () => {
    deleteWidgetTrigger(props.existingWidget.id)
      .then((res) => {
        showAlert("success", "Widget deleted.");
      })
      .finally(() => {
        setIsDeleteOpen(false);
        props.handleModalClose(true);
      });
    handleDeleteWidgetFilterLocalStorage(props.existingWidget.id);
  };

  const customEventRefreshWidgetChart = () => {
    const event = new CustomEvent("ce-refresh-widget-chart", {
      detail: { refreshData: true },
    });
    window.dispatchEvent(event);
  };

  const getFiltersToSave = () => {
    let filterObject = selectedFilters.map((filter, index) => ({
      entityFilterId: filter.id,
      sortOrder: index + 1,
    }));
    return filterObject;
  };

  const validateDataForSave = () => {
    let newState = { ...formState };
    let isFormValid = false;

    validationService.validateRequiredField(
      newState,
      "title",
      "titleError",
      "Title"
    );

    isFormValid = !validationService.hasError(newState, "titleError");

    if (!isFormValid) {
      setFormState(newState);
      showAlert("error", "Form is not valid for saving.");
    }
    return isFormValid;
  };
  const handleFilterChange = (e) => {
    setSelectedFilters(e.target.value);
    let newValue = e.target.value.filter(
      (x) => !countListRows.map((y) => y.id).includes(x.id)
    );
    let valueToRemove = countListRows.filter(
      (x) => !e.target.value.map((y) => y.id).includes(x.id)
    );

    setDropdownOpen(false);
    let existingRows = [...countListRows];
    if (newValue[0]) {
      setLoading(true);
      var entityType = _.toLower(newValue[0].entityType);

      if (entityType == "event") {
        getEventCountByFilterId(newValue[0].id).then((res) => {
          setCountListRows([
            ...existingRows,
            ...newValue.map((x) => ({
              id: x.id,
              filterName: x.name,
              entityCount: res.data,
              isEnabled: true,
              sortOrder: 1,
            })),
          ]);
          setLoading(false);
        });
      }
      if (entityType == "monitoringevent") {
        getMonitoringEventCountByFilterId(newValue[0].id).then((res) => {
          setCountListRows([
            ...existingRows,
            ...newValue.map((x) => ({
              id: x.id,
              filterName: x.name,
              entityCount: res.data,
              isEnabled: true,
              sortOrder: 1,
            })),
          ]);
          setLoading(false);
        });
      }
      if (entityType == "inspectionevent") {
        getInspectionCountByFilterId(newValue[0].id, false).then((res) => {
          setCountListRows([
            ...existingRows,
            ...newValue.map((x) => ({
              id: x.id,
              filterName: x.name,
              entityCount: res.data,
              isEnabled: true,
              sortOrder: 1,
            })),
          ]);
          setLoading(false);
        });
      }

      if (entityType == "monitoringresult") {
        getMonitoringResultCountByFilterId(newValue[0].id).then((res) => {
          setCountListRows([
            ...existingRows,
            ...newValue.map((x) => ({
              id: x.id,
              filterName: x.name,
              entityCount: res.data,
              isEnabled: true,
              sortOrder: 1,
            })),
          ]);
          setLoading(false);
        });
      }
      if (entityType == "action") {
        getActionCountByFilterId(newValue[0].id).then((res) => {
          setCountListRows([
            ...existingRows,
            ...newValue.map((x) => ({
              id: x.id,
              filterName: x.name,
              entityCount: res.data,
              isEnabled: true,
              sortOrder: 1,
            })),
          ]);
          setLoading(false);
        });
      }
      if (entityType == "incident") {
        getIncidentCountByFilterId(newValue[0].id).then((res) => {
          setCountListRows([
            ...existingRows,
            ...newValue.map((x) => ({
              id: x.id,
              filterName: x.name,
              entityCount: res.data,
              isEnabled: true,
              sortOrder: 1,
            })),
          ]);
          setLoading(false);
        });
      }

      if (entityType == _.toLower(EntityTypes.EnergyLog)) {
        getEnergyLogsCountByFilterId(newValue[0].id).then((res) => {
          setCountListRows([
            ...existingRows,
            ...newValue.map((x) => ({
              id: x.id,
              filterName: x.name,
              entityCount: res.data,
              isEnabled: true,
              sortOrder: 1,
            })),
          ]);
          setLoading(false);
        });
      }
    }

    if (valueToRemove[0]) {
      let rowToSet = existingRows.filter((x) => x.id != valueToRemove[0].id);
      setCountListRows(rowToSet);
    }
  };

  const getFilterLabel = (value) => {
    var label = value.name + " (";
    switch (_.toLower(value.entityType)) {
      case "event":
        label = label + "Events)";
        break;
      case "monitoringevent":
        label = label + "Monitoring Events)";
        break;
      case "action":
        label = label + "Actions)";
        break;
      case "incident":
        label = label + "Incidents)";
        break;
      case "inspectionevent":
        label = label + "Inspections)";
        break;
      case _.toLower(EntityTypes.EnergyLog):
        label = label + "Energy Log)";
        break;
      default:
        label = label + "Monitoring Results)";
    }

    return label;
  };

  const prepareFormStateFromExistingWidget = () => {
    setLoading(true);
    let newState = { ...formState };
    _.set(newState, "title", props.existingWidget.title);
    setFormState(newState);

    setSelectedFilters(
      props.filters.filter((f) =>
        props.existingWidget.dashboardWidgetFilters.some(
          (s) => s.entityFilter.id === f.id
        )
      )
    );

    getWidgetData(props.existingWidget.id, true)
      .then((res) => {
        setCountListRows(JSON.parse(res.data.dataJson));
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (isForUpdate && !_.isEmpty(props.filters)) {
      prepareFormStateFromExistingWidget();
    }
  }, [isForUpdate, props.filters]);

  return (
    <>
      <Grid.Col md={6} width={6}>
        <Form.Group isRequired label="Title">
          <Form.Input
            name="title"
            error={formState.titleError}
            onChange={handleInputChange}
            value={formState.title}
          ></Form.Input>
        </Form.Group>
      </Grid.Col>
      <Grid.Col md={6} width={6}>
        <Form.Group label="Select Filters" id="title">
          <MultiSelect
            name="selectedFilter"
            id="selectFilters"
            onChange={handleFilterChange}
            value={selectedFilters}
            dropdownValues={props.filters}
            key="id"
            labelFunction={(value) => getFilterLabel(value)}
            open={dropdownOpen}
          />
        </Form.Group>
      </Grid.Col>{" "}
      <Grid.Col md={12} width={12}>
        <CountListWidget
          title={formState.title}
          rows={countListRows}
          loading={loading}
        />
      </Grid.Col>
      <Grid.Col md={12} width={12}>
        <Stack direction="row-reverse" spacing={1}>
          <Button variant="contained" onClick={saveForm}>
            {isForUpdate ? "Save" : "Create Widget"}
          </Button>
          {isForUpdate && (
            <Button
              variant="contained"
              color="error"
              onClick={() => setIsDeleteOpen(true)}
            >
              Delete
            </Button>
          )}
        </Stack>
      </Grid.Col>
      <LynxDialog
        open={isDeleteOpen}
        title="Are you sure you want to delete the dashboard widget?"
        description={"This cannot be undone."}
        handleClose={() => setIsDeleteOpen(false)}
        handleConfirm={handleDeleteWidget}
      />
    </>
  );
}
