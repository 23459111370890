import { BaseParams } from "types";
import queryString from "query-string";
import { RtkTagTypes } from "types/enums";

const { apiService } = require("../apiService");

const BASE_ROUTE = "/monitoringlocations";

const monitoringlocationsEndpoint = apiService.injectEndpoints({
  endpoints: (builder: any) => ({
    getMonitoringLocations: builder.query({
      query: (params: BaseParams) =>
        `${BASE_ROUTE}?${queryString.stringify(params)}`,
      providesTags: [RtkTagTypes.GetMonitoringLocations],
      transformResponse: (response: any, meta: any) => {
        const pagination = meta.response.headers.get("Pagination"); // Access headers
        return { data: response, pagination }; // Include headers in the return object
      },
    }),
    getMonitoringLocation: builder.query({
      query: (id: number) => `${BASE_ROUTE}/${id}`,
      providesTags: (result: any, error: any, id: any) => [
        { type: RtkTagTypes.GetMonitoringLocations, id },
      ],
    }),
    addMonitoringLocation: builder.mutation({
      query: (args: any) => ({
        url: BASE_ROUTE,
        method: "POST",
        body: args,
      }),
      invalidatesTags: [RtkTagTypes.GetMonitoringLocations],
    }),
    updateMonitoringLocation: builder.mutation({
      query: (args: any) => ({
        url: `${BASE_ROUTE}/${args.id}`,
        method: "PATCH",
        body: args.body,
      }),
      invalidatesTags: [RtkTagTypes.GetMonitoringLocations],
    }),
    deleteMonitoringLocation: builder.mutation({
      query: (id: number) => ({
        url: `${BASE_ROUTE}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [RtkTagTypes.GetMonitoringLocations],
    }),
  }),
});

export const {
  useLazyGetMonitoringLocationQuery,
  useGetMonitoringLocationsQuery,
  useLazyGetMonitoringLocationsQuery,
  useAddMonitoringLocationMutation,
  useUpdateMonitoringLocationMutation,
  useDeleteMonitoringLocationMutation,
} = monitoringlocationsEndpoint;
