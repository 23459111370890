import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import { LynxDataGrid } from "../../data-grid/lynx-data-grid";
import { getInspections } from "../../../services/inspection-event-service";
import { getInspectionColumns } from "./inspection-columns";

import useOnlineStatus from "../../../hooks/useOnlineStatus";
import {
  inspectionEventStore,
  offlineDataService,
} from "../../../services/offlineDataService";
import { useHistory } from "react-router-dom";
import { InspectionEventDto, LynxGridColDef, RootState } from "types";
import { useSelector } from "react-redux";
import _ from "lodash";
import {
  useGetInspectionsQuery,
  useLazyGetInspectionsQuery,
} from "services/rtkApi/endpoints/inspections";
import { EntityTypes } from "types/enums-ts";
export function InspectionList() {
  const [columns, setColumns] = useState<LynxGridColDef[]>([]);
  const [isOfflineReady, setIsOfflineReady] = useState(true);
  const isOffline = !useOnlineStatus();
  const history = useHistory();
  const { users } = useSelector((state: RootState) => state.lookups);
  const predefinedLookups = { users: users };
  useEffect(() => {
    if (isOffline) {
      let isReady = true;

      offlineDataService.checkIfOfflineIsReady().then((res) => {
        if (!res) {
          isReady = false;
        }
        setIsOfflineReady(isReady);
      });
    }
  }, [isOffline]);

  useEffect(() => {
    if (!_.isEmpty(users)) {
      let newColumns = [...getInspectionColumns(predefinedLookups)];
      let colIndex = newColumns.findIndex((x) => x.field == "inspectionNumber");
      newColumns[colIndex].renderCell = (params: any) => {
        return (
          <Button
            variant="text"
            className="inspection-number-button"
            onClick={() => handleInspectionNumberClick(params.row)}
          >
            {params.value}
          </Button>
        );
      };
      setColumns(newColumns);
    }
  }, [users]);

  const navigateToAddInspection = () => {
    history.push(`/new-inspection`);
  };
  const handleInspectionNumberClick = (row: InspectionEventDto) => {
    history.push(`/inspections/${row.id}`);
  };
  return (
    <>
      {!_.isEmpty(columns) && (
        <LynxDataGrid
          columns={columns}
          enableSavedFilters
          title="Inspections"
          useQuery={useGetInspectionsQuery}
          indexDbObjectStoreName={inspectionEventStore}
          localStorageName="inspections"
          entityType={EntityTypes.InspectionEvent}
          indexDbSortColumn="inspectionNumber"
          addButtonText="Add Inspection"
          addButtonAction={navigateToAddInspection}
          isOfflineReady={isOfflineReady}
        />
      )}
    </>
  );
}
