import { BaseParams } from "types";
import queryString from "query-string";
import { RtkTagTypes } from "types/enums";

const { apiService } = require("../apiService");

const BASE_ROUTE = "/parameters";

const parametersEndpoint = apiService.injectEndpoints({
  endpoints: (builder: any) => ({
    getParameters: builder.query({
      query: (params: BaseParams) =>
        `${BASE_ROUTE}?${queryString.stringify(params)}`,
      providesTags: [RtkTagTypes.GetParameters],
      transformResponse: (response: any, meta: any) => {
        const pagination = meta.response.headers.get("Pagination"); // Access headers
        return { data: response, pagination }; // Include headers in the return object
      },
    }),
    addParameter: builder.mutation({
      query: (args: any) => ({
        url: BASE_ROUTE,
        method: "POST",
        body: args,
      }),
      invalidatesTags: [RtkTagTypes.GetParameters],
    }),
    updateParameter: builder.mutation({
      query: (args: any) => ({
        url: `${BASE_ROUTE}/${args.id}`,
        method: "PATCH",
        body: args.body,
      }),
      invalidatesTags: [RtkTagTypes.GetParameters],
    }),
    deleteParameter: builder.mutation({
      query: (id: number) => ({
        url: `${BASE_ROUTE}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [RtkTagTypes.GetParameters],
    }),
  }),
});

export const {
  useGetParametersQuery,
  useLazyGetParametersQuery,
  useAddParameterMutation,
  useUpdateParameterMutation,
  useDeleteParameterMutation,
} = parametersEndpoint;
