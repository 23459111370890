import { useGetParametersQuery } from "services/rtkApi/endpoints/parameters";

export const monitoringVarianceLimitColumns = [
  {
    field: "edit",
    headerName: "Edit / Delete",
    width: 100,
    type: "actions",
    sortable: false,
  },
  {
    field: "parameterName",
    headerName: "Parameter",
    width: 250,
    type: "singleSelect",
    query: useGetParametersQuery,
    getOptionValue: (value) => value.name,
    getOptionLabel: (value) => value.name,
    importable: true,
  },
  {
    field: "varianceLimit",
    headerName: "Allowance",
    width: 150,
    type: "string",
    importable: true,
  },
];
