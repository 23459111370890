import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import React from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import DeleteIcon from "@mui/icons-material/Delete";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import EditIcon from "@mui/icons-material/Edit";
import { WidgetTypes } from "types/enums-ts";
export function WidgetHeaderButton(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton
        aria-label="delete"
        className="ml-auto "
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="widget-menu"
        anchorEl={anchorEl}
        open={open}
        sx={{ padding: 0 }}
        onClose={handleClose}
        className="p-0"
        MenuListProps={{
          "aria-labelledby": "widget-menu-button",
          sx: { py: 0 },
        }}
      >
        <MenuList className="p-0">
          <MenuItem
            disabled={props.upDisabled}
            onClick={() => {
              handleClose();
              props.handleSortChange(props.widget.id, true);
            }}
          >
            <ListItemIcon>
              <ArrowUpwardIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Move Up</ListItemText>
          </MenuItem>
          <MenuItem
            disabled={props.downDisabled}
            onClick={() => {
              handleClose();
              props.handleSortChange(props.widget.id, false);
            }}
          >
            <ListItemIcon>
              <ArrowDownwardIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Move Down</ListItemText>
          </MenuItem>
          {props.widget.widgetType != WidgetTypes.CountList &&
            props.handleExportWidget && (
              <MenuItem
                onClick={() => {
                  handleClose();
                  props.handleExportWidget(props.widget.id);
                }}
              >
                <ListItemIcon>
                  <CameraAltIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Save as png</ListItemText>
              </MenuItem>
            )}

          {props.handleRefresh && (
            <MenuItem
              onClick={() => {
                handleClose();
                props.handleRefresh();
              }}
            >
              <ListItemIcon>
                <RefreshIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Refresh Data</ListItemText>
            </MenuItem>
          )}
          {props.handleUpdate && (
            <MenuItem
              onClick={() => {
                handleClose();
                props.handleUpdate(props.widget);
              }}
            >
              <ListItemIcon>
                <EditIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Edit</ListItemText>
            </MenuItem>
          )}

          <MenuItem
            onClick={() => {
              handleClose();
              props.handleDelete(props.widget.id);
            }}
          >
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Delete</ListItemText>
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
}
