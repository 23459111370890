export enum EntityTypes {
  Asset = "Asset",
  Event = "Event",
  Customer = "Customer",
  Incident = "Incident",
  IncidentInvestigation = "IncidentInvestigation",
  MonitoringEvent = "MonitoringEvent",
  MonitoringResult = "MonitoringResult",
  MonitoringLimit = "MonitoringLimit",
  PortalSubmission = "PortalSubmission",
  Action = "Action",
  MonitoringVarianceLimit = "MonitoringVarianceLimit",
  MonitoringLocation = "MonitoringLocation",
  InspectionEvent = "InspectionEvent",
  EnergyLog = "EnergyLog",
  Organization = "Organization",
  Equipment = "Equipment",
  Permit = "Permit",
  TimeSeries = "TimeSeries",
  CustomerPortalUserHistory = "CustomerPortalUserHistory",
  PhotoImportHistory = "PhotoImportHistory",
  Kpi = "Kpi",
  KpiHistory = "KpiHistory",
  User = "User",
  AuditLog = "AuditLog",
  Contact = "Contact",
  ContactGroup = "ContactGroup",
  ImportHistory = "ImportHistory",
  ImportSpecification = "ImportSpecification",
  CorrespondenceLog = "CorrespondenceLog",
  Document = "Document",
  ComplianceAndRegulation = "ComplianceAndRegulation",
}

export enum WidgetTypes {
  CountList = "CountList",
  DonutChart = "DonutChart",
  BarChart = "BarChart",
  LineChart = "LineChart",
  TimeLine = "TimeLine",
}