import queryString from "query-string";
import { apiService } from "../apiService";
import { BaseParams, OrganizationComplianceRegulationDto } from "types";
import { RtkTagTypes } from "types/enums";

const BASE_ROUTE = "/organizationcomplianceregulation";

const contactsEndpoint = apiService.injectEndpoints({
  endpoints: (builder: any) => ({
    getOrgComplianceAndRegulations: builder.query({
      query: (contactParams: BaseParams) =>
        `${BASE_ROUTE}?${queryString.stringify(contactParams)}`,
      providesTags: [RtkTagTypes.GetOrgComplianceAndRegulations],
      transformResponse: (response: any, meta: any) => {
        const pagination = meta.response.headers.get("Pagination");
        return { data: response, pagination };
      },
    }),

    // Update a contact (patch)
    updateOrgComplianceAndRegulations: builder.mutation({
      query: (dto: OrganizationComplianceRegulationDto) => ({
        url: `${BASE_ROUTE}/${dto.id}`,
        method: "PUT",
        body: dto,
      }),
      invalidatesTags: [RtkTagTypes.GetOrgComplianceAndRegulations],
    }),
  }),
});

export const {
  useGetOrgComplianceAndRegulationsQuery,
  useUpdateOrgComplianceAndRegulationsMutation,
} = contactsEndpoint;
