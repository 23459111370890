import {
  multipleSelectContainsOperator,
  multipleSelectDoesNotContainOperator,
} from "components/data-grid/multiple-select-operator";
import { dateUtil } from "services/date-util";
import { useGetAssetsLookupsQuery } from "services/rtkApi/endpoints/assets";
import { useGetLookupsQuery } from "services/rtkApi/endpoints/lookups";
import { LynxGridColDef } from "types";
import { LookupTypes } from "types/enums";

export const permitColumns = (predefinedLookups: any = null) =>
  [
    {
      field: "permitNumber",
      headerName: "Permit #",
      width: 100,
      type: "string",
    },
    {
      field: "assetNames",
      headerName: "Asset Names",
      width: 200,
      type: "singleSelect",
      query: useGetAssetsLookupsQuery,
      getOptionValue: (value: any) => (!value ? "" : value.name),
      getOptionLabel: (value: any) => (!value ? "" : value.name),
      filterOperators: [
        ...multipleSelectContainsOperator,
        ...multipleSelectDoesNotContainOperator,
      ],
      renderCell: (params: any) => <div>{params.value}</div>,
    },
    {
      field: "createdByFullName",
      headerName: "Created By",
      width: 150,
      type: "singleSelect",
      valueOptions: predefinedLookups.users,
      getOptionValue: (value: any) => (!value ? "" : value.fullName),
      getOptionLabel: (value: any) => (!value ? "" : value.fullName),
    },
    {
      field: "permitTypeName",
      headerName: "Permit Type",
      width: 200,
      type: "singleSelect",
      query: useGetLookupsQuery,
      queryParams: { lookupType: LookupTypes.PermitType },
      getOptionValue: (value: any) => (!value ? "" : value.code),
      getOptionLabel: (value: any) => (!value ? "" : value.code),
    },
    {
      field: "startDate",
      headerName: "Start Date",
      width: 200,
      type: "date",
      valueGetter: (value) => {
        return dateUtil.convertColumnDateOnlyToLocal(value);
      },
    },
    {
      field: "expiryDate",
      headerName: "Expiry Date",
      width: 200,
      type: "date",
      valueGetter: (value) => {
        return dateUtil.convertColumnDateOnlyToLocal(value);
      },
    },
    {
      field: "appliedDate",
      headerName: "Applied Date",
      width: 200,
      type: "date",
      valueGetter: (value) => {
        return dateUtil.convertColumnDateOnlyToLocal(value);
      },
    },
    {
      field: "circulatedDate",
      headerName: "Circulated Date",
      width: 200,
      type: "date",
      valueGetter: (value) => {
        return dateUtil.convertColumnDateOnlyToLocal(value);
      },
    },
    {
      field: "applyToCirc",
      headerName: "Apply To Circ",
      width: 125,
      type: "number",
      filterable: false,
      sortable: false,
    },
    {
      field: "applyToAccept",
      headerName: "Apply To Accept",
      width: 125,
      type: "number",
      filterable: false,
      sortable: false,
    },
    {
      field: "circToAccept",
      headerName: "Circ To Accept",
      width: 125,
      type: "number",
      filterable: false,
      sortable: false,
    },
  ] as LynxGridColDef[];
