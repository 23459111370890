import queryString from "query-string";
import { apiService } from "../apiService";
import { RtkTagTypes } from "types/enums";
import { BaseParams } from "types";

const BASE_ROUTE = "/imports";

const importsEndpoint = apiService.injectEndpoints({
  endpoints: (builder: any) => ({
    // Step 3: Upload initial file
    uploadInitialFile: builder.mutation({
      query: (dto: any) => ({
        url: `${BASE_ROUTE}/upload`,
        method: "POST",
        body: dto,
        headers: { "Content-Type": "multipart/form-data" },
      }),
    }),

    // Upload corrected file and validate
    uploadCorrectedFileAndValidate: builder.mutation({
      query: (dto: any) => ({
        url: `${BASE_ROUTE}/corrected`,
        method: "POST",
        body: dto,
        headers: { "Content-Type": "multipart/form-data" },
      }),
    }),

    // Get import specifications with pagination
    getImportSpecifications: builder.query({
      query: (baseParams: any) =>
        `${BASE_ROUTE}/specifications?${queryString.stringify(baseParams)}`,
      providesTags: [RtkTagTypes.GetImportSpecifications],
      transformResponse: (response: any, meta: any) => {
        const pagination = meta.response.headers.get("Pagination");
        return { data: response, pagination };
      },
    }),

    // Get import history with pagination
    getImportHistory: builder.query({
      query: (baseParams: BaseParams) =>
        `${BASE_ROUTE}/history?${queryString.stringify(baseParams)}`,
      providesTags: [RtkTagTypes.GetImportHistory],
      transformResponse: (response: any, meta: any) => {
        const pagination = meta.response.headers.get("Pagination");
        return { data: response, pagination };
      },
    }),

    // Step 4: Create import specification mappings
    createImportSpecificationMappings: builder.mutation({
      query: (dto: any) => ({
        url: `${BASE_ROUTE}/mappings`,
        method: "POST",
        body: dto,
      }),
    }),

    // Step 5: Create or update import translations
    createNewImportTranslations: builder.mutation({
      query: (dto: any) => ({
        url: `${BASE_ROUTE}/translations`,
        method: "POST",
        body: dto,
      }),
    }),

    // Step 6-2: Start import
    startImport: builder.mutation({
      query: (dto: any) => ({
        url: BASE_ROUTE,
        method: "POST",
        body: dto,
      }),
    }),

    // Step 6-1: Save specification
    saveSpec: builder.mutation({
      query: (dto: any) => ({
        url: `${BASE_ROUTE}/saveSpec`,
        method: "POST",
        body: dto,
      }),
    }),

    // Proceed with import
    proceedWithImport: builder.mutation({
      query: (historyId: number) => ({
        url: `${BASE_ROUTE}/proceed/${historyId}`,
        method: "POST",
      }),
    }),

    // Delete import by history
    deleteImportByHistory: builder.mutation({
      query: (historyId: number) => ({
        url: `${BASE_ROUTE}/history/${historyId}`,
        method: "DELETE",
      }),
      invalidatesTags: [RtkTagTypes.GetImportHistory],
    }),

    // Delete specification by ID
    deleteSpecById: builder.mutation({
      query: (specificationId: number) => ({
        url: `${BASE_ROUTE}/specifications/${specificationId}`,
        method: "DELETE",
      }),
      invalidatesTags: [RtkTagTypes.GetImportSpecifications],
    }),

    // Download import file
    downloadImportFile: builder.query({
      query: (importHistoryId: number) =>
        `${BASE_ROUTE}/download/${importHistoryId}`,
      responseType: "blob", // Ensures the response is treated as a file
    }),
  }),
});

export const { useGetImportHistoryQuery, useGetImportSpecificationsQuery } =
  importsEndpoint;
