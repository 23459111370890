import queryString from "query-string";
import { BaseParams } from "types";
import { RtkTagTypes } from "types/enums";

const { apiService } = require("../apiService");

const BASE_ROUTE = "/photoImportHistories";

const photoImportHistoriesEndpoint = apiService.injectEndpoints({
  endpoints: (builder: any) => ({
    getPhotoImportHistories: builder.query({
      query: (params: BaseParams) =>
        `${BASE_ROUTE}?${queryString.stringify(params)}`,
      providesTags: [RtkTagTypes.GetPhotoImportHistories],
      transformResponse: (response: any, meta: any) => {
        const pagination = meta.response.headers.get("Pagination");
        return { data: response, pagination };
      },
    }),
    getPhotoImportHistory: builder.query({
      query: (id: number) => `${BASE_ROUTE}/${id}`,
      providesTags: [RtkTagTypes.GetPhotoImportHistory],
    }),
    getPhotoImportHistoryResults: builder.query({
      query: (id: number) => `${BASE_ROUTE}/${id}/results`,
      providesTags: [RtkTagTypes.GetPhotoImportHistoryResults],
    }),
    submitPhotoForTraining: builder.mutation({
      query: ({
        importHistoryId,
        groundTruth,
      }: {
        importHistoryId: number;
        groundTruth: any;
      }) => ({
        url: `${BASE_ROUTE}/${importHistoryId}/submitTraining`,
        method: "POST",
        body: groundTruth,
      }),
      invalidatesTags: [
        RtkTagTypes.GetPhotoImportHistories,
        RtkTagTypes.GetPhotoImportHistory,
      ],
    }),
    skipPhotoForTraining: builder.mutation({
      query: ({
        importHistoryId,
        filePath,
      }: {
        importHistoryId: number;
        filePath: string;
      }) => ({
        url: `${BASE_ROUTE}/${importHistoryId}/skipTraining`,
        method: "POST",
        body: filePath,
      }),
      invalidatesTags: [
        RtkTagTypes.GetPhotoImportHistories,
        RtkTagTypes.GetPhotoImportHistory,
      ],
    }),
  }),
});

export const {
  useGetPhotoImportHistoriesQuery,
  useLazyGetPhotoImportHistoriesQuery,
  useGetPhotoImportHistoryQuery,
  useLazyGetPhotoImportHistoryQuery,
  useGetPhotoImportHistoryResultsQuery,
  useLazyGetPhotoImportHistoryResultsQuery,
  useSubmitPhotoForTrainingMutation,
  useSkipPhotoForTrainingMutation,
} = photoImportHistoriesEndpoint;
