import { BaseParams } from "types";
import queryString from "query-string";

const { apiService } = require("../apiService");

const BASE_ROUTE = "/actions";

const actionsEndpoint = apiService.injectEndpoints({
  endpoints: (builder: any) => ({
    getActions: builder.query({
      query: (params: BaseParams) =>
        `${BASE_ROUTE}?${queryString.stringify(params)}`,
      transformResponse: (response: any, meta: any) => {
        const pagination = meta.response.headers.get("Pagination"); // Access headers
        return { data: response, pagination }; // Include headers in the return object
      },
    }),
  }),
});

export const { useGetActionsQuery } = actionsEndpoint;
