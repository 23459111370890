import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import localforage from "localforage";
import _ from "lodash";
import moment from "moment";
import queryString from "query-string";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Card, Dimmer, Form, Grid } from "components/lynx-components";
import { roleMatch } from "../../../actions/auth";
import useOnlineStatus from "../../../hooks/useOnlineStatus";
import { validationService } from "../../../services";
import { getAssetLayerById, getAssetLookups } from "../../../services/assets";
import { addEntityAttachments } from "../../../services/attachments";
import {
  getCustomFields,
  setupInitialCustomFieldState,
  transformCustomFieldState,
} from "../../../services/custom-fields-service";
import { dateUtil } from "../../../services/date-util";
import {
  createEventWithAttachments,
  createGuid,
  deleteEvent,
  getEvent,
  patchEvent,
} from "../../../services/events";
import { getLookups } from "../../../services/lookup";
import {
  assetStore,
  categoryStore,
  customFieldStore,
  eventStore,
  eventSyncStore,
  subCategoryStore,
} from "../../../services/offlineDataService";
import { EntityTypes } from "types/enums-ts";
import { EventStatuses, UserRoles } from "../../../types/enums";
import { Accordion, AccordionDetails, AccordionSummary } from "../../accordion";
import { AttachmentViewer } from "../../attachment-viewer";
import { MultiSelect } from "../../form-controls/multi-select";
import { LocationSelectionControl } from "../../location-selection-control";
import "../events/event-modal.css";
import { ContactForm } from "../contacts/contact-form";
import { getPermits } from "./../../../services/permit-service";
import Contacts from "./contacts";
import EventDetailsCorrespondenceLog from "./event-details-correspondence-log";
import Tags from "./tags";
import { LynxTextArea } from "components/form-controls/lynx-form-controls";
import { Stack } from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import EventScheduler from "./scheduler/event-scheduler";
import useAlert from "hooks/useAlert";
import {
  AssetDto,
  ContactDto,
  CustomFieldState,
  EntityAssetDto,
  EntityLookupDto,
  EntityPermitDto,
  EntityUserDto,
  EventContactDto,
  EventCustomFieldDto,
  EventCustomFieldValueDto,
  EventDto,
  LookupDto,
  PermitDto,
  RootState,
} from "types";
import EventCorrespondenceMerge from "./event-correspondence-merge";
import { LynxDialog } from "components/lynx-dialog";
import CustomField from "./custom-field";
import CommentsInput, { TaggableEntity } from "./comments-input";
import { contactAndUserRegex } from "services/helpers/tagParser";

export const eventSyncLocalStorageName: string = "eventSync";

interface EventFormProps {
  openSection: string;
  isScheduledEvent: boolean;
  defaultValues: EventDto;
  linkedEntityId: number;
  entityId: number;
  event?: EventDto;
  isForEventDetails: boolean;
  handleModalClose: (
    refreshData: boolean,
    isDelete?: boolean,
    updatedEvent?: EventDto,
    isLinkedEvent?: any,
    promptFollowUp?: boolean
  ) => void;
}

const EventForm: FC<EventFormProps> = (props) => {
  const [formState, setFormState] = useState<any>({});
  const location = useLocation();
  const history = useHistory();
  const isScheduledEvent = (): boolean => {
    if (props.isScheduledEvent) {
      return true;
    }
    const values = queryString.parse(location.search);
    if (values.isScheduled) {
      return true;
    }
    if (
      formState &&
      formState.eventDueDateTime !== "" &&
      formState.eventDateTime === ""
    ) {
      return true;
    }
    return false;
  };

  interface FormState {
    eventDateTime?: string;
    eventDueDateTime?: string;
    eventCategoryId?: number;
    eventSubCategoryId?: number;
    isConfidential?: boolean;
    description?: string;
    urgency?: string;
    followUpRequired?: boolean;
    followUpDate?: string;
    followUpDescription?: string;
    eventDateTimeError?: string;
    assets?: any[];
    permits?: any[];
    eventCategoryIdError?: string;
    eventSubCategoryIdError?: string;
    locationTypeIdError?: string;
    severityError?: string;
    longitude?: number;
    latitude?: number;
    longitudeError?: string;
    latitudeError?: string;
    eventUrgencyError?: string;
    eventDueDateTimeError?: string;
    entityUsers?: any[];
    appliesToPermit?: boolean;
    eventCustomFieldValues?: EventCustomFieldValueDto[];
  }

  const initialForm: FormState = {
    eventDateTime: !isScheduledEvent()
      ? moment().format("YYYY-MM-DD HH:mm")
      : "",
    eventDueDateTime: "",
    eventCategoryId: undefined,
    eventSubCategoryId: undefined,
    isConfidential: false,
    description: "",

    urgency: "",
    followUpRequired: false,
    followUpDate: "",
    followUpDescription: "",
    eventDateTimeError: "",
    assets: [],
    permits: [],
    eventCategoryIdError: "",
    eventSubCategoryIdError: "",
    locationTypeIdError: "",
    severityError: "",
    longitude: undefined,
    latitude: undefined,
    longitudeError: "",
    latitudeError: "",
    eventUrgencyError: "",
    eventDueDateTimeError: "",
    entityUsers: [],
    eventCustomFieldValues: [],
  };
  const [reviewSaveWarning, setReviewSaveWarning] = useState<boolean>(false);

  const [initialFormState, setInitialFormState] = useState<FormState>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [assetLookups, setAssetLookups] = useState<AssetDto[]>([]);
  const [selectedEventSubCategories, setSelectedEventSubCategories] = useState<
    LookupDto[]
  >([]);
  const [corLogExpanded, setCorLogExpanded] = useState<boolean>(true);
  const [eventCategories, setEventCategories] = useState<LookupDto[]>([]);
  const [eventSubCategories, setEventSubCategories] = useState<LookupDto[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

  const [eventCustomFields, setEventCustomFields] = useState<
    EventCustomFieldDto[]
  >([]);
  const [selectedCustomFields, setSelectedCustomFields] = useState<
    EventCustomFieldDto[]
  >([]);
  const [existingCustomFieldValues, setExistingCustomFieldValues] = useState<
    EventCustomFieldValueDto[]
  >([]);
  const [selectedAssetGeojson, setSelectedAssetGeojson] = useState<any>({});
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [event, setEvent] = useState<EventDto>({} as EventDto);
  const [showFileSizeError, setShowFileSizeError] = useState<boolean>(false);
  const [locationTouched, setLocationTouched] = useState<boolean>(false);

  const [allContacts, setAllContacts] = useState<ContactDto[]>([]);
  const [initialFormSet, setInitialFormSet] = useState<boolean>(false);

  const [detailsExpanded, setDetailsExpanded] = useState<boolean>(true);
  const [customFieldsExpanded, setCustomFieldsExpanded] =
    useState<boolean>(true);
  const [locationExpanded, setLocationExpanded] = useState<boolean>(false);
  const [permits, setPermits] = useState<PermitDto[]>([]);
  const [eventLocked, setEventLocked] = useState<boolean>(false);
  const [parentEventId, setParentEventId] = useState<number>(0);
  const [assetsLoading, setAssetsLoading] = useState<boolean>(true);
  const [customFieldsLoading, setCustomFieldsLoading] = useState<boolean>(true);
  const [categoriesLoading, setCategoriesLoading] = useState<boolean>(true);
  const [subCategoriesLoading, setSubCategoriesLoading] =
    useState<boolean>(true);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [defaultLatitude, setDefaultLatitude] = useState<number | undefined>(
    undefined
  );
  const [defaultLongitude, setDefaultLongitude] = useState<number | undefined>(
    undefined
  );
  const [customFieldState, setCustomFieldState] = useState<{
    [key: string]: CustomFieldState;
  }>({});
  const [showAddContact, setShowAddContact] = useState<boolean>(false);
  const [showMergeEvent, setShowMergeEvent] = useState<boolean>(false);
  const [eventContacts, setEventContacts] = useState<EventContactDto[]>([]);
  const [eventTags, setEventTags] = useState<LookupDto[]>([]);
  const [permitsLoading, setPermitsLoading] = useState<boolean>(true);
  const isOffline: boolean = !useOnlineStatus();
  const organization = useSelector((state: RootState) => state.organization);
  const allUsers = useSelector((state: RootState) => state.lookups.users);
  const users = useMemo(
    () => allUsers?.filter((x) => x.isEventsUser) || [],
    [allUsers]
  );

  const account = useSelector((state: RootState) => state.account);
  let urgencyList: { id: string; value: string }[] = [
    { id: "Green", value: "Low" },
    { id: "Yellow", value: "Medium" },
    { id: "Red", value: "High" },
  ];

  const [schedulerOpen, setSchedulerOpen] = useState<boolean>(false);

  const { showAlert } = useAlert();

  const taggableUsers = useMemo(() => {
    if (!_.isEmpty(users)) {
      return users?.map(
        (m) => ({ id: m.id, display: m.fullName } as TaggableEntity)
      );
    }
  }, [users]);

  const commentParseToText = (markup: string) => {
    let finalText = markup;
    const contactMatches = [...markup.matchAll(contactAndUserRegex)];

    contactMatches.forEach((match) => {
      finalText = finalText.replace(match[0], match[2]);
    });

    return finalText;
  };

  useEffect(() => {
    if (
      !assetsLoading &&
      !customFieldsLoading &&
      !categoriesLoading &&
      !subCategoriesLoading &&
      !permitsLoading &&
      !_.isEmpty(formState)
    ) {
      setIsLoading(false);
    }
  }, [
    assetsLoading,
    customFieldsLoading,
    categoriesLoading,
    subCategoriesLoading,
    formState,
  ]);

  useEffect(() => {
    if (props.openSection === "details") {
      setDetailsExpanded(true);
      setCustomFieldsExpanded(false);
      setLocationExpanded(false);
    }
    if (props.openSection === "additional-fields") {
      setDetailsExpanded(false);
      setCustomFieldsExpanded(true);
      setLocationExpanded(false);
    }
    if (props.openSection === "location") {
      setDetailsExpanded(false);
      setCustomFieldsExpanded(false);
      setLocationExpanded(true);
    }
  }, [props.openSection]);

  useEffect(() => {
    if (!organization.isLoading) {
      loadAssetLookups();
      loadEventCustomFields();
      loadEventCategoryLookups();
      loadEventSubCategoryLookups();
      loadPermits();
    }
  }, [organization]);

  const loadPermits = (): void => {
    if (organization && organization.hasPermitFeature) {
      getPermits({ pageSize: 1000 })
        .then((res) => {
          setPermits(res.data);
          setPermitsLoading(false);
        })
        .catch(() => {
          setPermits([]);
          setPermitsLoading(false);
        });
    } else {
      setPermits([]);
      setPermitsLoading(false);
    }
  };

  useEffect(() => {
    if (!assetsLoading && !permitsLoading) {
      loadForm();
    }
  }, [assetsLoading, permitsLoading]);

  useEffect(() => {
    if (
      !_.isEmpty(props.defaultValues) &&
      props.defaultValues.entityAssets &&
      !_.isEmpty(assetLookups) &&
      initialFormSet
    ) {
      const assets = assetLookups.filter((a) =>
        props.defaultValues.entityAssets
          ?.map((a: EntityAssetDto) => a.assetId)
          .includes(a.id)
      );
      let newState = { ...initialForm, ...props.defaultValues, assets: assets };
      setFormState(newState);
      setParentEventId(props.linkedEntityId);
      if (
        _.last(assets)?.centroidLatitude != null &&
        _.last(assets)?.centroidLongitude != null
      ) {
        setDefaultLatitude(_.last(assets)?.centroidLatitude);
        setDefaultLongitude(_.last(assets)?.centroidLongitude);
        getAssetLayerGeojson(_.last(assets)?.id as number);
      }
    }
  }, [props.defaultValues, assetLookups, initialFormSet]);

  useEffect(() => {
    if (!_.isEmpty(eventCustomFields)) {
      if (formState.eventSubCategoryId) {
        setSubCategoryCustomFields(formState.eventSubCategoryId);
      }
      if (formState.eventCategoryId && !formState.eventSubCategoryId) {
        setCategoryCustomFields(formState.eventCategoryId);
      }
      if (!formState.eventCategoryId && !formState.eventSubCategoryId) {
        setStandardCustomFields();
      }
    }
  }, [
    formState.eventCategoryId,
    formState.eventSubCategoryId,
    eventCustomFields,
  ]);

  useEffect(() => {
    if (props.entityId <= 0 && !_.isEmpty(eventCustomFields)) {
      let newCustomFieldState = setupInitialCustomFieldState(
        eventCustomFields,
        []
      );

      setCustomFieldState(newCustomFieldState);
    }

    if (
      props.entityId > 0 &&
      !_.isEmpty(eventCustomFields) &&
      existingCustomFieldValues
    ) {
      let newCustomFieldState = setupInitialCustomFieldState(
        eventCustomFields,
        existingCustomFieldValues
      );
      setCustomFieldState(newCustomFieldState);
    }
  }, [eventCustomFields, props.entityId, existingCustomFieldValues]);

  useEffect(() => {
    if (!_.isEmpty(event) && !_.isEmpty(eventSubCategories)) {
      setSelectedEventSubCategories(
        eventSubCategories.filter(
          (x) => x.parentLookupId === event.eventCategoryId
        )
      );
    }
  }, [event, eventSubCategories]);

  const handleNavigateTo = (
    e: React.MouseEvent<HTMLAnchorElement> | null,
    url: string
  ): void => {
    if (e && e != null) {
      e.preventDefault();
    }
    history.push(url);
  };

  const loadAssetLookups = (): void => {
    getAssetLookups()
      .then((res) => {
        setAssetLookups(res.data);
        localforage.setItem(assetStore, res.data);
        setAssetsLoading(false);
      })
      .catch(() => {
        localforage.getItem(assetStore).then((data: any) => {
          setAssetLookups(
            data.sort((a: AssetDto, b: AssetDto) =>
              a.name?.localeCompare(b.name as string)
            )
          );
          setAssetsLoading(false);
        });
      });
  };

  const loadEventCustomFields = (): void => {
    getCustomFields(EntityTypes.Event)
      .then((res) => {
        let customFields = res.data.filter((x: any) => !x.isDeleted);
        setEventCustomFields(customFields);
        localforage.setItem(customFieldStore, customFields);
        setCustomFieldsLoading(false);
      })
      .catch(() => {
        localforage.getItem(customFieldStore).then((data: any) => {
          setEventCustomFields(data);
          setCustomFieldsLoading(false);
        });
      });
  };

  const loadEventCategoryLookups = (): void => {
    getLookups("eventCategory")
      .then((res) => {
        setEventCategories(res.data);
        localforage.setItem(categoryStore, res.data);
        setCategoriesLoading(false);
      })
      .catch(() => {
        localforage.getItem(categoryStore).then((data: any) => {
          setEventCategories(
            data.sort((a: any, b: any) => a.code.localeCompare(b.code))
          );
          setCategoriesLoading(false);
        });
      });
  };

  const loadEventSubCategoryLookups = (): void => {
    getLookups("eventSubCategory")
      .then((res) => {
        setEventSubCategories(res.data);
        localforage.setItem(subCategoryStore, res.data);
        setSubCategoriesLoading(false);
      })
      .catch(() => {
        localforage.getItem(subCategoryStore).then((data: any) => {
          setEventSubCategories(
            data.sort((a: any, b: any) => a.code.localeCompare(b.code))
          );
          setSubCategoriesLoading(false);
        });
      });
  };

  const prepExistingEvent = (event: EventDto): void => {
    if (
      _.toLower(event.status) !== _.toLower(EventStatuses.PendingEmailReview)
    ) {
      setLocationTouched(true);
    }
    setEvent(event);
    setExistingCustomFieldValues(
      event.eventCustomFieldValues as EventCustomFieldValueDto[]
    );
    setEventLocked(event?.reviewCompleted || false);
    setFormStateFromProps(event);
    const lastAsset: any = _.last(event.entityAssets);
    getAssetLayerGeojson(lastAsset?.assetId);
  };

  const loadForm = (): void => {
    if (props.entityId > 0) {
      if (props.event) {
        prepExistingEvent(props.event);
      } else {
        getEvent(props.entityId).then((res) => {
          prepExistingEvent(res.data);
        });
      }
    } else {
      let newState = { ...initialForm };
      setFormState(newState);
      setInitialFormSet(true);
    }
  };

  const getAssetLayerGeojson = (assetId: number): void => {
    getAssetLayerById(assetId).then((res) => {
      if (!_.isEmpty(res.data.geojson)) {
        setSelectedAssetGeojson(JSON.parse(res.data.geojson));
      }
    });
  };

  const setStandardCustomFields = (): void => {
    let newCustomFields = [
      ...eventCustomFields.filter((x: any) => x.parentLookupId == null),
    ];
    setSelectedCustomFields(newCustomFields);
  };

  const setCategoryCustomFields = (value: string): void => {
    let newCustomFields = [
      ...eventCustomFields.filter((x: any) => x.parentLookupId == null),
    ];

    newCustomFields = [
      ...newCustomFields,
      ...eventCustomFields.filter(
        (x: any) => x.parentLookupId == value && x.childLookupId == null
      ),
    ];

    setSelectedCustomFields(newCustomFields);
  };

  const setSubCategoryCustomFields = (value: string): void => {
    const customFields = _.cloneDeep(eventCustomFields);
    let newCustomFields = [
      ...customFields.filter(
        (x: any) =>
          x.parentLookupId == null ||
          (x.parentLookupId == formState.eventCategoryId &&
            x.childLookupId == null)
      ),
    ];
    if (value) {
      newCustomFields = [
        ...newCustomFields,
        ...eventCustomFields.filter((x: any) => x.childLookupId == value),
      ];
    }
    setSelectedCustomFields(newCustomFields);
  };

  const handleChangeCommentableDescription = (e: any, p: any) => {
    const newState = { ...formState };
    _.set(newState, "description", e);
    _.set(newState, "plainTextDescription", p);
    setFormState(newState);
  };

  const handleInputChange = (e: React.ChangeEvent<any>): void => {
    let newState = { ...formState };
    const name = e.target.name;
    const value = eventCustomFields
      .filter((x: any) => x.fieldType == "Checkbox")
      .map((x: any) => `${x.fieldName}.booleanValue`)
      .includes(name)
      ? e.target.checked
      : name === "followUpRequired" ||
        name === "appliesToPermit" ||
        name === "isConfidential"
      ? e.target.checked
      : eventCustomFields
          .filter((x: any) => x.fieldType == "MultiSelect")
          .map((x: any) => `${x.fieldName}.textValue`)
          .includes(name)
      ? e.target.value.join("|")
      : e.target.value;

    if (name === "eventCategoryId") {
      if (_.isEmpty(value)) {
        setSelectedEventSubCategories([]);
      }

      if (value !== newState.eventCategoryId) {
        newState.eventSubCategoryId = "";
        newState.eventSubCategoryIdError = "";
        setSelectedEventSubCategories(
          eventSubCategories.filter((x: any) => x.parentLookupId == value)
        );
      }
    }

    if (name === "followUpRequired" && !value) {
      newState.followUpDate = "";
      newState.followUpDescription = "";
    }

    _.set(newState, name, value);
    setFormState(newState);
  };

  const handleCustomFieldChange = (e: React.ChangeEvent<any>): void => {
    let newState = { ...customFieldState };
    const name = e.target.name;
    const value = eventCustomFields
      .filter((x: any) => x.fieldType == "Checkbox")
      .map((x: any) => `${x.fieldName}.booleanValue`)
      .includes(name)
      ? e.target.checked
      : eventCustomFields
          .filter((x: any) => x.fieldType == "MultiSelect")
          .map((x: any) => `${x.fieldName}.textValue`)
          .includes(name)
      ? e.target.value.join("|")
      : e.target.value;

    _.set(newState, name, value);
    setCustomFieldState(newState);
  };

  const setFormStateFromProps = (event: EventDto): void => {
    const eventDate = event.eventDateTime
      ? moment(event.eventDateTime).format(
          organization.hasDateOnlyEventDate ? "YYYY-MM-DD" : "YYYY-MM-DD HH:mm"
        )
      : "";
    const selectedAssets: any[] = [];
    event.entityAssets?.forEach((pa: any) => {
      const selectedAsset = assetLookups.filter(
        (al: any) => al.id === pa.assetId
      )[0];
      if (selectedAsset) {
        selectedAssets.push(selectedAsset);
      }
    });

    const selectedPermits: any[] = [];
    event.entityPermits?.forEach((pa: any) => {
      const selectedPermit = permits.find((al: any) => al.id === pa.permitId);
      if (selectedPermit) {
        selectedPermits.push(selectedPermit);
      }
    });

    const selectedEntityUsers: any[] = [];
    event.entityUsers?.forEach((atu: any) => {
      const selectedAssignedToUser = users?.find(
        (au: any) => au.id === atu.userId
      );
      if (selectedAssignedToUser) {
        selectedEntityUsers.push(selectedAssignedToUser);
      }
    });

    const tempDescription =
      isOffline && event.description
        ? commentParseToText(event.description)
        : event.description;

    let newState: FormState = {
      eventDateTime: eventDate,
      eventCategoryId: event.eventCategoryId,
      eventSubCategoryId: event.eventSubCategoryId,
      description: tempDescription,
      latitude: event.latitude,
      longitude: event.longitude,
      urgency: event.urgency,
      followUpRequired: event.followUpRequired,
      followUpDate: event.followUpDate,
      followUpDescription: event.followUpDescription,
      isConfidential: event.isConfidential,
      entityUsers: selectedEntityUsers,
      assets: selectedAssets,
      permits: selectedPermits,
      appliesToPermit: _.isEmpty(selectedPermits) ? false : true,
      eventDueDateTime: dateUtil.convertDateOnlyToLocal(event.eventDueDateTime),
      eventCustomFieldValues: event.eventCustomFieldValues,
    };
    if (!_.isEmpty(event.entityPermits)) {
      newState.appliesToPermit = true;
    }
    setInitialFormState(_.cloneDeep(newState));
    setDefaultLatitude(event.latitude);
    setDefaultLongitude(event.longitude);
    setFormState(newState);
  };

  const checkFollowUpForNewEvent = (form: FormState): boolean => {
    if (
      form.eventDateTime !== "" &&
      form.followUpRequired &&
      form.followUpDate !== ""
    ) {
      return true;
    }
    return false;
  };

  const checkFollowUpForExistingEvent = (
    initialForm: FormState,
    newForm: EventDto
  ): boolean => {
    if (
      initialForm.followUpRequired === false &&
      newForm.followUpRequired !== false &&
      newForm.followUpDate !== "" &&
      newForm.eventDateTime !== ""
    ) {
      return true;
    }
    return false;
  };

  const handleSavePendingReview = (): void => {
    if (!validateDataForSave()) {
      return;
    }
    setReviewSaveWarning(true);
  };

  const saveForm = (): void => {
    if (!validateDataForSave()) {
      return;
    }

    let formToSave = validationService.unsetErrors(
      formState,
      "eventDateTimeError",
      "eventCategoryIdError",
      "eventSubCategoryIdError",
      "locationTypeIdError",
      "eventDueDateTimeError",
      "severityError"
    ) as EventDto;

    formToSave = validationService.unsetFields(
      formToSave,
      "eventSubCategoryIdError"
    );
    formToSave.eventDateTime =
      formToSave.eventDateTime === ""
        ? ""
        : moment(formToSave.eventDateTime).utc().format();

    let eventCustomFieldValues = transformCustomFieldState(
      eventCustomFields,
      customFieldState,
      formState.eventCategoryId,
      formState.eventSubCategoryId
    );

    formToSave = { ...formState };

    formToSave.eventContacts = eventContacts;
    formToSave.tagIds = eventTags.map(
      (x: EntityLookupDto) => x.lookupId
    ) as number[];
    formToSave.isOffline = isOffline ? true : false;
    const entityAssets: EntityAssetDto[] = [];
    const entityPermits: EntityPermitDto[] = [];
    const entityUsers: EntityUserDto[] = [];
    formState.assets.forEach((a: any) =>
      entityAssets.push({ assetId: a.id, eventId: event.id ?? 0 })
    );
    formState.permits.forEach((a: any) =>
      entityPermits.push({ permitId: a.id, eventId: event.id ?? 0 })
    );
    formState.entityUsers.forEach((atu: any) =>
      entityUsers.push({ userId: atu.id, eventId: event.id ?? 0 })
    );
    formToSave.entityAssets = entityAssets;
    formToSave.entityPermits = entityPermits;
    formToSave.entityUsers = entityUsers;
    if (parentEventId > 0) {
      formToSave.parentEventId = parentEventId;
    }

    setIsUploading(true);
    formToSave.eventCustomFieldValues = eventCustomFieldValues;
    if (props.entityId <= 0) {
      const form = new FormData();
      const attachmentsToUpload: any[] = [];
      if (!_.isEmpty(uploadedFiles)) {
        for (let index = 0; index < uploadedFiles.length; index++) {
          const file = uploadedFiles[index];
          attachmentsToUpload.push({
            name: file.name,
            blob: file.slice(0, file.size, file.type),
          });
          const element = uploadedFiles[index];
          form.append("file", element);
        }
      }

      const dataString = JSON.stringify(formToSave);
      form.append("data", dataString);
      const guid = createGuid();
      const hasFollowUpEvent = checkFollowUpForNewEvent(formToSave);

      createEventWithAttachments(form)
        .then((res) => {
          showAlert("success", "Event created.");
          if (props.isForEventDetails) {
            props.handleModalClose(
              true,
              false,
              res.data,
              !_.isEmpty(props.defaultValues) ? true : false
            );
          } else {
            handleNavigateTo(
              null,
              `/events/${res.data.id}${
                hasFollowUpEvent ? "?hasFollowUp=true" : ""
              }`
            );
          }
        })
        .catch((err) => {
          if (err.response) {
            showAlert("error", err.response.data.message);
            setIsUploading(false);
          } else {
            localforage.getItem(eventStore).then((res: any) => {
              const values = [...res];
              values.push({
                id: guid,
                ...formToSave,
                notSynced: true,
                assetNames: formToSave.entityAssets?.map(
                  (x: EntityAssetDto) => x.assetName
                ),
                eventCategory: eventCategories.find(
                  (x: any) => x.id == formToSave.eventCategoryId
                )?.code,
              });
              localforage.setItem(eventStore, values);
            });

            localforage
              .getItem(eventSyncStore)
              .then((res: any) => {
                let data: any[] = [];
                if (res !== null && res !== undefined) {
                  data = [...res];
                }

                data.push({
                  id: guid,
                  type: "event",
                  form: { ...formToSave },
                  attachments: [...attachmentsToUpload],
                });
                localforage
                  .setItem(eventSyncStore, data)
                  .then(() => {
                    showAlert("success", "Event saved for syncing.");
                    handleNavigateTo(null, "/events");
                  })
                  .catch((err) => {
                    showAlert(
                      "error",
                      "Unable to create event. Please ensure you are not in private browsing mode."
                    );
                    handleNavigateTo(null, "/events");
                  });
              })
              .catch((err) => {
                showAlert(
                  "error",
                  "Unable to create event. Please ensure you are not in private browsing mode."
                );
                handleNavigateTo(null, "/events");
              });
          }
        });
    }
    if (props.entityId > 0) {
      const hasFollowUpEvent = checkFollowUpForExistingEvent(
        initialFormState,
        formToSave
      );

      setIsLoading(true);
      patchEvent(props.entityId, formToSave)
        .then((result) => {
          if (!_.isEmpty(uploadedFiles)) {
            addEntityAttachments("event", props.entityId, uploadedFiles)
              .then((res) => {})
              .catch(() => {
                showAlert("error", "Error uploading attachments.");
                props.handleModalClose(true, false, result.data);
              });

            showAlert(
              "success",
              "Event Saved. Attachments will be available once upload is complete."
            );
            props.handleModalClose(
              true,
              false,
              result.data,
              null,
              hasFollowUpEvent
            );
          } else {
            showAlert("success", "Event saved.");
            props.handleModalClose(
              true,
              false,
              result.data,
              null,
              hasFollowUpEvent
            );
          }
        })
        .catch((err) => {
          showAlert("error", err.response.data.message);
          props.handleModalClose(false);
        });
    }
  };

  const deleteItem = (): void => {
    deleteEvent(props.entityId).then(() => {
      showAlert("success", "Event deleted.");
      handleNavigateTo(null, "/events");
    });
  };

  const handleDelete = (): void => {
    setShowDelete(true);
  };

  useEffect(() => {
    if (!_.isEmpty(assetLookups) && !_.isEmpty(formState.assets)) {
      const lastAsset: any = _.last(formState.assets);
      const assetId = lastAsset?.id;
      const asset = assetLookups.find((x: any) => x.id == assetId);
      if (asset) {
        if (!locationTouched) {
          if (asset.centroidLatitude && asset.centroidLongitude) {
            setDefaultLatitude(asset.centroidLatitude);
            setDefaultLongitude(asset.centroidLongitude);
          }
        }

        getAssetLayerGeojson(asset.id as number);
      }
    }
  }, [assetLookups, formState.assets]);

  const validateDataForSave = (): boolean => {
    let newState = { ...formState };
    let newCustomFieldState = { ...customFieldState };
    let isFormValid = false;
    if (formState)
      if (isScheduledEvent()) {
        validationService.validateRequiredField(
          newState,
          "eventDueDateTime",
          "eventDueDateTimeError",
          "Due Date"
        );
      }
    if (props.entityId <= 0 && !isScheduledEvent()) {
      validationService.validateRequiredField(
        newState,
        "eventDateTime",
        "eventDateTimeError",
        "Completed Date"
      );
    }
    if (formState.appliesToPermit) {
      validationService.validateRequiredField(
        newState,
        "permits",
        "permitsError",
        "Associated Permits"
      );
    }
    validationService.validateRequiredField(
      newState,
      "eventCategoryId",
      "eventCategoryIdError",
      "Event Category"
    );
    if (isSubCatRequired()) {
      validationService.validateRequiredField(
        newState,
        "eventSubCategoryId",
        "eventSubCategoryIdError",
        "Subcategory"
      );
    }

    validationService.validateRequiredField(
      newState,
      "assets",
      "assetsError",
      "Associated Assets"
    );
    if (!isScheduledEvent() && formState.eventDateTime !== "") {
      eventCustomFields
        .filter(
          (x: EventCustomFieldDto) =>
            x.isRequired &&
            !x.isDeleted &&
            (_.toLower(x.fieldType) === "dropdown" ||
              _.toLower(x.fieldType) === "text" ||
              _.toLower(x.fieldType) === "textarea" ||
              _.toLower(x.fieldType) === "multiselect")
        )
        .forEach((value: EventCustomFieldDto) => {
          validationService.validateRequiredField(
            newCustomFieldState,
            `${value.fieldName}.textValue`,
            `${value.fieldName}Error`,
            `${value.fieldLabel}`
          );
        });
      eventCustomFields
        .filter(
          (x: EventCustomFieldDto) =>
            x.isRequired &&
            !x.isDeleted &&
            _.toLower(x.fieldType) === "datetime"
        )
        .forEach((value: EventCustomFieldDto) => {
          validationService.validateRequiredField(
            newCustomFieldState,
            `${value.fieldName}.dateTimeValue`,
            `${value.fieldName}Error`,
            `${value.fieldLabel}`
          );
        });
      eventCustomFields
        .filter(
          (x: EventCustomFieldDto) =>
            x.isRequired && !x.isDeleted && _.toLower(x.fieldType) === "date"
        )
        .forEach((value: EventCustomFieldDto) => {
          validationService.validateRequiredField(
            newCustomFieldState,
            `${value.fieldName}.dateValue`,
            `${value.fieldName}Error`,
            `${value.fieldLabel}`
          );
        });
    }

    eventCustomFields
      .filter((x: EventCustomFieldDto) => _.toLower(x.fieldType) === "number")
      .forEach((value: EventCustomFieldDto) => {
        validationService.validateNumberField(
          newCustomFieldState,
          `${value.fieldName}.numericValue`,
          `${value.fieldName}Error`,
          !isScheduledEvent() && value.isRequired,
          `${value.fieldLabel}`
        );
      });

    const customFieldErrorsToUnset = selectedCustomFields
      .filter((x: EventCustomFieldDto) =>
        !isScheduledEvent() && formState.eventDateTime !== ""
          ? x.isRequired || _.toLower(x.fieldType) === "number"
          : _.toLower(x.fieldType) === "number"
      )
      .map((field: EventCustomFieldDto) => `${field.fieldName}Error`);
    isFormValid =
      !validationService.hasError(
        newState,
        isScheduledEvent()
          ? "eventDueDateTimeError"
          : props.entityId <= 0
          ? "eventDateTimeError"
          : "",
        "assetsError",
        formState.appliesToPermit ? "permitsError" : "",
        "eventCategoryIdError",
        "eventSubCategoryIdError",
        "locationTypeIdError"
      ) &&
      !validationService.hasError(
        newCustomFieldState,
        ...customFieldErrorsToUnset
      );

    if (props.entityId > 0) {
      let dueDateValid = true;
      let completedDateValid = true;
      if (newState.eventDateTime === "") {
        dueDateValid = validationService.validateRequiredField(
          newState,
          "eventDueDateTime",
          "eventDueDateTimeError",
          "Due Date or Completed Date"
        );
      }
      if (newState.eventDueDateTime === "") {
        completedDateValid = validationService.validateRequiredField(
          newState,
          "eventDateTime",
          "eventDateTimeError",
          "Completed Date or Due Date"
        );
      }

      if (!dueDateValid && !completedDateValid) {
        isFormValid = false;
      }
    }

    if (!isFormValid) {
      setFormState(newState);
      setCustomFieldState(newCustomFieldState);
      showAlert("error", "Form is not valid for saving.");
    }
    return isFormValid;
  };

  const handleLatLongChange = (lat: string, long: string): void => {
    setFormState((existingState: FormState) => {
      return { ...existingState, latitude: lat, longitude: long };
    });
  };

  const isSubCatRequired = (): boolean => {
    if (!_.isEmpty(eventCategories) && formState.eventCategoryId) {
      const cat = eventCategories.find(
        (x: any) => x.id == formState.eventCategoryId
      );
      if (cat) {
        return cat.requireChildSelection as boolean;
      }
    }
    return false;
  };

  const getFormRow = (): JSX.Element => {
    return (
      <Grid.Row>
        <Container className="form-container" maxWidth="xl">
          <Dimmer active={isLoading} loader>
            <Form className="card">
              <Card.Body className="pt-0 pb-0 pl-0 pr-0">
                <Grid.Row>
                  {!isLoading && (
                    <>
                      {event.status === EventStatuses.PendingEmailReview && (
                        <Accordion
                          expanded={corLogExpanded}
                          onChange={() => setCorLogExpanded(!corLogExpanded)}
                          className="w-100"
                        >
                          <AccordionSummary
                            aria-controls="cor-log-content"
                            id="cor-log-header"
                          >
                            <Typography>
                              Email Review + Correspondence Log
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid.Row>
                              <Grid.Col md={12} width={12}>
                                <Typography>
                                  This event was created by a forwarded email.
                                  Review the email and correspondence log below
                                  and fill out the event form to create the
                                  correspondence as a new event. If this
                                  correspondence belongs to an existing
                                  event,&nbsp;
                                  <Link
                                    underline="hover"
                                    href="#"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setShowMergeEvent(true);
                                    }}
                                  >
                                    click here
                                  </Link>
                                  &nbsp;to merge the correspondence to the
                                  correct event.
                                </Typography>
                              </Grid.Col>
                              <Grid.Col md={12} width={12} className="mt-2">
                                <EventDetailsCorrespondenceLog
                                  isEventForm
                                  noHeader
                                  event={event}
                                />
                              </Grid.Col>
                            </Grid.Row>
                          </AccordionDetails>
                        </Accordion>
                      )}

                      <Accordion
                        expanded={detailsExpanded}
                        onChange={() => setDetailsExpanded(!detailsExpanded)}
                        className="w-100"
                      >
                        <AccordionSummary
                          aria-controls="details-content"
                          id="details-header"
                        >
                          <Typography>Details</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid.Row>
                            <Grid.Col md={12} width={12}>
                              {!_.isEmpty(assetLookups) && (
                                <Form.Group
                                  isRequired
                                  label="Associated Assets"
                                >
                                  <MultiSelect
                                    name="assets"
                                    onChange={handleInputChange}
                                    value={formState.assets}
                                    dropdownValues={assetLookups}
                                    key="assetId"
                                    label="name"
                                    id="assetId"
                                    error={formState.assetsError}
                                  />
                                </Form.Group>
                              )}
                            </Grid.Col>
                            {organization.hasPermitFeature && (
                              <Grid.Col md={12} width={12}>
                                <Form.Group className="" label="">
                                  <Form.Checkbox
                                    label="Applies to Permit?"
                                    name="appliesToPermit"
                                    checked={formState.appliesToPermit}
                                    onChange={handleInputChange}
                                    disabled={eventLocked}
                                  />
                                </Form.Group>
                              </Grid.Col>
                            )}
                            <Grid.Col md={12} width={12}>
                              {formState.appliesToPermit &&
                                organization.hasPermitFeature && (
                                  <Form.Group
                                    isRequired
                                    label="Associated Permits"
                                  >
                                    <MultiSelect
                                      name="permits"
                                      onChange={handleInputChange}
                                      value={formState.permits}
                                      dropdownValues={permits}
                                      key="id"
                                      label={
                                        organization.eventPermitLabelFunction
                                          ? null
                                          : "permitNumber"
                                      }
                                      labelFunction={
                                        organization.eventPermitLabelFunction
                                          ? (value: any) => {
                                              return eval(
                                                organization.eventPermitLabelFunction as string
                                              );
                                            }
                                          : null
                                      }
                                      id="permitId"
                                      error={formState.permitsError}
                                    />
                                  </Form.Group>
                                )}
                            </Grid.Col>
                            <Grid.Col md={6} width={12}>
                              <Form.Group isRequired label="Category">
                                <Form.Select
                                  name="eventCategoryId"
                                  value={formState.eventCategoryId}
                                  onChange={handleInputChange}
                                  error={formState.eventCategoryIdError}
                                  disabled={eventLocked}
                                >
                                  <option value={""}></option>
                                  {eventCategories.map((type: LookupDto) => (
                                    <option value={type.id} key={type.id}>
                                      {type.code}
                                    </option>
                                  ))}
                                </Form.Select>
                              </Form.Group>
                            </Grid.Col>
                            <Grid.Col md={6} width={12}>
                              <Form.Group
                                label="Subcategory"
                                isRequired={isSubCatRequired()}
                              >
                                <Form.Select
                                  name="eventSubCategoryId"
                                  onChange={handleInputChange}
                                  value={formState.eventSubCategoryId}
                                  error={formState.eventSubCategoryIdError}
                                  disabled={
                                    eventLocked ||
                                    formState.eventCategoryId === ""
                                  }
                                >
                                  <option value={""}></option>
                                  {selectedEventSubCategories.map(
                                    (type: LookupDto) => (
                                      <option value={type.id} key={type.id}>
                                        {type.code}
                                      </option>
                                    )
                                  )}
                                </Form.Select>
                              </Form.Group>
                            </Grid.Col>

                            <Grid.Col md={6} width={12}>
                              <Form.Group
                                label="Due Date"
                                isRequired={isScheduledEvent()}
                              >
                                <Form.Input
                                  type="date"
                                  value={formState.eventDueDateTime}
                                  name="eventDueDateTime"
                                  onChange={handleInputChange}
                                  disabled={eventLocked}
                                  error={formState.eventDueDateTimeError}
                                />
                              </Form.Group>
                            </Grid.Col>
                            <Grid.Col md={6} width={12}>
                              <Form.Group
                                isRequired={
                                  !isScheduledEvent() && props.entityId <= 0
                                }
                                label="Event Date / Completed Date"
                              >
                                <Form.Input
                                  type={
                                    organization.hasDateOnlyEventDate
                                      ? "date"
                                      : "datetime-local"
                                  }
                                  value={formState.eventDateTime}
                                  name="eventDateTime"
                                  onChange={handleInputChange}
                                  error={formState.eventDateTimeError}
                                  disabled={eventLocked}
                                />
                              </Form.Group>
                            </Grid.Col>
                            {roleMatch([
                              UserRoles.EventsAdministrator,
                              UserRoles.EventsEditor,
                            ]) && (
                              <Grid.Col md={6} width={12}>
                                <Form.Group label="Assigned To">
                                  <MultiSelect
                                    name="entityUsers"
                                    id="entityUsers"
                                    onChange={handleInputChange}
                                    value={formState.entityUsers}
                                    dropdownValues={users}
                                    key="id"
                                    label="fullName"
                                  />
                                </Form.Group>
                              </Grid.Col>
                            )}

                            <Grid.Col md={6} width={12}>
                              <Form.Group label="Urgency">
                                <Form.Select
                                  name="urgency"
                                  onChange={handleInputChange}
                                  value={formState.urgency}
                                  error={formState.urgencyError}
                                  disabled={eventLocked}
                                >
                                  <option value={""}></option>
                                  {urgencyList.map((type: any) => (
                                    <option value={type.value} key={type.value}>
                                      {type.value}
                                    </option>
                                  ))}
                                </Form.Select>
                              </Form.Group>
                            </Grid.Col>

                            <Grid.Col md={12} width={12}>
                              <Form.Group label="Description">
                                {isOffline ? (
                                  <LynxTextArea
                                    name="description"
                                    onChange={handleInputChange}
                                    value={formState.description}
                                    disabled={eventLocked}
                                  ></LynxTextArea>
                                ) : (
                                  <>
                                    <CommentsInput
                                      isDisabled={eventLocked}
                                      value={formState.description}
                                      users={taggableUsers || []}
                                      onChange={(
                                        e: any,
                                        n: string,
                                        p: string
                                      ) => {
                                        handleChangeCommentableDescription(
                                          n,
                                          p
                                        );
                                      }}
                                    />
                                    <Typography variant="caption">
                                      Type "@" to tag users or "#" to tag
                                      contacts or contact groups
                                    </Typography>
                                  </>
                                )}
                              </Form.Group>
                            </Grid.Col>
                            {account.hasEventConfidentialAccess && (
                              <Grid.Col md={12} width={12}>
                                <Form.Group label="">
                                  <Form.Checkbox
                                    label="Confidential?"
                                    name="isConfidential"
                                    checked={formState.isConfidential}
                                    onChange={handleInputChange}
                                  />
                                </Form.Group>
                              </Grid.Col>
                            )}

                            {_.isEmpty(props.defaultValues) && (
                              <>
                                {" "}
                                <Grid.Col md={6} width={12}>
                                  <Form.Group label="">
                                    <Form.Checkbox
                                      label="Follow up required?"
                                      name="followUpRequired"
                                      checked={formState.followUpRequired}
                                      onChange={handleInputChange}
                                      disabled={eventLocked}
                                    />
                                  </Form.Group>
                                </Grid.Col>
                                {formState.followUpRequired && (
                                  <>
                                    <Grid.Col md={6} width={12}>
                                      <Form.Group label="Follow Up Date">
                                        <Form.Input
                                          type="date"
                                          value={
                                            formState.followUpDate
                                              ? moment
                                                  .utc(formState.followUpDate)
                                                  .format("YYYY-MM-DD")
                                              : ""
                                          }
                                          name="followUpDate"
                                          onChange={handleInputChange}
                                          disabled={eventLocked}
                                        />
                                      </Form.Group>
                                    </Grid.Col>
                                    <Grid.Col md={12} width={12}>
                                      <Form.Group label="Follow Up Description">
                                        <LynxTextArea
                                          name="followUpDescription"
                                          onChange={handleInputChange}
                                          value={formState.followUpDescription}
                                          disabled={eventLocked}
                                        ></LynxTextArea>
                                      </Form.Group>
                                    </Grid.Col>
                                  </>
                                )}
                              </>
                            )}
                          </Grid.Row>
                        </AccordionDetails>
                      </Accordion>
                      {!_.isEmpty(selectedCustomFields) && (
                        <>
                          <Accordion
                            expanded={customFieldsExpanded}
                            onChange={() =>
                              setCustomFieldsExpanded(!customFieldsExpanded)
                            }
                            className="w-100"
                          >
                            <AccordionSummary
                              aria-controls="custom-fields-content"
                              id="custom-fields-header"
                            >
                              <Typography>Additional Fields</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Grid.Row>
                                {selectedCustomFields
                                  .sort((a: any, b: any) =>
                                    a.position > b.position ? 1 : -1
                                  )
                                  .filter((x: any) => !x.isDeleted)
                                  .map((field: any) => (
                                    <CustomField
                                      customField={field}
                                      key={field.position}
                                      isRequired={
                                        field.isRequired &&
                                        !isScheduledEvent() &&
                                        formState.eventDateTime !== ""
                                      }
                                      handleInputChange={
                                        handleCustomFieldChange
                                      }
                                      formState={customFieldState}
                                      disabled={eventLocked}
                                    ></CustomField>
                                  ))}
                              </Grid.Row>
                            </AccordionDetails>
                          </Accordion>
                        </>
                      )}
                    </>
                  )}
                  {props.entityId <= 0 && !isOffline && (
                    <>
                      <Accordion className="w-100">
                        <AccordionSummary
                          aria-controls="tags-content"
                          id="tags-header"
                        >
                          <Typography>Tags</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Tags
                            entityType={EntityTypes.Event}
                            entityId={""}
                            handleSetTags={(e: LookupDto[]) => {
                              setEventTags(e);
                            }}
                          />
                        </AccordionDetails>
                      </Accordion>
                    </>
                  )}

                  {props.entityId <= 0 && !isOffline && (
                    <>
                      <Accordion className="w-100">
                        <AccordionSummary
                          aria-controls="contacts-content"
                          id="contacts-header"
                        >
                          <Typography>Contacts</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Contacts
                            entityType={EntityTypes.Event}
                            entityId={undefined}
                            handleSetContacts={(e: EventContactDto[]) => {
                              setEventContacts(e);
                            }}
                          />
                        </AccordionDetails>
                      </Accordion>
                    </>
                  )}
                  <>
                    {(props.entityId <= 0 ||
                      event.pendingEmailIntegrationReview) && (
                      <Accordion
                        className="w-100"
                        defaultExpanded={event.pendingEmailIntegrationReview}
                      >
                        <AccordionSummary
                          aria-controls="attachments-content"
                          id="attachments-header"
                        >
                          <Typography>Attachments</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <AttachmentViewer
                            isExistingEntity={props.entityId > 0}
                            entityType={"Event"}
                            entityId={event.id}
                            cardClass="mb-0"
                            handleSetUploadedFiles={(files: File[]) => {
                              setUploadedFiles(files);
                            }}
                            rowHeight={200}
                            cols={6}
                          />
                        </AccordionDetails>
                      </Accordion>
                    )}
                  </>
                  <Accordion
                    className="w-100"
                    expanded={locationExpanded}
                    onChange={() => setLocationExpanded(!locationExpanded)}
                  >
                    <AccordionSummary
                      aria-controls="location-content"
                      id="location-header"
                    >
                      <Typography>Location</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid.Row>
                        <LocationSelectionControl
                          defaultLatitude={defaultLatitude}
                          defaultLongitude={defaultLongitude}
                          isRequired
                          handleLatLongChange={handleLatLongChange}
                          handleLocationTouched={() => setLocationTouched(true)}
                          supportingGeojson={selectedAssetGeojson}
                          entityType={EntityTypes.Event}
                        />
                      </Grid.Row>
                    </AccordionDetails>
                  </Accordion>
                </Grid.Row>
              </Card.Body>
              <Card.Footer>
                {(!props.entityId || (props.entityId <= 0 && !isLoading)) && (
                  <Button
                    variant="contained"
                    className="float-right"
                    onClick={saveForm}
                  >
                    {isScheduledEvent() ? "Schedule Event" : "Add Event"}
                  </Button>
                )}
                {props.entityId > 0 && !isLoading && (
                  <div>
                    {(roleMatch([UserRoles.EventsAdministrator]) ||
                      event.status === EventStatuses.PendingEmailReview) && (
                      <Button
                        variant="contained"
                        color="error"
                        className="float-left"
                        onClick={handleDelete}
                      >
                        Delete Event
                      </Button>
                    )}

                    <Button
                      variant="contained"
                      className="float-right"
                      onClick={
                        event.status === EventStatuses.PendingEmailReview
                          ? handleSavePendingReview
                          : saveForm
                      }
                    >
                      Save Event
                    </Button>
                  </div>
                )}
              </Card.Footer>
              {showDelete && (
                <LynxDialog
                  open={showDelete}
                  handleClose={() => setShowDelete(false)}
                  handleDelete={deleteItem}
                  title={`Delete Event?`}
                  description={"All attachments will also be deleted."}
                />
              )}
              {showFileSizeError && (
                <LynxDialog
                  open={showFileSizeError}
                  handleClose={() => setShowFileSizeError(false)}
                  title={`File size exceeded`}
                  description={
                    "Attachments are over the required total size limit of 50MB."
                  }
                  handleConfirm={() => setShowFileSizeError(false)}
                />
              )}
              {isUploading && (
                <LynxDialog
                  open={isUploading}
                  title={`Saving event. Do not close the window.`}
                  dialogContent={
                    <div className="d-flex align-items-center justify-content-center mt-4">
                      <CircularProgress />
                    </div>
                  }
                />
              )}
              {showMergeEvent && (
                <LynxDialog
                  open={showMergeEvent}
                  maxWidth="lg"
                  fullWidth
                  title={`Merge Correspondence`}
                  handleClose={() => setShowMergeEvent(false)}
                  dialogContent={<EventCorrespondenceMerge event={event} />}
                />
              )}

              {reviewSaveWarning && (
                <LynxDialog
                  open={reviewSaveWarning}
                  title={`Save Event?`}
                  handleSave={() => {
                    saveForm();
                  }}
                  handleClose={() => {
                    setReviewSaveWarning(false);
                  }}
                  dialogContent={
                    <>
                      <div>
                        Asset(s):
                        {formState.assets
                          .map((a: AssetDto) => a.name)
                          .join(", ")}
                      </div>
                      <div>
                        Category:{" "}
                        {formState.eventCategoryId &&
                          formState.eventCategoryId > 0 &&
                          eventCategories.find(
                            (x: any) => x.id == formState.eventCategoryId
                          )?.code}
                      </div>
                    </>
                  }
                />
              )}
              {showAddContact && (
                <LynxDialog
                  dividers
                  hasNoActions
                  isCloseInHeader
                  open={showAddContact}
                  handleClose={() => setShowAddContact(false)}
                  title={`Add Contact`}
                  maxWidth="md"
                  dialogContent={
                    <ContactForm
                      modalMode
                      handleSave={(contact: any) => {
                        setShowAddContact(false);
                        setAllContacts([...allContacts, contact]);
                      }}
                    />
                  }
                />
              )}
            </Form>
          </Dimmer>
        </Container>
      </Grid.Row>
    );
  };

  if (props.isForEventDetails) {
    return getFormRow();
  } else
    return (
      <Grid>
        <Paper>
          <Grid.Row className="ml-0 mr-0">
            <Grid.Col lg={12} width={12}>
              <Stack direction="row" alignItems="center">
                <Typography
                  mr={2}
                  variant="h3"
                  component="div"
                  className="form-header-text"
                >
                  {isScheduledEvent() ? "Schedule Event" : "Add Event"}
                </Typography>
                {isScheduledEvent() && (
                  <Button
                    sx={{ mt: 1 }}
                    startIcon={<EventIcon />}
                    onClick={() => setSchedulerOpen(true)}
                    variant="outlined"
                  >
                    Bulk Scheduler
                  </Button>
                )}
              </Stack>
            </Grid.Col>
            <Grid.Col lg={12} width={12}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  underline="hover"
                  color="inherit"
                  href="#"
                  onClick={(e) => handleNavigateTo(e, "/events")}
                >
                  Events
                </Link>
                <Typography color="text.primary">
                  {isScheduledEvent() ? "Schedule Event" : "Add Event"}
                </Typography>
              </Breadcrumbs>
            </Grid.Col>
          </Grid.Row>
        </Paper>
        {getFormRow()}

        <LynxDialog
          title="Bulk Schedule Events"
          fullWidth
          open={schedulerOpen}
          handleClose={() => setSchedulerOpen(false)}
          dialogContent={<EventScheduler />}
          isCloseInHeader
        />
      </Grid>
    );
};

export default EventForm;
