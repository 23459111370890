import {
  Chip,
  chipClasses,
  IconButton,
  Popover,
  Stack,
  Tooltip,
} from "@mui/material";
import { LynxDataGrid } from "components/data-grid/lynx-data-grid";
import { useHistory } from "react-router-dom";
import { EntityLookupDto, LynxGridColDef, RootState } from "types";
import useAlert from "hooks/useAlert";
import CircularProgress from "@mui/material/CircularProgress";
import EditIcon from "@mui/icons-material/Edit";
import { downloadAttachment } from "services/attachments";
import { dateUtil } from "services/date-util";
import { useEffect, useState } from "react";
import TagQuickCard from "../events/tag-quick-card";
import saveAs from "file-saver";
import { Download } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { useGetDocumentsQuery } from "services/rtkApi/endpoints/documents";
import { EntityTypes } from "types/enums-ts";
import { LynxDialog } from "components/lynx-dialog";

const DocumentList = () => {
  const { showAlert } = useAlert();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedTag, setSelectedTag] = useState<EntityLookupDto | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const organization = useSelector((state: RootState) => state.organization);
  const history = useHistory();
  const [finalColumn, setFinalColumn] = useState<any[]>([]);
  const [showDownloading, setShowDownloading] = useState(false);
  const handleTagClick = (
    event: React.MouseEvent<HTMLElement>,
    tag: EntityLookupDto
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedTag(tag);
  };

  const handleDownloadFile = (id: number, fileName: string) => {
    setShowDownloading(true);
    downloadAttachment(id)
      .then((res) => {
        saveAs(res.data, fileName);
      })
      .catch(async (err: any) => {
        var errorString = JSON.parse(await err.response.data.text());
        showAlert("error", errorString);
      })
      .finally(() => {
        setShowDownloading(false);
      });
  };

  const columns: LynxGridColDef[] = [
    {
      field: "actions",
      headerName: ``,
      width: 100,
      type: "string",
      sortable: false,
      filterable: false,
      renderCell: (params: any) => {
        return (
          <Stack direction="row">
            <Tooltip title="Edit">
              <IconButton
                onClick={() =>
                  history.push(`/documents/${params.row.entityAttachmentId}`)
                }
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Download">
              <IconButton
                onClick={() =>
                  handleDownloadFile(
                    params.row.entityAttachmentId,
                    params.row.fileName
                  )
                }
              >
                <Download color="primary" />
              </IconButton>
            </Tooltip>
          </Stack>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      width: 300,
      type: "string",
    },
    {
      field: "description",
      headerName: "Description",
      width: 300,
      type: "string",
    },
    {
      field: "fileName",
      headerName: "File Name",
      width: 300,
      type: "string",
    },

    {
      field: "createdByUserFullName",
      headerName: "Uploaded By",
      width: 200,
      type: "string",
    },
    {
      field: "createdDateTimeUtc",
      headerName: "Uploaded Date Time",
      width: 200,
      type: "dateTime",
      valueGetter: (value: any) => {
        return dateUtil.convertColumnDateTimeToLocal(value);
      },
    },
    {
      field: "indexInAI",
      headerName: "Index in AI",
      width: 100,
      type: "boolean",
      sortable: false,
    },
    {
      field: "indexedDateTimeUtc",
      headerName: "Indexed Date Time",
      width: 200,
      type: "dateTime",
      valueGetter: (value: any) => {
        return dateUtil.convertColumnDateTimeToLocal(value);
      },
    },
    {
      field: "tags",
      headerName: "Tags",
      width: 300,
      type: "string",
      sortable: false,
      filterable: false,
      renderCell: (params: any) => {
        let element = <></>;
        if (params.value) {
          element = (
            <>
              {params.value.map((tag: EntityLookupDto, index: number) => (
                <Chip
                  onClick={(e) => handleTagClick(e, tag)}
                  label={tag.lookupCode}
                  variant="outlined"
                  sx={{
                    [`& .${chipClasses.icon}`]: {
                      color: "rgba(0, 0, 0, 0.26)",
                    },
                    mr: 1,
                  }}
                  key={`${index}_${tag}`}
                />
              ))}
            </>
          );
        }
        return element;
      },
    },
  ];

  useEffect(() => {
    const cols = organization.featureFlags?.showInDevelopmentFeatures
      ? columns
      : columns.filter(
          (f) => f.field !== "indexInAI" && f.field !== "indexedDateTimeUtc"
        );
    setFinalColumn(cols);
  }, [columns]);

  return (
    <>
      <LynxDataGrid
        columns={finalColumn}
        useQuery={useGetDocumentsQuery}
        addButtonAction={() => history.push("/upload-documents")}
        entityType={EntityTypes.Document}
        getRowId="entityAttachmentId"
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        disableScrollLock
        onClose={() => setAnchorEl(null)}
      >
        {selectedTag && <TagQuickCard id={selectedTag.lookupId} />}
      </Popover>
      <LynxDialog
        open={showDownloading}
        title={`Downloading attachment.`}
        description={
          <>
            <div className="d-flex align-items-center justify-content-center mt-4">
              <CircularProgress />
            </div>
          </>
        }
      />
    </>
  );
};

export default DocumentList;
