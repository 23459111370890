import * as React from "react";
import _ from "lodash";
import lynxColors from "../../../modules/lynxColors";
import { dateUtil } from "../../../services/date-util";
import { ActionStatuses, LookupTypes } from "../../../types/enums";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import {
  multipleSelectContainsOperator,
  multipleSelectDoesNotContainOperator,
} from "components/data-grid/multiple-select-operator";
import { LynxGridColDef } from "types";
import { useGetLookupsQuery } from "services/rtkApi/endpoints/lookups";
import { useGetAssetsLookupsQuery } from "services/rtkApi/endpoints/assets";
export const getActionColumns = (
  predefinedLookups: any = null
): LynxGridColDef[] => {
  return [
    {
      field: "actionNumber",
      headerName: "Action #",
      width: 100,
      type: "string",
    },
    {
      field: "sourceNumber",
      headerName: "Source",
      width: 100,
      type: "string",
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      type: "singleSelect",
      valueOptions: [
        ActionStatuses.PendingApproval,
        ActionStatuses.Approved,
        ActionStatuses.Overdue,
        ActionStatuses.Complete,
        ActionStatuses.Closed,
      ],
      renderCell: (params: GridRenderCellParams<any, string>) => (
        <div>
          <i
            className={"fe fe-circle "}
            style={
              params.value ? { color: statusColorSwitch(params.value) } : {}
            }
          />
          &nbsp;
          {params.value}
        </div>
      ),
    },
    {
      field: "title",
      headerName: "Action",
      width: 300,
      type: "string",
    },
    {
      field: "priority",
      headerName: "Priority",
      width: 75,
      type: "singleSelect",
      valueOptions: ["P1", "P2", "P3", "P4", "P5"],
    },

    {
      field: "assignedToUserFullName",
      headerName: "Assigned To",
      width: 150,
      type: "singleSelect",
      valueOptions: predefinedLookups.users,
      getOptionValue: (value: any) => (!value ? "" : value.fullName),
      getOptionLabel: (value: any) => (!value ? "" : value.fullName),
    },
    {
      field: "dueDate",
      headerName: "Due Date",
      width: 110,
      type: "date",
      valueGetter: (value) => {
        return dateUtil.convertColumnDateOnlyToLocal(value);
      },
    },

    {
      field: "description",
      headerName: "Description",
      width: 300,
      type: "string",
    },
    {
      field: "category",
      headerName: "Category",
      width: 150,
      type: "singleSelect",
      query: useGetLookupsQuery,
      queryParams: { lookupType: LookupTypes.ActionCategory },
      getOptionValue: (value: any) => (!value ? "" : value.code),
      getOptionLabel: (value: any) => (!value ? "" : value.code),
    },

    {
      field: "evidenceRequired",
      headerName: "Evidence Required",
      width: 150,
      type: "singleSelect",
      query: useGetLookupsQuery,
      queryParams: { lookupType: LookupTypes.ActionEvidenceRequired },
      getOptionValue: (value: any) => (!value ? "" : value.code),
      getOptionLabel: (value: any) => (!value ? "" : value.code),
    },
    {
      field: "closedDateTime",
      headerName: "Closed Date",
      width: 200,
      type: "dateTime",
      valueGetter: (value) => {
        return dateUtil.convertColumnDateTimeToLocal(value);
      },
    },

    {
      field: "assetName",
      headerName: "Asset Name",
      width: 150,
      type: "singleSelect",
      query: useGetAssetsLookupsQuery,
      getOptionValue: (value: any) => (!value ? "" : value.name),
      getOptionLabel: (value: any) => (!value ? "" : value.name),
    },
    {
      field: "dueInDays",
      headerName: "Due In Days",
      width: 150,
      type: "number",
    },

    {
      field: "assetNames",
      headerName: "Associated Assets",
      width: 150,
      type: "singleSelect",
      query: useGetAssetsLookupsQuery,
      getOptionValue: (value: any) => (!value ? "" : value.name),
      getOptionLabel: (value: any) => (!value ? "" : value.name),
      sortable: false,
      filterOperators: [
        ...multipleSelectContainsOperator,
        ...multipleSelectDoesNotContainOperator,
      ],
      renderCell: (params: any) => <div>{params.value}</div>,
    },
  ];
};

export const statusColorSwitch = (status: string) => {
  switch (_.toLower(status)) {
    case _.toLower(ActionStatuses.PendingApproval):
    case _.toLower(ActionStatuses.PendingVerification):
      return lynxColors.harvestOrange;
    case _.toLower(ActionStatuses.Overdue):
      return lynxColors.error;

    default:
      return lynxColors.primary;
  }
};
