const { apiService } = require("../apiService");

const BASE_ROUTE = "/roles";

const rolesEndpoint = apiService.injectEndpoints({
  endpoints: (builder) => ({
    getEventRoles: builder.query({
      query: () => `${BASE_ROUTE}/events`,
    }),
    getMonitoringRoles: builder.query({
      query: () => `${BASE_ROUTE}/monitoring`,
    }),
  }),
});

export const { useGetEventRolesQuery, useGetMonitoringRolesQuery } =
  rolesEndpoint;
